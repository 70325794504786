import { bool } from 'prop-types';
import DesktopToolbar from './DesktopToolbar/DesktopToolbar';
import MobileToolbar from './MobileToolbar/MobileToolbar';
import { compose } from 'redux';
import { connect } from 'react-redux';

const Toolbar = props => {
  const isAuthenticated = props.isAuthenticated;

  if (props.hideOnAuthenticated && isAuthenticated) {
    return <MobileToolbar isAuthenticated={isAuthenticated} />;
  }

  return (
    <>
      <MobileToolbar />
      <DesktopToolbar />
    </>
  );
};

Toolbar.defaultProps = {
  hideOnAuthenticated: false,
};

Toolbar.propTypes = {
  hideOnAuthenticated: bool,
};

const mapStateToProps = state => {
  const { currentUser } = state.user;
  const { isAuthenticated } = state.Auth;
  return { currentUser, isAuthenticated };
};

export default compose(connect(mapStateToProps))(Toolbar);
