/**
 * This is a wrapper component for different Layouts.
 * Navigational 'aside' content should be added to this wrapper.
 */
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { NewTabNav, TabNav } from '..';

import css from './NewLayoutWrapperSideNav.module.css';
import { useState } from 'react';

const NewLayoutWrapperSideNav = props => {
  const {
    className,
    rootClassName,
    children,
    tabs,
    isAvatar,
    currentUser,
    isSidebarPinned,
    setIsSidebarPinned,
    isSidebarHovered,
    setIsSidebarHovered,
    isUserHasListings,
  } = props;

  const classes = classNames(
    rootClassName || css.root,
    className,
    isSidebarPinned && css.pinned,
    isSidebarHovered && css.hovered
  );

  function handleMouseEnter() {
    setIsSidebarHovered(true);
  }

  function handleMouseLeave() {
    setIsSidebarHovered(false);
  }

  return (
    <div
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      className={isSidebarPinned ? classNames(css.sidebarWrapper, css.pinned) : css.sidebarWrapper}
    >
      <aside className={classes}>
        {children}
        {tabs ? (
          <NewTabNav
            rootClassName={css.tabs}
            tabRootClassName={css.tab}
            tabs={tabs}
            isAvatar={isAvatar}
            currentUser={currentUser}
            setIsSidebarPinned={setIsSidebarPinned}
            isSidebarPinned={isSidebarPinned}
            setIsSidebarHovered={setIsSidebarHovered}
            isSidebarHovered={isSidebarHovered}
            isUserHasListings={isUserHasListings}
          />
        ) : null}
      </aside>
    </div>
  );
};

NewLayoutWrapperSideNav.defaultProps = {
  className: null,
  rootClassName: null,
  children: null,
  tabs: null,
};

const { node, string, array } = PropTypes;

NewLayoutWrapperSideNav.propTypes = {
  children: node,
  className: string,
  rootClassName: string,
  tabs: array,
};

export default NewLayoutWrapperSideNav;
