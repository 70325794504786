import css from './MobileToolbar.module.css';
import Button, { GrayButton } from '../../Button/Button';
import Logo from '../../Logo/Logo';
import NamedLink from '../../NamedLink/NamedLink';
import MenuIcon from '../../Topbar/MenuIcon';
import Modal from '../../Modal/Modal';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { manageDisableScrolling } from '../../../ducks/UI.duck';
import searchImage from '../../../assets/search.svg';
import { Field, Form } from 'react-final-form';
import { createResourceLocatorString } from '../../../util/routes';
import { useHistory } from 'react-router-dom';
import routeConfiguration from '../../../routeConfiguration';

const MobileToolbar = ({ isAuthenticated }) => {
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();

  const handleManageDisableScrolling = (componentId, disableScrolling) => {
    dispatch(manageDisableScrolling(componentId, disableScrolling));
  };

  const handleSearch = v => {
    const searchParams = {
      keywords: v.search,
    };
    history.push(createResourceLocatorString('SearchPage', routeConfiguration(), {}, searchParams));
  };

  return (
    <>
      <div className={css.container}>
        <div className={css.topbarLeftContainer}>
          <Button rootClassName={css.menu} onClick={() => setOpen(true)}>
            <MenuIcon className={css.menuIcon} />
          </Button>
          <NamedLink className={css.home} name="LandingPage">
            <Logo format="mobile" className={css.logo} />
          </NamedLink>
        </div>
        {!isAuthenticated && (
          <div className={css.mobileTopbarButtonContainer}>
            <NamedLink name="SignupPage">
              <GrayButton>Sign Up</GrayButton>
            </NamedLink>
          </div>
        )}
      </div>
      <Modal
        id="modalMenu"
        containerClassName={css.modalContainer}
        usePortal
        isOpen={open}
        onClose={() => setOpen(false)}
        onManageDisableScrolling={handleManageDisableScrolling}
      >
        <div className={css.root}>
          <nav className={css.contentBasic}>
            <div className={css.searchContainer}>
              <img src={searchImage} />
              <Form
                onSubmit={handleSearch}
                render={({ handleSubmit }) => (
                  <form onSubmit={handleSubmit}>
                    <Field name="search" component="input" placeholder="Search" />
                  </form>
                )}
              />
            </div>
            <NamedLink className={css.navigationLink} name="BecomeProPage">
              Become a Pro
            </NamedLink>
            <NamedLink className={css.navigationLink} name="HelpGeneralPage">
              Help Center
            </NamedLink>
            <NamedLink className={css.navigationLink} name="LoginPage">
              Login
            </NamedLink>
          </nav>
        </div>
      </Modal>
    </>
  );
};

export default MobileToolbar;
