import { _ } from 'core-js';
import useStepperHook from '../../Hooks/Stepper.hook';
import ProFirstStepFile from './ProFirstStepFile';
import ProSecondStepFile from './ProSecondStepFile';
import ProThirdStepFile from './ProThirdStepFile';
import ProFourthStepFile from './ProFourthStepFile';
import Stepper from 'react-stepper-horizontal';
import { Form, Formik } from 'formik';
import ProStepperCss from '../../styles/ProStepper.style';
import { FormattedMessage } from 'react-intl';

const ProStepperPage = props => {
    const data = useStepperHook({ section: 'pro', ...props });
    const {
        createListingDraftError = null,
        updateListingError = null,
        showListingsError = null,

        publishListingError = null,
        uploadImageError = null,
        fetchExceptionsError = null,
        addExceptionError = null,
        deleteExceptionError = null,
    } = props?.page;

    function _commonError(condition, key) {
        return condition ? (
            <p className="error">
                {' '}
                <FormattedMessage id={`EditListingDescriptionForm.${key}`} />
            </p>
        ) : null;
    }
    const errorMessageUpdateListing = _commonError(updateListingError, 'updateFailed');
    const errorMessageCreateListingDraft = _commonError(
        createListingDraftError,
        'createListingDraftError'
    );
    const errorMessageShowListing = _commonError(showListingsError, 'showListingFailed');

    const STEPS_TITLE_ACCORDING = [
        {
            step: 1,
            component: otherProps => <ProFirstStepFile {...otherProps} />,
            title: 'Personal information',
            image: require('../../assest/Mitrakisa.png'),
            para: "We need this information to approve your profile. This won't be public",
        },
        {
            step: 2,
            component: otherProps => <ProSecondStepFile {...otherProps} />,
            title: 'Create your Pro Profile',
            image: require('../../assest/Profile.png'),
            para:
                'This information will appear on your page, visible to clients. You can change it later',
        },
        {
            step: 3,
            component: otherProps => <ProThirdStepFile {...otherProps} />,
            title: 'Tell us more what you wanna sell',
            image: require('../../assest/listingType.png'),
            para:
                "Select your primary product now, and we'll guide you to create your first listing. You can always add more products later",
        },
        {
            step: 4,
            component: otherProps => <ProFourthStepFile {...otherProps} />,
            title: 'Almost done! Setup your first listing',
            image: require('../../assest/lastproStep.png'),
            para:
                'Describe your Product and add photos to make it appealing for your future clients. You can change this information later',
        },
    ];

    const {
        step,
        _submitProForm,
        _handleBack,
        _handleNext,
        isLastStep,
        STEPS,
        proValidationSchema,
        INITIAL_VALUES,
    } = data;
    const CURRENT_TITLE_OBJECT = _.find(STEPS_TITLE_ACCORDING, { step: step + 1 });

    return (
        <ProStepperCss>
            <div className="steps_main_container">
                <section className="first_container">
                    <div className="title_section_css">
                        <h1>{CURRENT_TITLE_OBJECT?.title}</h1>
                        <p>{CURRENT_TITLE_OBJECT?.para}</p>
                    </div>

                    <div className="stepper">
                        <Stepper className="proStepper_steps common_step" completeColor="#4C687F" activeColor="#4C687F" steps={STEPS} activeStep={step} />
                    </div>
                </section>
                <div className="secondContainer">
                    <div className="mainProFirst_container commonWidth">
                        <Formik
                            initialValues={INITIAL_VALUES[step]}
                            validationSchema={proValidationSchema[step] ? proValidationSchema[step] : {}}
                            onSubmit={(values, action) => _submitProForm(values, action)}
                        >
                            {({ isSubmitting, ...otherFieldValue }) => (
                                <>
                                    <Form id={'formId' + (step + 1)}>
                                        {CURRENT_TITLE_OBJECT?.component({ ...otherFieldValue, ...data })}
                                        <div
                                            className={`actionButtons ${step === 0 ? 'not-position' : 'apply_position'}`}
                                        >
                                            {step !== 0 && (
                                                <button
                                                    type="button"
                                                    style={{ margin: '0 auto' }}
                                                    onClick={_handleBack}
                                                    className="backbutton"
                                                >
                                                    Previous step
                                                </button>
                                            )}
                                            {step !== 2 ? (
                                                <button
                                                    disabled={isSubmitting}
                                                    type="submit"
                                                    variant="contained"
                                                    color="primary"
                                                    style={{ margin: '0 auto' }}
                                                    className=""
                                                >
                                                    {isLastStep ? 'Start earning money' : 'Next'}
                                                </button>
                                            ) : (
                                                <button
                                                    type="button"
                                                    variant="contained"
                                                    color="primary"
                                                    style={{ margin: '0 auto' }}
                                                    className=""
                                                    onClick={() => _handleNext()}
                                                >
                                                    {isLastStep ? 'Start earning money' : 'Next'}
                                                </button>
                                            )}
                                        </div>
                                    </Form>
                                    {errorMessageCreateListingDraft}
                                    {errorMessageUpdateListing}
                                    {errorMessageShowListing}
                                </>
                            )}
                        </Formik>
                    </div>
                    <div className="imageSection">
                        <img src={CURRENT_TITLE_OBJECT?.image} alt="stepperimage" />
                        {step === 0 && (
                            <div className="imageContent">
                                <div>
                                    <h3>Teach and sell with ease</h3>
                                    <span>For freelancers, teachers, influencers. For everyone.</span>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </ProStepperCss>
    );
};

export default ProStepperPage;
