import css from './Faq.module.css';
import plus from '../../../assets/become-pro/plus.svg';
import minus from '../../../assets/become-pro/minus.svg';
import { useState } from 'react';

const template = [
  {
    questions: 'How will clients discover my profile on ProLed?',
    answer:
      'Yes, you can try us for free for 30 days. If you want, we’ll provide you with a free, personalized 30-minute onboarding call to get you up and running as soon as possible.',
  },
  {
    questions: 'Do I need to be certified to sign up with ProLed?',
    answer:
      "No certification is required to sign up as a PRO on ProLed. We believe that everyone has valuable skills to share, whether it's a passion or a profession. While certifications and extensive experience can attract more clients, we welcome all skilled individuals to join our platform and showcase their expertise. Your unique talents are highly valued, and we're excited to help you connect with learners who are eager to learn from you.",
  },
  {
    questions: 'Does ProLed charge any fees?',
    answer:
      "Unlike agencies, we have no commission or referral fees. Creating your profile and listing your service is free. Plus, there's no membership or subscription fees. We only have a 10% service fee per booking transaction that you can take into account when setting your desired price",
  },
  {
    questions: 'When do I get paid?',
    answer:
      "Payment is processed instantly after your complete your session. We understand the importance of timely payments, and to ensure your peace of mind, we'll send you an email on the day the payment is processed, confirming that your payment has been sent and processed successfully.",
  },
];

const Faq = () => {
  const [questions, setQuestions] = useState(
    template.map((v, i) => ({ ...v, open: false, key: i }))
  );

  const handleControl = key => {
    setQuestions(prev => prev.map(v => (v.key === key ? { ...v, open: !v.open } : { ...v })));
  };

  return (
    <div className={css.faq_container}>
      <div className={css.faq}>
        <h2 className={css.faq_title}>Your questions answered</h2>
        <div>
          {questions.map(({ answer, questions, open }, index) => (
            <div className={css.faq_question} key={index}>
              <div className={css.faq_question_content}>
                <h3 onClick={() => handleControl(index)}>{questions}</h3>
                {open && <p>{answer}</p>}
              </div>
              <div onClick={() => handleControl(index)}>
                <img src={open ? minus : plus} alt="controller" />
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Faq;
