import Logo from '../../Logo/Logo';
import NamedLink from '../../NamedLink/NamedLink';
import css from './DesktopToolbar.module.css';
import searchImage from '../../../assets/search.svg';
import { Field, Form } from 'react-final-form';
import { createResourceLocatorString } from '../../../util/routes';
import routeConfiguration from '../../../routeConfiguration';
import { useHistory } from 'react-router-dom';
import { GrayButton } from '../../Button/Button';
import classNames from 'classnames';

const DesktopToolbar = () => {
  const history = useHistory();

  const handleSearch = v => {
    const searchParams = {
      keywords: v.search,
    };
    history.push(createResourceLocatorString('SearchPage', routeConfiguration(), {}, searchParams));
  };

  return (
    <div className={css.desktopToolbarContainer}>
      <div className={css.desktopToolbar}>
        <NamedLink className={css.home} name="LandingPage">
          <Logo format="mobile" className={css.logo} />
        </NamedLink>
        <div className={css.searchContainer}>
          <img src={searchImage} />
          <Form
            onSubmit={handleSearch}
            render={({ handleSubmit }) => (
              <form onSubmit={handleSubmit}>
                <Field name="search" component="input" placeholder="Search" />
              </form>
            )}
          />
        </div>
        <nav className={css.navbar}>
          <NamedLink
            className={classNames(
              css.navigationLink,
              history.location.pathname === '/become-a-pro' && css.navigationLinkActive
            )}
            name="BecomeProPage"
          >
            Become a Pro
          </NamedLink>
          <NamedLink className={css.navigationLink} name="HelpGeneralPage">
            Help Center
          </NamedLink>
        </nav>
        <div className={css.navbar}>
          <NamedLink className={css.navigationLink} name="LoginPage">
            Login
          </NamedLink>
          <NamedLink name="SignupPage">
            <GrayButton className={css.signButton}>Sign Up</GrayButton>
          </NamedLink>
        </div>
      </div>
    </div>
  );
};

export default DesktopToolbar;
