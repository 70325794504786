import React from 'react';
import { FormattedMessage } from 'react-intl';
import WelcomeProLedStyle from '../styles/WelcomeProLed.style';
import parse from 'html-react-parser';

const WelcomProLed = (props) => {

    const { _handleGetStarted, section } = props;

    const PRO_LISTING_DATA = [
        { image: require('../assest/WelcomeLedImage1.png'), heading: 'Build Profile', para: 'Share your experience, certificates, and <br/> achievements' },
        { image: require('../assest/WelcomeLedImage2.png'), heading: 'Create listing', para: "Describe your service or product, enhance it with <br/> eye-catching photos, we'll assist you!" },
        { image: require('../assest/WelcomeLedImage3.png'), heading: 'Get paid', para: 'Make secure payments through our platform and <br/>sell your services easily' }
    ];

    const LISTING_DATA = [
        { image: require('../assest/WelcomeLedImage1.png'), heading: 'Find a Pro', para: "Select preferable categories and we'll assist you to <br/> find a service or a product" },
        { image: require('../assest/WelcomeLedImage2.png'), heading: 'Chat with Pro', para: "Chat directly with a Pro, request locations for <br/> services, and order goods and digital products" },
        { image: require('../assest/WelcomeLedImage3.png'), heading: 'Stay on Platform', para: 'Make secure payments through our platform and <br/>sell your services easily' }
    ];

    return (
        <WelcomeProLedStyle className='MainWelcomeLed_Class'>
            <section className='LedFirstSection commonWidth'>
                <h1>Welcome to ProLed!</h1>
                <p>Sell services and products easily</p>
            </section>
            <section className='LedSecondSection'>
                <div className='listing_section'>
                    {_.map((section === 'learner') ? LISTING_DATA : PRO_LISTING_DATA, (r, index) => (
                        <div className='listing_container' key={'listing' + index}>
                            <div className='listing_image'>
                                <img src={r?.image} alt='listingImage' />
                            </div>
                            <div className='text_Container'>
                                <h3>{r?.heading}</h3>
                                {parse(`<p>${r?.para}</p>`)}
                            </div>
                        </div>
                    ))}
                </div>
                <div className='buttonSection'>
                    <button type='button' onClick={() => _handleGetStarted()}>Get started</button>
                    <p>It will take you {(section === 'learner') ? 5 : 10} minutes</p>
                </div>
            </section>
        </WelcomeProLedStyle>
    );
}

export default WelcomProLed;