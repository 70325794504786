import React, { useEffect } from 'react';
import LearnerAndProOption from './components/LearnerAndProOption';
import MainSignupFlowStyle from './styles/MainSignupFlow.style';
import useSignupFlowHook from './Hooks/signupFlow.hook';
import CreateAccountForm from './components/CreateAccountForm';
import WelcomProLed from './components/WelcomProLed';
import ProStepperPage from './StepperFiles/ProSteppers';
import LearnerStep from './StepperFiles/LearnerStepper';

const MainSignupFlow = props => {
  const data = useSignupFlowHook(props);

  const { currentFlow, selectedSignupProcess, isAuthenticated } = data;
  const commonFLow = {
    // flow3: <><p>Singpass</p> <button type='button' style={{ color: '#000', background: "#DDDD" }} onClick={() => setCurrentFlow('flow4')}>Next</button></>,
    // flow4: <><p>Singpass</p> <button type='button' style={{ color: '#000', background: "#DDDD" }} onClick={() => setCurrentFlow('flow5')}>Next</button></>,
  };
  const PRO_FLOW = {
    flow2: <CreateAccountForm section="pro" {...data} />,
    flow5: <WelcomProLed section="pro" {...data} />,
    flow6: <ProStepperPage {...data} />,
  };
  const LERNER_FLOW = {
    flow2: <CreateAccountForm section="learner" {...data} />,
    flow5: <WelcomProLed section="learner" {...data} />,
    flow6: <LearnerStep {...data} />,
  };

  useEffect(() => {
    window.scrollTo({ behavior: 'smooth', top: 0 });
  }, [currentFlow]);

  //   We have 2 cases for using signup page: for new user and for existed user when he clicks "become a pro"
  const newUserCase = !currentFlow ? (
    <LearnerAndProOption {...data} />
  ) : selectedSignupProcess === 2 ? (
    PRO_FLOW[currentFlow]
  ) : (
    LERNER_FLOW[currentFlow]
  );

  return (
    <MainSignupFlowStyle className="container">
      {!isAuthenticated ? newUserCase : <ProStepperPage {...data} />}
    </MainSignupFlowStyle>
  );
};

export default MainSignupFlow;
