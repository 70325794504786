import styled from 'styled-components';

const MainSignupFlowStyle = styled.div`
  --blue-primary: #0f69e0;
  --text-color: #1c1c1c;
  .react-datepicker-wrapper {
    width: 100% !important;
  }
  width: 100%;
  margin: 0 auto;
  max-width: 1248px;
  padding: 80px 15px;

  @media (max-width: 767px) {
    padding: 30px 15px;
  }

  .commonWidth {
    width: 100%;
    max-width: 523px;
    margin: 0 auto;
    padding: 0 20px;
    @media (max-width: 991px) {
      max-width: 100%;
    }
  }
  .error {
    font-size: 13px;
    color: #f00;
  }

  input,
  select,
  textarea {
    width: 100%;
    height: 48px;
    border: 1px solid #ddd !important;
    border-radius: 6px;
    padding: 10px;
    font-size: 16px;
    color: #959595;
  }
  textarea {
    height: 100px;
  }
  .mainLernerPro_class {
    margin: 0 -15px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    @media (max-width: 1199px) {
      gap: 30px;
      grid-template-columns: repeat(1, 1fr);
    }
  }
  .imageSection {
    max-width: 632px;
    width: 100%;
    height: 100%;
    padding: 0 20px;
    @media (max-width: 991px) {
      max-width: 100%;
    }
    @media (max-width: 767px) {
      display: none;
    }

    img {
      width: 100%;
      height: 100%;
      border-radius: 24px;
      @media (max-width: 1199px) {
        object-fit: cover;
      }
    }

    .imageContent {
      font-weight: var(--fontWeightRegular);
      text-align: left;
      position: relative;

      & > div {
        width: 100%;
        max-width: 100%;
        position: absolute;
        bottom: 0;
        left: 0;
        color: #ffff;
        padding: 60px;
        @media (max-width: 1199px) {
          padding: 40px;
        }
        @media (max-width: 767px) {
          padding: 30px;
        }
        h3 {
          font-size: 36px;
          line-height: 44px;
          letter-spacing: -0.02em;
          margin: 0px 0px 14px 0px;
          @media (max-width: 1199px) {
            font-size: 30px;
            line-height: 34px;
          }
          @media (max-width: 767px) {
            font-size: 22px;
            line-height: normal;
          }
        }
        p,
        span {
          font-size: 16px;
          line-height: 24px;
          letter-spacing: 0em;
          font-weight: var(--fontWeightRegular);
          @media (max-width: 767px) {
            width: 100%;
            display: block;
          }
        }
      }
    }
  }

  .main_orBorder {
    position: relative;
    margin: 20px 0;

    .orDisplaySection {
      text-align: center;

      &::after {
        content: '';
        width: 100%;
        max-width: 490px;
        border-bottom: solid 1px #d2d2d2;
        position: absolute;
        left: 0;
        top: 50%;
        z-index: 1;
      }

      .orborder_class {
        background-color: var(--matterColorLight);
        width: auto;
        display: inline-block;
        z-index: 3;
        padding: 0 20px 3px 20px;
        position: relative;
        margin: 0;
        font-size: 14px;
      }
    }
  }
  button {
    border: unset;
    font-size: 18px;
    font-weight: var(--fontWeightRegular);
    line-height: 24px;
    letter-spacing: 0em;
    width: 100%;
    max-width: 100%;
    color: #ffffff;
    padding: 10px 16px;
    border-radius: 8px;
    cursor: pointer;
    margin-bottom: 10px;
    background: var(--blue-primary);
  }
`;

export default MainSignupFlowStyle;
