import React from 'react';
import { InputFieldComp, SelectFieldComp } from '../../components/CommonComp/FieldComponent';
import PhoneNumberCountry from '../../components/CommonComp/PhoneNumberCountry';
import { isUploadImageOverLimitError } from '../../../util/errors';
import _ from 'lodash';

function LearnerFirstStep(props) {
    const { imageUpload, handleClick, inputRef, _handleImageChange, uploadImageError, errors, setFieldValue } = props;

    let error = null;
    if (isUploadImageOverLimitError(uploadImageError)) {
        error = (
            <div className={css.error}>
                <FormattedMessage id="ProfileSettingsForm.imageUploadFailedFileTooLarge" />
            </div>
        );
    } else if (uploadImageError) {
        error = (
            <div className={css.error}>
                <FormattedMessage id="ProfileSettingsForm.imageUploadFailed" />
            </div>
        );
    }

    return (
        <div className='proSecond_main'>
            <div className='fieldWithLabel uploadSection'>
                <div className='imagePreview'>
                    <img src={imageUpload?.preview || require('../../assest/profile-pic.png')} alt="dummy" />
                </div>
                <div className='file_selection'>
                    <input type="file" ref={inputRef} accept={'image/*'} id="upload-button" style={{ display: "none" }} onChange={_handleImageChange} />
                    <button type='button' onClick={handleClick}>Upload photo</button>
                </div>
            </div>
            {(error || imageUpload?.errMsg) && <div className="error">{error || imageUpload?.errMsg}</div>}

            <div className='fieldWithLabel'>
                <label htmlFor=''>Phone number</label>
                <PhoneNumberCountry {...{ setFieldValue }} />
                {errors?.phoneNumber && <div className="error">{errors?.phoneNumber}</div>}
            </div>
            <div className='fieldWithLabel'>
                <label htmlFor=''>Display name</label>
                <InputFieldComp name='displayName' placeholder='Prefilled name from Singpass or Principal name' />
            </div>
            <div className='fieldWithLabel'>
                <label htmlFor=''>Home address</label>
                <InputFieldComp name='homeAddress' placeholder='Type here' />
            </div>
            <div className='fieldWithLabel'>
                <label htmlFor=''>How did you hear about ProLed</label>
                <SelectFieldComp
                    name="hearFrom"
                    options={_.map(['facebook', 'linkedin ', 'instagram', 'twitter', 'others'], (r) => ({ label: _.capitalize(r), value: r }))}
                    error={errors?.hearFrom}
                />
            </div>
        </div>
    )
}

export default LearnerFirstStep;