import React from 'react';
import { InputFieldComp, SelectFieldComp } from '../../components/CommonComp/FieldComponent';
import { isUploadImageOverLimitError } from '../../../util/errors';
import { FormattedMessage } from 'react-intl';

const ProSecondStepFile = props => {
  const {
    imageUpload,
    handleClick,
    inputRef,
    _handleImageChange,
    uploadImageError,
    errors,
    handleChange,
    setFieldValue,
  } = props;
  let error = null;
  if (isUploadImageOverLimitError(uploadImageError)) {
    error = (
      <div className="">
        <FormattedMessage id="ProfileSettingsForm.imageUploadFailedFileTooLarge" />
      </div>
    );
  } else if (uploadImageError) {
    error = (
      <div className="">
        <FormattedMessage id="ProfileSettingsForm.imageUploadFailed" />
      </div>
    );
  }

  const ROLES = _.map(
    [
      'Personal Trainer',
      'Songwriter',
      'Artist',
      'Vocalist',
      'Semi-Pro Athlete',
      'National Athlete',
      'Professional Athlete',
      'Collegiate Athlete',
      'E Sports Gamer',
      'Tutor',
      'Other',
    ],
    r => ({ label: r, value: r })
  );
  return (
    <div className="LearnerFirst_main">
      <div className="fieldWithLabel">
        <label htmlFor="">Profile picture</label>
        <div className="uploadSection">
          <div className="imagePreview">
            <img
              src={imageUpload?.preview || require('../../assest/profile-pic.png')}
              alt="image-profile"
            />
          </div>
          <div className="file_selection">
            <input
              type="file"
              ref={inputRef}
              id="upload-button"
              style={{ display: 'none' }}
              onChange={_handleImageChange}
            />
            <button type="button" onClick={handleClick}>
              Upload photo
            </button>
          </div>
        </div>
      </div>
      {(error || imageUpload?.errMsg) && (
        <div className="error">{error || imageUpload?.errMsg}</div>
      )}

      <div className="fieldWithLabel">
        <label htmlFor="">Display name</label>
        <InputFieldComp
          name="displayName"
          placeholder="Prefilled name from Singpass or Principal name"
        />
      </div>
      <div className="fieldWithLabel">
        <label htmlFor="">Role</label>
        <SelectFieldComp name="role" options={ROLES} error={errors?.role} />
      </div>
      <div className="fieldWithLabel">
        <label htmlFor="">About</label>
        <textarea name="about" onChange={handleChange} />
        {errors?.about && <div className="error">{errors?.about}</div>}
      </div>
      <div className="fieldWithLabel">
        <label htmlFor="">How did you hear about ProLed</label>
        <SelectFieldComp
          name="hearFrom"
          options={_.map(['facebook', 'linkdin', 'instagram', 'twitter', 'others'], r => ({
            label: _.capitalize(r),
            value: r,
          }))}
          error={errors?.hearFrom}
        />
      </div>
    </div>
  );
};

export default ProSecondStepFile;
