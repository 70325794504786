import { func, string } from 'prop-types';
import ListingCard from '../../ListingCard/ListingCard';
import NamedLink from '../../NamedLink/NamedLink';
import css from '../CategoriesListingsPanel.module.css';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { searchPro } from '../../../containers/SearchPage/SearchPage.duck';

const Icon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.19959 13.8977C5.93347 13.6781 5.93347 13.3219 6.19959 13.1023L10.6875 9.39775C10.9536 9.17808 10.9536 8.82192 10.6875 8.60225L6.19959 4.89775C5.93347 4.67808 5.93347 4.32192 6.19959 4.10225C6.46572 3.88258 6.89719 3.88258 7.16331 4.10225L11.6512 7.80676C12.4496 8.46577 12.4496 9.53423 11.6512 10.1932L7.16331 13.8977C6.89719 14.1174 6.46572 14.1174 6.19959 13.8977Z"
      fill="#959595"
    />
  </svg>
);

const TrainWithPro = props => {
  const { setActiveListing, cardRenderSizes, origin, title } = props;
  const dispatch = useDispatch();
  const marketplaceData = useSelector(state => state.marketplaceData.entities.listing);
  const data = useSelector(state => state.SearchPage.proListings);

  useEffect(() => {
    if (marketplaceData) dispatch(searchPro());
  }, [dispatch, marketplaceData]);

  return (
    <>
      <div className={css.categorieHeaderContainer}>
        <h2 className={css.subheader}>{title}</h2>
        <NamedLink
          name="CategoriesPage"
          to={{
            search: `bounds=1.68008452%2C104.08284892%2C1.00507067%2C103.59952873&is_find_pro=true`,
          }}
          className={css.legalLink}
        >
          <span>View All</span>
          <Icon />{' '}
        </NamedLink>
      </div>
      <div className={css.listingCards}>
        {data &&
          data.map(l => (
            <ListingCard
              className={css.listingCard}
              key={l.id.uuid}
              listing={l}
              renderSizes={cardRenderSizes}
              setActiveListing={setActiveListing}
              origin={origin}
              isFindPro
            />
          ))}
      </div>
    </>
  );
};

TrainWithPro.propTypes = {
  cardRenderSizes: string,
  origin: string,
  setActiveListing: func,
  title: string,
};

export default TrainWithPro;
