import { useEffect, useState } from 'react';
import { Menu, MenuContent, MenuItem, MenuLabel } from '../../../components';
import { GrayButton } from '../../../components/Button/Button';
import { filters } from '../../../marketplace-custom-config';
import { format, getOptions, getQueryParamName, reverseFormat } from '../Filter.helpers';
import ArrowIcon from './ArrowIcon';
import css from './Filter.module.css';
import classNames from 'classnames';

const AgeIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M9.99935 1.04199C7.81322 1.04199 6.04102 2.8142 6.04102 5.00033C6.04102 7.18645 7.81322 8.95866 9.99935 8.95866C12.1855 8.95866 13.9577 7.18645 13.9577 5.00033C13.9577 2.8142 12.1855 1.04199 9.99935 1.04199ZM7.29102 5.00033C7.29102 3.50455 8.50358 2.29199 9.99935 2.29199C11.4951 2.29199 12.7077 3.50455 12.7077 5.00033C12.7077 6.4961 11.4951 7.70866 9.99935 7.70866C8.50358 7.70866 7.29102 6.4961 7.29102 5.00033Z"
      fill="#313131"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M9.99935 10.2087C8.2999 10.2087 6.73061 10.601 5.56447 11.2673C4.41601 11.9236 3.54102 12.9254 3.54102 14.167C3.54102 15.4085 4.41601 16.4104 5.56447 17.0667C6.73061 17.733 8.2999 18.1253 9.99935 18.1253C11.6988 18.1253 13.2681 17.733 14.4342 17.0667C15.5827 16.4104 16.4577 15.4085 16.4577 14.167C16.4577 12.9254 15.5827 11.9236 14.4342 11.2673C13.2681 10.601 11.6988 10.2087 9.99935 10.2087ZM4.79102 14.167C4.79102 13.5676 5.22185 12.9028 6.18465 12.3526C7.12976 11.8126 8.47713 11.4587 9.99935 11.4587C11.5216 11.4587 12.8689 11.8126 13.8141 12.3526C14.7768 12.9028 15.2077 13.5676 15.2077 14.167C15.2077 14.7664 14.7768 15.4312 13.8141 15.9814C12.8689 16.5214 11.5216 16.8753 9.99935 16.8753C8.47713 16.8753 7.12976 16.5214 6.18465 15.9814C5.22185 15.4312 4.79102 14.7664 4.79102 14.167Z"
      fill="#313131"
    />
  </svg>
);

const options = [
  {
    label: 'Senior (55+ years)',
    key: 'senior',
  },
  {
    label: 'Adult (19-54 years)',
    key: 'adult',
  },
  {
    label: 'Teen (13-18 years)',
    key: 'teen',
  },
  {
    label: 'Kids (6-12 years)',
    key: 'child',
  },
];

const AgeFilter = ({ urlQueryParams, onSubmit }) => {
  const [open, setOpen] = useState(false);
  const [values, setValues] = useState([]);
  const filter = filters.find(({ id }) => id === 'ageCategory');
  const searchMode = filter.config.searchMode;
  const queryParamName = getQueryParamName(filter.queryParamNames);

  const handleSelect = key => {
    if (!values.includes(key)) {
      setValues([...values, key]);
    } else {
      setValues(values.filter(k => k !== key));
    }
  };

  const handleSubmit = () => {
    const usedValue = values;
    const formatted = format(usedValue, queryParamName, searchMode);
    onSubmit(formatted);
    setOpen(false);
  };

  useEffect(() => {
    const { selectedOptions } = reverseFormat(urlQueryParams, queryParamName);
    setValues(selectedOptions);
  }, [urlQueryParams, queryParamName]);

  return (
    <Menu
      className={css.filterContainer}
      useArrow={false}
      onToggleActive={values => setOpen(values)}
      isOpen={open}
    >
      <MenuLabel className={css.filterLabel}>
        <AgeIcon />
        <span>Age group</span>
        <ArrowIcon />
      </MenuLabel>
      <MenuContent key="activities" className={css.filterContent}>
        <MenuItem key="activities" className={css.filterTitle}>
          Age group
        </MenuItem>
        <MenuItem key="items" className={css.filterItemContainer}>
          {options.map(option => (
            <span
              key={option.key}
              className={classNames(
                css.filterItem,
                values.includes(option.key) && css.filterItemActive
              )}
              onClick={() => handleSelect(option.key)}
            >
              {option.label}
            </span>
          ))}
        </MenuItem>
        <MenuItem key="actions" className={css.filterAction}>
          <button
            className={css.filterBtnApply}
            onClick={() => {
              setValues([]);
            }}
          >
            Clear All
          </button>
          <GrayButton className={css.filterBtnGray} onClick={handleSubmit}>
            Apply
          </GrayButton>{' '}
        </MenuItem>
      </MenuContent>
    </Menu>
  );
};

export default AgeFilter;
