import React, { useEffect, useState } from 'react';
import { FaMinus, FaPlus } from "react-icons/fa";
import { HiMinusSmall, HiPlusSmall } from "react-icons/hi2";
const Accordion = ({ children, title, active }) => {
    const [isActive, setIsActive] = useState(false);
    useEffect(() => {
        if (active) {
            setIsActive(active)
        }
    }, [active])

    return (
        <div className="accordion-item">
            <div className="accordion-title" onClick={() => setIsActive(!isActive)}>
                <div className='text_title'>{title}</div>
                <div className='plus_minusIcon'>{isActive ? <HiMinusSmall fontSize={20} /> : <HiPlusSmall fontSize={20} />}</div>
            </div>
            {isActive &&
                <div className="accordion-content">
                    {children}
                </div>
            }
        </div>
    );
};

export default Accordion;