import {
  Footer,
  LayoutSingleColumn,
  LayoutWrapperFooter,
  LayoutWrapperMain,
  LayoutWrapperTopbar,
} from '../../components';
import Toolbar from '../../components/Toolbar/Toolbar';
import StaticPage from '../StaticPage/StaticPage';
import CallToAction from './CallToAction/CallToAction';
import Faq from './Faq/Faq';
import Features from './Features/Features';
import Flow from './Flow/Flow';
import HeroBecomeProPage from './Hero/Hero';
import HowItWorks from './HowItWorks/HowItWorks';
import Offers from './Offers/Offers';

const BecomeProPage = () => {
  const description = 'Join our community of skilled freelancers in Singapore';
  const title = 'Become a PRO | ProLed';

  return (
    <StaticPage
      description={description}
      title={title}
      schema={{
        '@context': 'http://schema.org',
        '@type': 'BecomeProPage',
        description: { description },
        name: { title },
      }}
    >
      <LayoutSingleColumn>
        <LayoutWrapperTopbar>
          <Toolbar />
        </LayoutWrapperTopbar>
        <LayoutWrapperMain>
          <HeroBecomeProPage />
          <Offers />
          <Features />
          <HowItWorks />
          <Flow />
          <CallToAction />
          <Faq />
        </LayoutWrapperMain>
        <LayoutWrapperFooter>
          <Footer />
        </LayoutWrapperFooter>
      </LayoutSingleColumn>
    </StaticPage>
  );
};

export default BecomeProPage;
