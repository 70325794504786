import React from 'react';
import { arrayOf, bool, func, shape, string } from 'prop-types';
import { compose } from 'redux';
import { Field, Form as FinalForm } from 'react-final-form';
import { intlShape, injectIntl, FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { propTypes } from '../../util/types';
import * as validators from '../../util/validators';
import { maxLength, required, composeValidators } from '../../util/validators';
import {
  Form,
  Button,
  FieldTextInput,
  FieldCheckbox,
  FieldRadioButton,
  Footnote,
  FieldPhoneNumberInput,
  FieldSelect,
  PrimaryButton,
} from '../../components';
import CustomCertificateSelectFieldMaybe from './CustomCertificateSelectFieldMaybe';

import css from './EditListingPersonalDetailsForm.module.css';
import config from '../../config';

const TITLE_MAX_LENGTH = 60;

const EditListingPersonalDetailsFormComponent = props => (
  <FinalForm
    {...props}
    render={formRenderProps => {
      const {
        certificateOptions,
        className,
        disabled,
        ready,
        handleSubmit,
        intl,
        invalid,
        pristine,
        saveActionMsg,
        updated,
        updateInProgress,
        fetchErrors,
        values,
      } = formRenderProps;

      const listingTypes = config.listingTypes;

      const titleMessage = intl.formatMessage({ id: 'EditListingDescriptionForm.title' });
      const footnote = intl.formatMessage({ id: 'EditListingDescriptionForm.footnote' });
      const titlePlaceholderMessage = intl.formatMessage({
        id: 'EditListingDescriptionForm.titlePlaceholder',
      });
      const titleRequiredMessage = intl.formatMessage({
        id: 'EditListingDescriptionForm.titleRequired',
      });
      const maxLengthMessage = intl.formatMessage(
        { id: 'EditListingDescriptionForm.maxLength' },
        {
          maxLength: TITLE_MAX_LENGTH,
        }
      );

      const phoneNumberTitleMessage = intl.formatMessage({
        id: 'EditListingDescriptionForm.titlePhoneNumber',
      });

      const phoneNumberPlaceholderMessage = intl.formatMessage({
        id: 'EditListingDescriptionForm.phoneNumberPlaceholder',
      });

      const maxLength60Message = maxLength(maxLengthMessage, TITLE_MAX_LENGTH);

      const { updateListingError, createListingDraftError, showListingsError } = fetchErrors || {};
      const errorMessageUpdateListing = updateListingError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingDescriptionForm.updateFailed" />
        </p>
      ) : null;

      // This error happens only on first tab (of EditListingWizard)
      const errorMessageCreateListingDraft = createListingDraftError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingDescriptionForm.createListingDraftError" />
        </p>
      ) : null;

      const errorMessageShowListing = showListingsError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingDescriptionForm.showListingFailed" />
        </p>
      ) : null;

      const sgCitizenLabel = intl.formatMessage({
        id: 'EditListingPersonalDetailsForm.singaporeCitizenLabel',
      });

      const usCitizenLabelYes = intl.formatMessage({
        id: 'EditListingDescriptionForm.usCitizenLabelYes',
      });

      const usCitizenLabelNo = intl.formatMessage({
        id: 'EditListingDescriptionForm.usCitizenLabelNo',
      });

      const allowedToWorkLabel = intl.formatMessage({
        id: 'EditListingPersonalDetailsForm.allowedToWorkLabel',
      });

      const allowedToWorkLabelYes = intl.formatMessage({
        id: 'EditListingDescriptionForm.allowedToWorkLabelYes',
      });

      const allowedToWorkLabelNo = intl.formatMessage({
        id: 'EditListingDescriptionForm.allowedToWorkLabelNo',
      });

      const convictedLabel = intl.formatMessage({
        id: 'EditListingDescriptionForm.convictedLabel',
      });

      const convictedLabelYes = intl.formatMessage({
        id: 'EditListingDescriptionForm.convictedLabelYes',
      });

      const convictedLabelNo = intl.formatMessage({
        id: 'EditListingDescriptionForm.convictedLabelNo',
      });

      // const grantProleadDiscretionLabel = intl.formatMessage({
      //   id: 'EditListingDescriptionForm.grantProleadDiscretionLabel',
      // });

      const grantProleadDiscretionRequiredMessage = intl.formatMessage({
        id: 'EditListingDescriptionForm.grantProleadDiscretionRequiredMessage',
      });

      const hearedAboutMessage = intl.formatMessage({
        id: 'EditListingDescriptionForm.hearedAboutMessage',
      });

      const hearedAboutPlaceholderMessage = intl.formatMessage({
        id: 'EditListingDescriptionForm.hearedAboutPlaceholderMessage',
      });

      const hearedAboutRequiredMessage = intl.formatMessage({
        id: 'EditListingDescriptionForm.hearedAboutRequiredMessage',
      });

      const certificateLabel = (
        <span>
          <FormattedMessage id="EditListingDescriptionForm.certificateLabel" />
          <span className={css.optional}>
            <FormattedMessage id="EditListingWizard.optional" />
          </span>
        </span>
      );

      const certificatePlaceholderMessage = intl.formatMessage({
        id: 'EditListingDescriptionForm.certificatePlaceholderMessage',
      });

      const certificateRequiredMessage = intl.formatMessage({
        id: 'EditListingDescriptionForm.certificateRequiredMessage',
      });

      const maleGenderLabel = intl.formatMessage({
        id: 'EditListingDescriptionForm.maleGenderLabel',
      });

      const femaleGenderLabel = intl.formatMessage({
        id: 'EditListingDescriptionForm.femaleGenderLabel',
      });

      const otherGenderLabel = intl.formatMessage({
        id: 'EditListingDescriptionForm.otherGenderLabel',
      });

      const genderLabel = intl.formatMessage({
        id: 'EditListingDescriptionForm.genderLabel',
      });

      const classes = classNames(css.root, className);
      const submitReady = (updated && pristine) || ready;
      const submitInProgress = updateInProgress;
      const submitDisabled = invalid || disabled || submitInProgress;

      const phonePlaceholder = intl.formatMessage({
        id: 'Enter your phone number',
      });
      const phoneLabel = intl.formatMessage({ id: 'ContactDetailsForm.phoneLabel' });
      const countrycodePlaceholder = intl.formatMessage({
        id: 'Enter country code',
      });

      const countryCodes = [
        {
          name: 'Afghanistan',
          dial_code: '+93',
          code: 'AF',
        },
        {
          name: 'Aland Islands',
          dial_code: '+358',
          code: 'AX',
        },
        {
          name: 'Albania',
          dial_code: '+355',
          code: 'AL',
        },
        {
          name: 'Algeria',
          dial_code: '+213',
          code: 'DZ',
        },
        {
          name: 'AmericanSamoa',
          dial_code: '+1684',
          code: 'AS',
        },
        {
          name: 'Andorra',
          dial_code: '+376',
          code: 'AD',
        },
        {
          name: 'Angola',
          dial_code: '+244',
          code: 'AO',
        },
        {
          name: 'Anguilla',
          dial_code: '+1264',
          code: 'AI',
        },
        {
          name: 'Antarctica',
          dial_code: '+672',
          code: 'AQ',
        },
        {
          name: 'Antigua and Barbuda',
          dial_code: '+1268',
          code: 'AG',
        },
        {
          name: 'Argentina',
          dial_code: '+54',
          code: 'AR',
        },
        {
          name: 'Armenia',
          dial_code: '+374',
          code: 'AM',
        },
        {
          name: 'Aruba',
          dial_code: '+297',
          code: 'AW',
        },
        {
          name: 'Australia',
          dial_code: '+61',
          code: 'AU',
        },
        {
          name: 'Austria',
          dial_code: '+43',
          code: 'AT',
        },
        {
          name: 'Azerbaijan',
          dial_code: '+994',
          code: 'AZ',
        },
        {
          name: 'Bahamas',
          dial_code: '+1242',
          code: 'BS',
        },
        {
          name: 'Bahrain',
          dial_code: '+973',
          code: 'BH',
        },
        {
          name: 'Bangladesh',
          dial_code: '+880',
          code: 'BD',
        },
        {
          name: 'Barbados',
          dial_code: '+1246',
          code: 'BB',
        },
        {
          name: 'Belarus',
          dial_code: '+375',
          code: 'BY',
        },
        {
          name: 'Belgium',
          dial_code: '+32',
          code: 'BE',
        },
        {
          name: 'Belize',
          dial_code: '+501',
          code: 'BZ',
        },
        {
          name: 'Benin',
          dial_code: '+229',
          code: 'BJ',
        },
        {
          name: 'Bermuda',
          dial_code: '+1441',
          code: 'BM',
        },
        {
          name: 'Bhutan',
          dial_code: '+975',
          code: 'BT',
        },
        {
          name: 'Bolivia, Plurinational State of',
          dial_code: '+591',
          code: 'BO',
        },
        {
          name: 'Bosnia and Herzegovina',
          dial_code: '+387',
          code: 'BA',
        },
        {
          name: 'Botswana',
          dial_code: '+267',
          code: 'BW',
        },
        {
          name: 'Brazil',
          dial_code: '+55',
          code: 'BR',
        },
        {
          name: 'British Indian Ocean Territory',
          dial_code: '+246',
          code: 'IO',
        },
        {
          name: 'Brunei Darussalam',
          dial_code: '+673',
          code: 'BN',
        },
        {
          name: 'Bulgaria',
          dial_code: '+359',
          code: 'BG',
        },
        {
          name: 'Burkina Faso',
          dial_code: '+226',
          code: 'BF',
        },
        {
          name: 'Burundi',
          dial_code: '+257',
          code: 'BI',
        },
        {
          name: 'Cambodia',
          dial_code: '+855',
          code: 'KH',
        },
        {
          name: 'Cameroon',
          dial_code: '+237',
          code: 'CM',
        },
        {
          name: 'Canada',
          dial_code: '+1',
          code: 'CA',
        },
        {
          name: 'Cape Verde',
          dial_code: '+238',
          code: 'CV',
        },
        {
          name: 'Cayman Islands',
          dial_code: '+ 345',
          code: 'KY',
        },
        {
          name: 'Central African Republic',
          dial_code: '+236',
          code: 'CF',
        },
        {
          name: 'Chad',
          dial_code: '+235',
          code: 'TD',
        },
        {
          name: 'Chile',
          dial_code: '+56',
          code: 'CL',
        },
        {
          name: 'China',
          dial_code: '+86',
          code: 'CN',
        },
        {
          name: 'Christmas Island',
          dial_code: '+61',
          code: 'CX',
        },
        {
          name: 'Cocos (Keeling) Islands',
          dial_code: '+61',
          code: 'CC',
        },
        {
          name: 'Colombia',
          dial_code: '+57',
          code: 'CO',
        },
        {
          name: 'Comoros',
          dial_code: '+269',
          code: 'KM',
        },
        {
          name: 'Congo',
          dial_code: '+242',
          code: 'CG',
        },
        {
          name: 'Congo, The Democratic Republic of the Congo',
          dial_code: '+243',
          code: 'CD',
        },
        {
          name: 'Cook Islands',
          dial_code: '+682',
          code: 'CK',
        },
        {
          name: 'Costa Rica',
          dial_code: '+506',
          code: 'CR',
        },
        {
          name: "Cote d'Ivoire",
          dial_code: '+225',
          code: 'CI',
        },
        {
          name: 'Croatia',
          dial_code: '+385',
          code: 'HR',
        },
        {
          name: 'Cuba',
          dial_code: '+53',
          code: 'CU',
        },
        {
          name: 'Cyprus',
          dial_code: '+357',
          code: 'CY',
        },
        {
          name: 'Czech Republic',
          dial_code: '+420',
          code: 'CZ',
        },
        {
          name: 'Denmark',
          dial_code: '+45',
          code: 'DK',
        },
        {
          name: 'Djibouti',
          dial_code: '+253',
          code: 'DJ',
        },
        {
          name: 'Dominica',
          dial_code: '+1767',
          code: 'DM',
        },
        {
          name: 'Dominican Republic',
          dial_code: '+1849',
          code: 'DO',
        },
        {
          name: 'Ecuador',
          dial_code: '+593',
          code: 'EC',
        },
        {
          name: 'Egypt',
          dial_code: '+20',
          code: 'EG',
        },
        {
          name: 'El Salvador',
          dial_code: '+503',
          code: 'SV',
        },
        {
          name: 'Equatorial Guinea',
          dial_code: '+240',
          code: 'GQ',
        },
        {
          name: 'Eritrea',
          dial_code: '+291',
          code: 'ER',
        },
        {
          name: 'Estonia',
          dial_code: '+372',
          code: 'EE',
        },
        {
          name: 'Ethiopia',
          dial_code: '+251',
          code: 'ET',
        },
        {
          name: 'Falkland Islands (Malvinas)',
          dial_code: '+500',
          code: 'FK',
        },
        {
          name: 'Faroe Islands',
          dial_code: '+298',
          code: 'FO',
        },
        {
          name: 'Fiji',
          dial_code: '+679',
          code: 'FJ',
        },
        {
          name: 'Finland',
          dial_code: '+358',
          code: 'FI',
        },
        {
          name: 'France',
          dial_code: '+33',
          code: 'FR',
        },
        {
          name: 'French Guiana',
          dial_code: '+594',
          code: 'GF',
        },
        {
          name: 'French Polynesia',
          dial_code: '+689',
          code: 'PF',
        },
        {
          name: 'Gabon',
          dial_code: '+241',
          code: 'GA',
        },
        {
          name: 'Gambia',
          dial_code: '+220',
          code: 'GM',
        },
        {
          name: 'Georgia',
          dial_code: '+995',
          code: 'GE',
        },
        {
          name: 'Germany',
          dial_code: '+49',
          code: 'DE',
        },
        {
          name: 'Ghana',
          dial_code: '+233',
          code: 'GH',
        },
        {
          name: 'Gibraltar',
          dial_code: '+350',
          code: 'GI',
        },
        {
          name: 'Greece',
          dial_code: '+30',
          code: 'GR',
        },
        {
          name: 'Greenland',
          dial_code: '+299',
          code: 'GL',
        },
        {
          name: 'Grenada',
          dial_code: '+1473',
          code: 'GD',
        },
        {
          name: 'Guadeloupe',
          dial_code: '+590',
          code: 'GP',
        },
        {
          name: 'Guam',
          dial_code: '+1671',
          code: 'GU',
        },
        {
          name: 'Guatemala',
          dial_code: '+502',
          code: 'GT',
        },
        {
          name: 'Guernsey',
          dial_code: '+44',
          code: 'GG',
        },
        {
          name: 'Guinea',
          dial_code: '+224',
          code: 'GN',
        },
        {
          name: 'Guinea-Bissau',
          dial_code: '+245',
          code: 'GW',
        },
        {
          name: 'Guyana',
          dial_code: '+595',
          code: 'GY',
        },
        {
          name: 'Haiti',
          dial_code: '+509',
          code: 'HT',
        },
        {
          name: 'Holy See (Vatican City State)',
          dial_code: '+379',
          code: 'VA',
        },
        {
          name: 'Honduras',
          dial_code: '+504',
          code: 'HN',
        },
        {
          name: 'Hong Kong',
          dial_code: '+852',
          code: 'HK',
        },
        {
          name: 'Hungary',
          dial_code: '+36',
          code: 'HU',
        },
        {
          name: 'Iceland',
          dial_code: '+354',
          code: 'IS',
        },
        {
          name: 'India',
          dial_code: '+91',
          code: 'IN',
        },
        {
          name: 'Indonesia',
          dial_code: '+62',
          code: 'ID',
        },
        {
          name: 'Iran, Islamic Republic of Persian Gulf',
          dial_code: '+98',
          code: 'IR',
        },
        {
          name: 'Iraq',
          dial_code: '+964',
          code: 'IQ',
        },
        {
          name: 'Ireland',
          dial_code: '+353',
          code: 'IE',
        },
        {
          name: 'Isle of Man',
          dial_code: '+44',
          code: 'IM',
        },
        {
          name: 'Israel',
          dial_code: '+972',
          code: 'IL',
        },
        {
          name: 'Italy',
          dial_code: '+39',
          code: 'IT',
        },
        {
          name: 'Jamaica',
          dial_code: '+1876',
          code: 'JM',
        },
        {
          name: 'Japan',
          dial_code: '+81',
          code: 'JP',
        },
        {
          name: 'Jersey',
          dial_code: '+44',
          code: 'JE',
        },
        {
          name: 'Jordan',
          dial_code: '+962',
          code: 'JO',
        },
        {
          name: 'Kazakhstan',
          dial_code: '+77',
          code: 'KZ',
        },
        {
          name: 'Kenya',
          dial_code: '+254',
          code: 'KE',
        },
        {
          name: 'Kiribati',
          dial_code: '+686',
          code: 'KI',
        },
        {
          name: "Korea, Democratic People's Republic of Korea",
          dial_code: '+850',
          code: 'KP',
        },
        {
          name: 'Korea, Republic of South Korea',
          dial_code: '+82',
          code: 'KR',
        },
        {
          name: 'Kuwait',
          dial_code: '+965',
          code: 'KW',
        },
        {
          name: 'Kyrgyzstan',
          dial_code: '+996',
          code: 'KG',
        },
        {
          name: 'Laos',
          dial_code: '+856',
          code: 'LA',
        },
        {
          name: 'Latvia',
          dial_code: '+371',
          code: 'LV',
        },
        {
          name: 'Lebanon',
          dial_code: '+961',
          code: 'LB',
        },
        {
          name: 'Lesotho',
          dial_code: '+266',
          code: 'LS',
        },
        {
          name: 'Liberia',
          dial_code: '+231',
          code: 'LR',
        },
        {
          name: 'Libyan Arab Jamahiriya',
          dial_code: '+218',
          code: 'LY',
        },
        {
          name: 'Liechtenstein',
          dial_code: '+423',
          code: 'LI',
        },
        {
          name: 'Lithuania',
          dial_code: '+370',
          code: 'LT',
        },
        {
          name: 'Luxembourg',
          dial_code: '+352',
          code: 'LU',
        },
        {
          name: 'Macao',
          dial_code: '+853',
          code: 'MO',
        },
        {
          name: 'Macedonia',
          dial_code: '+389',
          code: 'MK',
        },
        {
          name: 'Madagascar',
          dial_code: '+261',
          code: 'MG',
        },
        {
          name: 'Malawi',
          dial_code: '+265',
          code: 'MW',
        },
        {
          name: 'Malaysia',
          dial_code: '+60',
          code: 'MY',
        },
        {
          name: 'Maldives',
          dial_code: '+960',
          code: 'MV',
        },
        {
          name: 'Mali',
          dial_code: '+223',
          code: 'ML',
        },
        {
          name: 'Malta',
          dial_code: '+356',
          code: 'MT',
        },
        {
          name: 'Marshall Islands',
          dial_code: '+692',
          code: 'MH',
        },
        {
          name: 'Martinique',
          dial_code: '+596',
          code: 'MQ',
        },
        {
          name: 'Mauritania',
          dial_code: '+222',
          code: 'MR',
        },
        {
          name: 'Mauritius',
          dial_code: '+230',
          code: 'MU',
        },
        {
          name: 'Mayotte',
          dial_code: '+262',
          code: 'YT',
        },
        {
          name: 'Mexico',
          dial_code: '+52',
          code: 'MX',
        },
        {
          name: 'Micronesia, Federated States of Micronesia',
          dial_code: '+691',
          code: 'FM',
        },
        {
          name: 'Moldova',
          dial_code: '+373',
          code: 'MD',
        },
        {
          name: 'Monaco',
          dial_code: '+377',
          code: 'MC',
        },
        {
          name: 'Mongolia',
          dial_code: '+976',
          code: 'MN',
        },
        {
          name: 'Montenegro',
          dial_code: '+382',
          code: 'ME',
        },
        {
          name: 'Montserrat',
          dial_code: '+1664',
          code: 'MS',
        },
        {
          name: 'Morocco',
          dial_code: '+212',
          code: 'MA',
        },
        {
          name: 'Mozambique',
          dial_code: '+258',
          code: 'MZ',
        },
        {
          name: 'Myanmar',
          dial_code: '+95',
          code: 'MM',
        },
        {
          name: 'Namibia',
          dial_code: '+264',
          code: 'NA',
        },
        {
          name: 'Nauru',
          dial_code: '+674',
          code: 'NR',
        },
        {
          name: 'Nepal',
          dial_code: '+977',
          code: 'NP',
        },
        {
          name: 'Netherlands',
          dial_code: '+31',
          code: 'NL',
        },
        {
          name: 'Netherlands Antilles',
          dial_code: '+599',
          code: 'AN',
        },
        {
          name: 'New Caledonia',
          dial_code: '+687',
          code: 'NC',
        },
        {
          name: 'New Zealand',
          dial_code: '+64',
          code: 'NZ',
        },
        {
          name: 'Nicaragua',
          dial_code: '+505',
          code: 'NI',
        },
        {
          name: 'Niger',
          dial_code: '+227',
          code: 'NE',
        },
        {
          name: 'Nigeria',
          dial_code: '+234',
          code: 'NG',
        },
        {
          name: 'Niue',
          dial_code: '+683',
          code: 'NU',
        },
        {
          name: 'Norfolk Island',
          dial_code: '+672',
          code: 'NF',
        },
        {
          name: 'Northern Mariana Islands',
          dial_code: '+1670',
          code: 'MP',
        },
        {
          name: 'Norway',
          dial_code: '+47',
          code: 'NO',
        },
        {
          name: 'Oman',
          dial_code: '+968',
          code: 'OM',
        },
        {
          name: 'Pakistan',
          dial_code: '+92',
          code: 'PK',
        },
        {
          name: 'Palau',
          dial_code: '+680',
          code: 'PW',
        },
        {
          name: 'Palestinian Territory, Occupied',
          dial_code: '+970',
          code: 'PS',
        },
        {
          name: 'Panama',
          dial_code: '+507',
          code: 'PA',
        },
        {
          name: 'Papua New Guinea',
          dial_code: '+675',
          code: 'PG',
        },
        {
          name: 'Paraguay',
          dial_code: '+595',
          code: 'PY',
        },
        {
          name: 'Peru',
          dial_code: '+51',
          code: 'PE',
        },
        {
          name: 'Philippines',
          dial_code: '+63',
          code: 'PH',
        },
        {
          name: 'Pitcairn',
          dial_code: '+872',
          code: 'PN',
        },
        {
          name: 'Poland',
          dial_code: '+48',
          code: 'PL',
        },
        {
          name: 'Portugal',
          dial_code: '+351',
          code: 'PT',
        },
        {
          name: 'Puerto Rico',
          dial_code: '+1939',
          code: 'PR',
        },
        {
          name: 'Qatar',
          dial_code: '+974',
          code: 'QA',
        },
        {
          name: 'Romania',
          dial_code: '+40',
          code: 'RO',
        },
        {
          name: 'Russia',
          dial_code: '+7',
          code: 'RU',
        },
        {
          name: 'Rwanda',
          dial_code: '+250',
          code: 'RW',
        },
        {
          name: 'Reunion',
          dial_code: '+262',
          code: 'RE',
        },
        {
          name: 'Saint Barthelemy',
          dial_code: '+590',
          code: 'BL',
        },
        {
          name: 'Saint Helena, Ascension and Tristan Da Cunha',
          dial_code: '+290',
          code: 'SH',
        },
        {
          name: 'Saint Kitts and Nevis',
          dial_code: '+1869',
          code: 'KN',
        },
        {
          name: 'Saint Lucia',
          dial_code: '+1758',
          code: 'LC',
        },
        {
          name: 'Saint Martin',
          dial_code: '+590',
          code: 'MF',
        },
        {
          name: 'Saint Pierre and Miquelon',
          dial_code: '+508',
          code: 'PM',
        },
        {
          name: 'Saint Vincent and the Grenadines',
          dial_code: '+1784',
          code: 'VC',
        },
        {
          name: 'Samoa',
          dial_code: '+685',
          code: 'WS',
        },
        {
          name: 'San Marino',
          dial_code: '+378',
          code: 'SM',
        },
        {
          name: 'Sao Tome and Principe',
          dial_code: '+239',
          code: 'ST',
        },
        {
          name: 'Saudi Arabia',
          dial_code: '+966',
          code: 'SA',
        },
        {
          name: 'Senegal',
          dial_code: '+221',
          code: 'SN',
        },
        {
          name: 'Serbia',
          dial_code: '+381',
          code: 'RS',
        },
        {
          name: 'Seychelles',
          dial_code: '+248',
          code: 'SC',
        },
        {
          name: 'Sierra Leone',
          dial_code: '+232',
          code: 'SL',
        },
        {
          name: 'Singapore',
          dial_code: '+65',
          code: 'SG',
        },
        {
          name: 'Slovakia',
          dial_code: '+421',
          code: 'SK',
        },
        {
          name: 'Slovenia',
          dial_code: '+386',
          code: 'SI',
        },
        {
          name: 'Solomon Islands',
          dial_code: '+677',
          code: 'SB',
        },
        {
          name: 'Somalia',
          dial_code: '+252',
          code: 'SO',
        },
        {
          name: 'South Africa',
          dial_code: '+27',
          code: 'ZA',
        },
        {
          name: 'South Sudan',
          dial_code: '+211',
          code: 'SS',
        },
        {
          name: 'South Georgia and the South Sandwich Islands',
          dial_code: '+500',
          code: 'GS',
        },
        {
          name: 'Spain',
          dial_code: '+34',
          code: 'ES',
        },
        {
          name: 'Sri Lanka',
          dial_code: '+94',
          code: 'LK',
        },
        {
          name: 'Sudan',
          dial_code: '+249',
          code: 'SD',
        },
        {
          name: 'Suriname',
          dial_code: '+597',
          code: 'SR',
        },
        {
          name: 'Svalbard and Jan Mayen',
          dial_code: '+47',
          code: 'SJ',
        },
        {
          name: 'Swaziland',
          dial_code: '+268',
          code: 'SZ',
        },
        {
          name: 'Sweden',
          dial_code: '+46',
          code: 'SE',
        },
        {
          name: 'Switzerland',
          dial_code: '+41',
          code: 'CH',
        },
        {
          name: 'Syrian Arab Republic',
          dial_code: '+963',
          code: 'SY',
        },
        {
          name: 'Taiwan',
          dial_code: '+886',
          code: 'TW',
        },
        {
          name: 'Tajikistan',
          dial_code: '+992',
          code: 'TJ',
        },
        {
          name: 'Tanzania, United Republic of Tanzania',
          dial_code: '+255',
          code: 'TZ',
        },
        {
          name: 'Thailand',
          dial_code: '+66',
          code: 'TH',
        },
        {
          name: 'Timor-Leste',
          dial_code: '+670',
          code: 'TL',
        },
        {
          name: 'Togo',
          dial_code: '+228',
          code: 'TG',
        },
        {
          name: 'Tokelau',
          dial_code: '+690',
          code: 'TK',
        },
        {
          name: 'Tonga',
          dial_code: '+676',
          code: 'TO',
        },
        {
          name: 'Trinidad and Tobago',
          dial_code: '+1868',
          code: 'TT',
        },
        {
          name: 'Tunisia',
          dial_code: '+216',
          code: 'TN',
        },
        {
          name: 'Turkey',
          dial_code: '+90',
          code: 'TR',
        },
        {
          name: 'Turkmenistan',
          dial_code: '+993',
          code: 'TM',
        },
        {
          name: 'Turks and Caicos Islands',
          dial_code: '+1649',
          code: 'TC',
        },
        {
          name: 'Tuvalu',
          dial_code: '+688',
          code: 'TV',
        },
        {
          name: 'Uganda',
          dial_code: '+256',
          code: 'UG',
        },
        {
          name: 'Ukraine',
          dial_code: '+380',
          code: 'UA',
        },
        {
          name: 'United Arab Emirates',
          dial_code: '+971',
          code: 'AE',
        },
        {
          name: 'United Kingdom',
          dial_code: '+44',
          code: 'GB',
        },
        {
          name: 'United States',
          dial_code: '+1',
          code: 'US',
        },
        {
          name: 'Uruguay',
          dial_code: '+598',
          code: 'UY',
        },
        {
          name: 'Uzbekistan',
          dial_code: '+998',
          code: 'UZ',
        },
        {
          name: 'Vanuatu',
          dial_code: '+678',
          code: 'VU',
        },
        {
          name: 'Venezuela, Bolivarian Republic of Venezuela',
          dial_code: '+58',
          code: 'VE',
        },
        {
          name: 'Vietnam',
          dial_code: '+84',
          code: 'VN',
        },
        {
          name: 'Virgin Islands, British',
          dial_code: '+1284',
          code: 'VG',
        },
        {
          name: 'Virgin Islands, U.S.',
          dial_code: '+1340',
          code: 'VI',
        },
        {
          name: 'Wallis and Futuna',
          dial_code: '+681',
          code: 'WF',
        },
        {
          name: 'Yemen',
          dial_code: '+967',
          code: 'YE',
        },
        {
          name: 'Zambia',
          dial_code: '+260',
          code: 'ZM',
        },
        {
          name: 'Zimbabwe',
          dial_code: '+263',
          code: 'ZW',
        },
      ];

      const countrycodeLabel = intl.formatMessage({ id: 'Country Code' });

      const countrycodeRequired = validators.required(
        intl.formatMessage({
          id: 'StripePaymentAddress.countrycodeRequired',
        })
      );

      const phoneNumberRequired = validators.required(
        intl.formatMessage({
          id: 'EditListingPersonalDetailsForm.phoneNumberRequired',
        })
      );
      //

      return (
        <Form className={classes} onSubmit={handleSubmit}>
          {errorMessageCreateListingDraft}
          {errorMessageUpdateListing}
          {errorMessageShowListing}
          {/* <FieldTextInput
            id="title"
            name="title"
            className={css.title}
            type="text"
            label={titleMessage}
            placeholder={titlePlaceholderMessage}
            maxLength={TITLE_MAX_LENGTH}
            validate={composeValidators(required(titleRequiredMessage), maxLength60Message)}
            autoFocus
          /> */}
          <FieldTextInput
            id="title"
            name="title"
            className={css.certificate}
            type="text"
            label="Title"
            placeholder={'Title'}
            // maxLength={TITLE_MAX_LENGTH}
            // validate={composeValidators(required('this field is required'), maxLength60Message)}
            autoFocus
          />

          {/* <>
            {' '}
            <FieldSelect
              id={'type'}
              name={'type'}
              className={css.listingTypeSelect}
              label={intl.formatMessage({ id: 'EditListingDetailsForm.listingTypeLabel' })}
              validate={required(
                intl.formatMessage({ id: 'EditListingDetailsForm.listingTypeRequired' })
              )}
              // onChange={handleOnChange}
            >
              <option disabled value="">
                {intl.formatMessage({ id: 'EditListingDetailsForm.listingTypePlaceholder' })}
              </option>
              {listingTypes.map(config => {
                const type = config.listingType;
                return (
                  <option key={type} value={type}>
                    {config.label}
                  </option>
                );
              })}
            </FieldSelect>
          </> */}

          {/* <div className={css.phonWrap}>
            <FieldSelect
              id={`phoneCountryCode`}
              name="phoneCountryCode"
              // disabled={disabled}
              className={css.field}
              label={countrycodeLabel}
              validate={countrycodeRequired}
            >
              <option disabled value="" className={css.field}>
                {countrycodePlaceholder}
              </option>
              {countryCodes.map(country => {
                return (
                  <option key={country.dial_code} value={country.dial_code}>
                    {`${country.name} (${country.dial_code})`}
                  </option>
                );
              })}
            </FieldSelect>
            <FieldPhoneNumberInput
              className={css.phone}
              name="phoneNumber"
              id={'phoneNumber'}
              label={phoneNumberTitleMessage}
              placeholder={phoneNumberPlaceholderMessage}
              validate={phoneNumberRequired}
            />
          </div>

          <div className={css.genderContainer}>
            {genderLabel}
            <FieldRadioButton
              id="gender-male"
              name="gender"
              className={css.gender}
              label={maleGenderLabel}
              value="male"
            />

            <FieldRadioButton
              id="gender-female"
              name="gender"
              className={css.gender}
              label={femaleGenderLabel}
              value="female"
            />

            <FieldRadioButton
              id="gender-other"
              name="gender"
              className={css.gender}
              label={otherGenderLabel}
              value="other"
            />
          </div> */}

          {/* <FieldTextInput
            id="description"
            name="description"
            className={css.description}
            type="textarea"
            label={descriptionMessage}
            placeholder={descriptionPlaceholderMessage}
            ///
            validate={composeValidators(required(descriptionRequiredMessage))}
          />

          <div className={css.usCitizenContainer}>
            {'What category are you coaching in?'}
            <FieldCheckbox
              id="category-fitness"
              name="category"
              className={css.usCitizen}
              label={'Fitness'}
              value="fitness"
            />
            <FieldCheckbox
              id="category-professional-skills"
              name="category"
              className={css.usCitizen}
              label={'Professional skills'}
              value="professional skills"
            />
            <FieldCheckbox
              id="category-creative"
              name="category"
              className={css.usCitizen}
              label={'Creative'}
              value="creative"
            />
          </div>

          <br />

          <div className={css.usCitizenContainer}>
            {'Choose your activity'}
            <h4>Fitness activities</h4>
            <FieldCheckbox
              id="activity-personal-training"
              name="activity"
              className={css.isInfoCorrect}
              label={'Personal training'}
              value="personal training"
            />
            <FieldCheckbox
              id="activity-football"
              name="activity"
              className={css.consentPayThroughProlead}
              label={'Football'}
              value="football"
            />
            <FieldCheckbox
              id="activity-basketball"
              name="activity"
              className={css.consentProleadsPolicy}
              label={'Basketball'}
              value="basketball"
            />
            <FieldCheckbox
              id="activity-rugby"
              name="activity"
              className={css.consentStripeAgreement}
              label={'Rugby'}
              value="rugby"
            />
            <FieldCheckbox
              id="activity-tennis"
              name="activity"
              className={css.consentStripeAgreement}
              label={'Tennis'}
              value="tennis"
            />
            <FieldCheckbox
              id="activity-badminton"
              name="activity"
              className={css.consentStripeAgreement}
              label={'Badminton'}
              value="badminton"
            />
            <FieldCheckbox
              id="activity-weightlifting"
              name="activity"
              className={css.consentStripeAgreement}
              label={'Weightlifting'}
              value="weightlifting"
            />
            <FieldCheckbox
              id="activity-running"
              name="activity"
              className={css.consentStripeAgreement}
              label={'Running'}
              value="running"
            />
            <FieldCheckbox
              id="otherActivity"
              name="activity"
              className={css.consentStripeAgreement}
              label={'Other'}
              value="other"
            />
            {values?.activity?.some(value => value === 'other') && (
              <FieldTextInput
                id="activityOther"
                name="activityOther"
                className={css.certificate}
                type="textarea"
                placeholder={'Specify your fitness activity'}
                // value="activityOther"
                validate={composeValidators(required(hearedAboutRequiredMessage))}
                //
                label={'Other activities'}
              />
            )}

            <h4>Professional activities</h4>
            <FieldCheckbox
              id="activity-academics"
              name="activity"
              className={css.consentStripeAgreement}
              label={'Academics'}
              value="academics"
            />
            <FieldCheckbox
              id="activity-leadership"
              name="activity"
              className={css.consentStripeAgreement}
              label={'Leadership'}
              value="leadership"
            />
            <FieldCheckbox
              id="activity-management"
              name="activity"
              className={css.consentStripeAgreement}
              label={'Management'}
              value="management"
            />
            <h4>Creativity</h4>
            <FieldCheckbox
              id="activity-musical-instruments"
              name="activity"
              className={css.consentStripeAgreement}
              label={'Musical instruments'}
              value="musical instruments"
            />
            <FieldCheckbox
              id="activity-visual-arts"
              name="activity"
              className={css.consentStripeAgreement}
              label={'Visual arts'}
              value="visual arts"
            />
            <FieldCheckbox
              id="activity-dance"
              name="activity"
              className={css.consentStripeAgreement}
              label={'Dance'}
              value="dance"
            />
          </div>

          <br />

          <div className={css.usCitizenContainer}>
            {'What group ages do you train?'}
            <FieldCheckbox
              id="seniorAge"
              name="ageCategory"
              className={css.isInfoCorrect}
              label={'Senior'}
              value="senior"
            />

            <FieldCheckbox
              id="adultAge"
              name="ageCategory"
              className={css.consentPayThroughProlead}
              label={'Adult'}
              value="adult"
            />

            <FieldCheckbox
              id="teenAge"
              name="ageCategory"
              className={css.consentProleadsPolicy}
              label={'Teen'}
              value="teen"
            />

            <FieldCheckbox
              id="childAge"
              name="ageCategory"
              className={css.consentStripeAgreement}
              label={'Child'}
              value="child"
            />
          </div> */}

          {/* <FieldTextInput
            id="certificate"
            name="certificate"
            className={css.certificate}
            type="textarea"
            label={certificateLabel}
            placeholder={certificatePlaceholderMessage}
          /> */}

          {/* <div className={css.usCitizenContainer}>
            {sgCitizenLabel}
            <FieldRadioButton
              id="us-citizen-yes"
              name="usCitizen"
              className={css.usCitizen}
              label={usCitizenLabelYes}
              value="yes"
            />
            <FieldRadioButton
              id="us-citizen-no"
              name="usCitizen"
              className={css.usCitizen}
              label={usCitizenLabelNo}
              value="no"
            />
          </div>

          <div className={css.allowedToWorkContainer}>
            {allowedToWorkLabel}
            <FieldRadioButton
              id="allowed-to-work-yes"
              name="allowedToWork"
              className={css.allowedToWork}
              label={allowedToWorkLabelYes}
              value="yes"
            />
            <FieldRadioButton
              id="allowed-to-work-no"
              name="allowedToWork"
              className={css.allowedToWork}
              label={allowedToWorkLabelNo}
              value="no"
            />
          </div>

          <div className={css.convictedContainer}>
            {convictedLabel}
            <FieldRadioButton
              id="convicted-yes"
              name="convicted"
              className={css.convicted}
              label={convictedLabelYes}
              value="yes"
            />
            <FieldRadioButton
              id="convicted-no"
              name="convicted"
              className={css.convicted}
              label={convictedLabelNo}
              value="no"
            />
          </div>

          <FieldTextInput
            id="heared-about"
            name="hearedAbout"
            className={css.description}
            type="textarea"
            label={hearedAboutMessage}
            placeholder={hearedAboutPlaceholderMessage}
            validate={composeValidators(required(hearedAboutRequiredMessage))}
          /> */}

          {/* <FieldCheckbox
            id="grant-prolead-discretion"
            name="grantProleadDiscretion"
            className={css.grantProleadDiscretion}
            label={grantProleadDiscretionLabel}
            value="yes"
            required
          /> */}

          <PrimaryButton
            className={css.submitButton}
            type="submit"
            inProgress={submitInProgress}
            disabled={submitDisabled}
            ready={submitReady}
          >
            {saveActionMsg}
          </PrimaryButton>
          <Footnote message={footnote} />
        </Form>
      );
    }}
  />
);

EditListingPersonalDetailsFormComponent.defaultProps = { className: null, fetchErrors: null };

EditListingPersonalDetailsFormComponent.propTypes = {
  className: string,
  intl: intlShape.isRequired,
  onSubmit: func.isRequired,
  saveActionMsg: string.isRequired,
  disabled: bool.isRequired,
  ready: bool.isRequired,
  updated: bool.isRequired,
  updateInProgress: bool.isRequired,
  fetchErrors: shape({
    createListingDraftError: propTypes.error,
    showListingsError: propTypes.error,
    updateListingError: propTypes.error,
  }),
  certificateOptions: arrayOf(
    shape({
      key: string.isRequired,
      label: string.isRequired,
    })
  ),
};

export default compose(injectIntl)(EditListingPersonalDetailsFormComponent);
