import _ from 'lodash';
import { useEffect, useRef, useState } from 'react';
import * as Yup from 'yup';
import { ensureOwnListing } from '../../util/data';

const useStepperHook = props => {
    const {
        section,
        history,
        onImageUpload,
        createInitialValues,
        onCreateListing,
        onUpdateProfile,
        page,
        image,
        images,
        onUpsertListingDraft,
        onUpdateListing,
        getOwnListing,
        onAddAvailabilityException,
        currentUserData,
    } = props;

    const [currentListingId, setCurrentListingId] = useState({});

    const listingId = currentListingId || page.submittedListingId || null;
    //   console.log(currentUserData, 'currentUseddd');
    const listing = getOwnListing(listingId);
    const currentListing = ensureOwnListing(listing);
    const {
        createListingDraftError = null,
        publishListingError = null,
        updateListingError = null,
        showListingsError = null,
        uploadImageError = null,
        fetchExceptionsError = null,
        addExceptionError = null,
        deleteExceptionError = null,
    } = page;

    const errorsApis = {
        createListingDraftError,
        publishListingError,
        updateListingError,
        showListingsError,
        uploadImageError,
        fetchExceptionsError,
        addExceptionError,
        deleteExceptionError,
    };

    const INITIAL_VALUES = [
        {
            title: '',
            nationality: '',
            usCitizen: '',
            gender: '',
            DOB: '',
            homeAddress: '',
            phoneNumber: '',
            nricFin: '',
        },
        {
            homeAddress: '',
            hearFrom: '',
            displayName: '',
            role: '',
            about: '',
        },
        {},
        {
            discriptionTitle: '',
            category: '',
            ageCategory: '',
            description: '',
            pricing_hourly: '',
            requestLocation: '',
            online: '',
            myLocation: '',
            location: { search: '', predictions: [], selectedPlace: null },
            location1: { search: '', predictions: [], selectedPlace: null },
            location2: { search: '', predictions: [], selectedPlace: null },
        },
    ];

    useEffect(() => {
        if (page.submittedListingId) {
            setCurrentListingId(page.submittedListingId);
        }
    }, [page.submittedListingId]);

    const LEARNER_VALIDATION = {
        phoneNumber: Yup.string()
            .trim()
            .required('This field is required'),
        homeAddress: Yup.string()
            .trim()
            .required('This field is required'),
        hearFrom: Yup.string()
            .trim()
            .required('This field is required'),
        displayName: Yup.string()
            .trim()
            .required('This field is required'),
    };
    const learnerValidationSchema = Yup.object().shape(LEARNER_VALIDATION);

    const proValidationSchema = [
        Yup.object().shape({
            title: Yup.string()
                .trim()
                .required('this field is required'),
            phoneNumber: Yup.string()
                .trim()
                .required('this field is required'),
            usCitizen: Yup.string()
                .trim()
                .required('this field is required'),
            gender: Yup.string()
                .trim()
                .required('this field is required'),
            nationality: Yup.string()
                .trim()
                .required('this field is required'),
            DOB: Yup.string()
                .trim()
                .required('this field is required'),
            homeAddress: Yup.string()
                .trim()
                .required('this field is required'),
            nricFin: Yup.string()
                .trim()
                .required('this field is required'),
        }),
        Yup.object().shape({
            ..._.omit(LEARNER_VALIDATION, ['phoneNumber']),
            role: Yup.string()
                .trim()
                .required('this field is required'),
            about: Yup.string()
                .trim()
                .required('this field is required'),
            hearFrom: Yup.string()
                .trim()
                .required('this field is required'),
        }),
        null,
        Yup.object().shape({
            discriptionTitle: Yup.string()
                .trim()
                .required('this field is required'),
            category: Yup.string()
                .trim()
                .required('this field is required'),
            ageCategory: Yup.string()
                .trim()
                .required('this field is required'),
            description: Yup.string()
                .trim()
                .required('this field is required'),
            pricing_hourly: Yup.string()
                .trim()
                .required('this field is required'),
            requestLocation: Yup.string()
                .trim()
                .notRequired('this field is required'),
            online: Yup.string()
                .trim()
                .notRequired('this field is required'),
            myLocation: Yup.string()
                .trim()
                .notRequired('this field is required'),
        }),
        null,
    ];

    const [step, setStep] = useState(0);
    const STEPS =
        section === 'pro'
            ? [
                { title: 'Step One' },
                { title: 'Step Two' },
                { title: 'Step Three' },
                { title: 'Step Four' },
            ]
            : [{ title: 'Step One' }, { title: 'Step Two' }];
    const isLastStep = step === STEPS.length - 1;

    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }, [step]);

    function _sleep(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    function _handleBack() {
        setStep(step - 1);
    }

    function _handleNext(section = 'learner') {
        // console.log(section, isLastStep, 'isLastStep');
        if (section === 'learner' && isLastStep) {
            history.push('/listings');
        }
        //  else {
        //     console.log(isLastStep, step, step + 1, step + 1 > STEPS?.length)
        //     if ((isLastStep && step + 1 > STEPS?.length)) {
        //         history.push('/listings');
        //     }
        // }
        setStep(step + 1);
    }

    const [imageUpload, setImageUpload] = useState({ preview: '', raw: '' });
    const [listingImageUpload, setListingImageUpload] = useState({ preview: '', raw: '' });
    const inputRef = useRef(null);
    const listingInputRef = useRef(null);

    function handleClick() {
        inputRef.current.click();
    }
    function handleClickListing() {
        listingInputRef.current.click();
    }

    const onImageUploadHandler = (values, fn) => {
        const { id, imageId, file } = values;
        if (file) {
            fn({ id, imageId, file });
        }
    };

    const _ImageChange = (event, setImage) => {
        const fileObj = event.target.files && event.target.files[0];
        if (!fileObj) {
            setImage({ preview: null, raw: null, errMsg: 'this field is required' });
            return;
        }
        setImage({ preview: URL.createObjectURL(fileObj), raw: fileObj });
        const tempId = `${fileObj.name}_${Date.now()}`;
        onImageUploadHandler({ id: tempId, file: fileObj }, data => {
            onImageUpload(data, resp => {
                if (isLastStep && resp?.id) {
                    setImagesIdsUpload([...imagesIdsTOUpload, resp]);
                }
            });
        });
        event.target.value = null;
    };

    const _handleImageChange = event => {
        _ImageChange(event, setImageUpload);
    };

    const _handleListingImageChange = event => {
        _ImageChange(event, setListingImageUpload);
    };

    // ************************************************ pro function *************************************
    const [selectedWhatSell, setSelectedWhatSell] = useState('');
    const [calenderAvablility, setCalenderAvablility] = useState(null);
    const [priceValues, setPriceValues] = useState({ old: null, new: null });
    const [locationArray, setLocationArray] = useState(['location']);
    const [exceptionData, setExceptionData] = useState({
        listingId: null,
        seats: null,
        start: '',
        end: '',
    });

    const [imagesIdsTOUpload, setImagesIdsUpload] = useState([]);

    const imageIds = images => {
        return images ? images.map(img => img?.imageId || img?.id) : null;
    };

    async function _submitProForm(values, actions) {
        try {
            await _sleep(1000);

            const {
                title,
                usCitizen,
                phoneCountryCode,
                homeAddress,
                gender,
                DOB,
                nationality,
                nricFin,
                phoneNumber,
                hearFrom,
                displayName,
                role,
                about,
            } = values;

            if (isLastStep) {
                const {
                    location,
                    location2,
                    location3,
                    discriptionTitle,
                    description,
                    category,
                    ageCategory,
                    requestLocation,
                    online,
                    myLocation,
                } = values;
                // console.log(online, 'online')

                const address = location && location.selectedPlace && location.selectedPlace.address;
                const origin = location && location.selectedPlace && location.selectedPlace.origin;

                const address2 = location2 && location2.selectedPlace && location2.selectedPlace.address;
                const origin2 = location2 && location2.selectedPlace && location2.selectedPlace.origin;

                const address3 = location3 && location3.selectedPlace && location3.selectedPlace.address;
                const origin3 = location3 && location3.selectedPlace && location3.selectedPlace.origin;

                const VALUES_UPDATE = {
                    title: discriptionTitle,
                    description: description,

                    price: {
                        _sdkType: 'Money',
                        amount: +(priceValues?.new + '00'),
                        currency: 'SGD',
                    },
                    availabilityPlan: calenderAvablility?.availabilityPlan,
                    publicData: {
                        usCitizen,
                        gender,
                        homeAddress,

                        nationality,
                        DOB,
                        nricFin,
                        displayName: displayName || '',
                        activity: [role, 'other'] || '',
                        about: about || '',
                        phoneNumber,
                        certificate: null,
                        allowedToWork: 'no',
                        convicted: 'no',
                        ageCategory: [ageCategory],
                        category: [category],
                        // "activity": [
                        //     "personal training",
                        //     "football"
                        // ],
                        activityOther: null,
                        sports: null,
                        achievements: null,
                        specializations: null,
                        exclusiveOnlineTraining: online ? 'yes' : 'no',
                        virtualTraining: null,
                        travelWilling: null,
                        location: { address, building: null },
                        location2: {
                            address: address2 || null,
                            buildingAddress: null,
                            origin: origin2 || null,
                        },
                        location3: {
                            address: address3 || null,
                            buildingAddress: null,
                            origin: origin3 || null,
                        },
                        // rules: "dfsd",
                        // achieve: "sdf",
                        // "~:fitnessOther": null,
                        // "~:esportsOther": null,
                        // "~:educationOther": null,
                        // "~:creativeOther": null,
                        // "~:convenientArea": null
                    },
                    privateData: {
                        phoneNumber,
                        phoneCountryCode: '+' + phoneCountryCode,
                        hearedAbout: hearFrom || '',
                        grantProleadDiscretion: null,
                    },
                    images: typeof listingImageUpload !== 'undefined' ? imageIds(imagesIdsTOUpload) : {},
                };
                const GEOLOCATION_DATA = origin && !online ? { ...VALUES_UPDATE, geolocation: origin } : VALUES_UPDATE;
                onCreateListing(GEOLOCATION_DATA, data => {
                    setTimeout(() => {
                        if (exceptionData?.start) {
                            onAddAvailabilityException({ ...exceptionData, listingId: data?.data?.data?.id })
                                .then(() => {
                                    //   console.log('/pro/${currentUserData?.uuid}', `/pro/${currentUserData?.uuid}`);
                                    // history.push(`/pro/${currentUserData?.uuid}`)
                                    window.location.assign(`/pro/${currentUserData?.uuid}`);
                                })
                                .catch(err => alert('somthing went wrong try again'));
                        } else {
                            //   console.log('/pro/${currentUserData?.uuid}', `/pro/${currentUserData?.uuid}`);
                            window.location.assign(`/pro/${currentUserData?.uuid}`);
                        }
                    }, 1000);
                    // actions.setSubmitting(false);
                    // setStep(step + 1);
                }).catch(err => alert('somthing went wrong try again'));
            } else {
                const uploadedImage = props?.image;

                const {
                    title,
                    usCitizen,
                    phoneCountryCode,
                    homeAddress,
                    gender,
                    DOB,
                    nationality,
                    nricFin,
                    phoneNumber,
                    hearFrom,
                    displayName,
                    role,
                    about,
                } = values;

                const VALUES_UPDATE = {
                    firstName: displayName || title || '',
                    lastName: displayName || title || '',
                    bio: about || '',
                    publicData: {
                        pricipleName: title,
                        usCitizen,
                        gender,
                        nationality,
                        DOB,
                        hearedAbout: hearFrom || '',
                        displayName: displayName || '',
                        activity: role || '',
                    },
                    protectedData: {
                        nricFin,
                        phoneNumber,
                        preferredName: displayName,
                        homeAddress,
                        phoneNumberCountryCode: '+' + phoneCountryCode,
                    },
                };

                const updatedValues =
                    uploadedImage && uploadedImage.imageId && uploadedImage.file
                        ? { ...VALUES_UPDATE, profileImageId: uploadedImage.imageId }
                        : VALUES_UPDATE;

                onUpdateProfile(updatedValues);
                actions.setSubmitting(false);
                setStep(step + 1);
            }

            // if (step == 0 && !currentListing?.id) {
            //     console.log(values, VALUES_UPDATE);
            //     onCreateListingDraft(VALUES_UPDATE, () => {
            //         actions.setSubmitting(false);
            //         setStep(step + 1);
            //     })
            // } else {
            //     onUpdateListing(null, { ...upsertValues, id: currentListing.id }, 'signup');
            //     actions.setSubmitting(false);
            //     setStep(step + 1);
            // }
        } catch (err) {
            console.log(err);
        }
    }

    // ************************************************ Learner function *************************************

    // learner step 2
    const [selectedButton, setSelectedButton] = useState([]);
    function _handleInterested(val) {
        if (_.includes(selectedButton, val?.value)) {
            const rejectedValue = _.reject(selectedButton, r => r === val?.value);
            setSelectedButton(rejectedValue);
        } else {
            setSelectedButton([...selectedButton, val?.value]);
        }
    }

    async function _submitLearnerForm(values, actions) {
        await _sleep(1000);
        if (step === 0) {
            const uploadedImage = props?.image;
            if (!imageUpload?.raw) {
                setImage({ preview: null, raw: null, errMsg: 'this field is required' });
                return;
            }

            // Update profileImage only if file system has been accessed
            const profile = {
                firstName: createInitialValues?.firstName.trim(),
                lastName: createInitialValues?.lastName.trim(),
                bio: '',
                publicData: {
                    displayName: values?.displayName,
                    hearFrom: values?.hearFrom,
                    interested: selectedButton,
                },
                protectedData: {
                    phoneNumber: values?.phoneNumber,
                    homeAddress: values?.homeAddress,
                    phoneNumberCountryCode: values?.phoneCountryCode,
                },
            };
            const updatedValues =
                uploadedImage && uploadedImage.imageId && uploadedImage.file
                    ? { ...profile, profileImageId: uploadedImage.imageId }
                    : profile;

            onUpdateProfile(updatedValues);
            actions.setSubmitting(false);
            if (!isLastStep) {
                setStep(step + 1);
            }
        } else {
            window.location.assign('/listings');
        }
    }

    return {
        // states
        locationArray,
        setLocationArray,
        step,
        setStep,
        inputRef,
        imageUpload,
        isLastStep,
        STEPS,
        selectedButton,
        setSelectedWhatSell,
        setCalenderAvablility,
        priceValues,
        setPriceValues,
        exceptionData,
        setExceptionData,

        // functions
        _submitLearnerForm,
        _submitProForm,
        _handleBack,
        _handleNext,
        _handleImageChange,
        handleClick,
        _handleInterested,
        _handleListingImageChange,
        handleClickListing,

        // constants
        INITIAL_VALUES,
        errorsApis,
        listingInputRef,
        imagesIdsTOUpload,
        learnerValidationSchema,
        proValidationSchema,
        ...props,
    };
};

export default useStepperHook;
