import css from './HowItWorks.module.css';

const HowItWorks = () => {
  return (
    <div className={css.howitworks}>
      <span className={css.howitworks_label}>How it works</span>
      <h2 className={css.howitworks_title}>Enhance your business just in few clicks</h2>
    </div>
  );
};

export default HowItWorks;
