import styled from 'styled-components';

const CreatSingupStyle = styled.div`
  .MainCreateAccount_Class {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    @media (max-width: 991px) {
      grid-template-columns: repeat(1, 1fr);
      gap: 30px;
    }
    .createFirstSection {
      h1 {
        font-size: 30px;
        font-weight: 600;
        line-height: 38px;
        letter-spacing: -0.02em;
        text-align: left;
        margin-top: 0;
        color: #1c1c1c;
        @media (max-width: 991px) {
          font-size: 24px;
          margin-bottom: 23px;
        }
      }
      .actionsButton {
        button {
          font-size: 16px;
          max-width: 100%;
          &:first-child {
            background: #f4333d;
            margin-bottom: 16px;
            font-weight: 600;
          }
          &:last-child {
            background: #f3f3f3;
            color: #1c1c1c;
            gap: 10px;
            font-weight: var(--fontWeightRegular);
            svg {
              width: 15px;
            }
            @media (max-width: 991px) {
              padding: 8px 10px;
            }
          }
        }
      }

      .signupForm {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 445px;
        label {
          font-weight: var(--fontWeightRegular);
        }
        @media (max-width: 991px) {
          height: inherit;
          gap: 64px;
        }
        .allInput_section {
          display: flex;
          flex-direction: column;
          gap: 18px;
          @media (max-width: 991px) {
            gap: 16px;
          }
          .inputField {
            width: 100%;
            input {
              width: 100%;
              height: 48px;
              border: 1px solid #ddd !important;
              border-radius: 6px;
              padding: 10px;
              font-size: 16px;
            }
          }
          .firstLast_name {
            display: flex;
            justify-content: space-between;
            gap: 10px;

            input {
              max-width: 100%;
            }
          }

          input[type='checkbox'] {
            font-size: 16px;
            display: none;
            & + label {
              padding-left: 30px;
              position: relative;
              font-weight: var(--fontWeightRegular);
            }

            & + label::before {
              display: block;
              border: 1px solid #999;
              border-radius: 4px;
              left: 3px;
              top: 9px;
              height: 18px;
              width: 18px;
              content: '';
              position: absolute;
              line-height: 14px;
            }

            &:invalid + label {
              color: red;
            }

            &:invalid + label::before {
              border-color: red;
            }

            &:checked + label::before {
              content: '✓';
              text-indent: 2px;
            }
          }
        }

        .formsubmit {
          text-align: center;
          p {
            font-size: 16px;
            color: #1c1c1c;
            line-height: normal;
            a {
              color: var(--blue-primary);
            }
          }
          @media (max-width: 991px) {
            button {
              font-size: 16px;
              margin-bottom: 20px;
            }
          }
        }
      }
    }
  }
`;

export default CreatSingupStyle;
