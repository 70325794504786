import css from './Flow.module.css';
import flow1 from '../../../assets/become-pro/flow-1.png';
import flow2 from '../../../assets/become-pro/flow-2.png';
import flow3 from '../../../assets/become-pro/flow-3.png';

const Flow = () => {
  return (
    <div className={css.flow}>
      <div className={css.flow_1}>
        <div className={css.flow_container}>
          <div>
            <span className={css.flow_number}>01</span>
            <h3 className={css.flow_title}>Become a Pro</h3>
            <p className={css.flow_desc}>
              Create your profile and storefront in less than 10 minutes - share your experience,
              certificates, and achievements.
            </p>
          </div>
          <img src={flow1} alt="flow-1" className={css.flow_img} />
        </div>
      </div>
      <div className={css.flow_2}>
        <div className={css.flow_container_reverse}>
          <div>
            <span className={css.flow_number}>02</span>
            <h3 className={css.flow_title}>Create listing</h3>
            <p className={css.flow_desc}>
              Describe your service or product, enhance it with eye-catching photos, we'll assist
              you!
            </p>
          </div>
          <img src={flow2} alt="flow-2" className={css.flow_img} />
        </div>
      </div>
      <div className={css.flow_3}>
        <div className={css.flow_container}>
          <div>
            <span className={css.flow_number}>03</span>
            <h3 className={css.flow_title}>Get paid</h3>
            <p className={css.flow_desc}>
              Promote your profile and make secure payments through our platform.
            </p>
          </div>
          <img src={flow3} alt="flow-2" className={css.flow_img} />
        </div>
      </div>
    </div>
  );
};

export default Flow;
