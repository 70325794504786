import { useHistory } from 'react-router-dom';
import css from './CategoriesBar.module.css';
import Menu from '../Menu/Menu';
import MenuLabel from '../MenuLabel/MenuLabel';
import MenuContent from '../MenuContent/MenuContent';
import MenuItem from '../MenuItem/MenuItem';
import NamedLink from '../NamedLink/NamedLink';

const ArrowDown = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="arrow-down">
      <path
        id="Vector"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.55806 7.07459C4.80214 6.80847 5.19786 6.80847 5.44194 7.07459L9.55806 11.5625C9.80214 11.8286 10.1979 11.8286 10.4419 11.5625L14.5581 7.07459C14.8021 6.80847 15.1979 6.80847 15.4419 7.07459C15.686 7.34072 15.686 7.77219 15.4419 8.03832L11.3258 12.5262C10.5936 13.3246 9.40641 13.3246 8.67418 12.5262L4.55806 8.03831C4.31398 7.77219 4.31398 7.34072 4.55806 7.07459Z"
        fill="#313131"
      />
    </g>
  </svg>
);

const fitness = [
  'Personal training',
  'Strength Training',
  'Functional Training',
  'Cardio',
  'Stretching',
  'Sports Recovery',
];

const sports = [
  'Combat sports',
  'Football',
  'Badminton',
  'Basketball',
  'Tennis',
  'Swimming',
  'Volleyball',
  'Table tennis',
  'Gymnastics',
  'Track & Field',
];

const trainWithPro = [
  'Olympic Athletes',
  'SEA Games Athlete',
  'Student Athlete',
  'University Athlete',
  'SEMI Pro',
  'Under 20 National Team',
  'National Athletes',
];

const educations = [
  'Academics',
  'Leadership',
  'Tools',
  'Mentorship',
  'Languages',
  'Mathematics',
  'Sciences',
  'Humanities',
  'Leadership Coaching',
  'Professional Skills',
];

const arts = ['Dance', 'Musical instruments', 'Visual arts', 'Music Theory', 'Production'];

const CategoriesBar = () => {
  const history = useHistory();

  return (
    <div className={css.homeCategoriesContianer}>
      <div className={css.homeCategories}>
        <Menu>
          <MenuLabel className={css.menuLabel}>
            Fitness
            <ArrowDown />
          </MenuLabel>
          <MenuContent className={css.menuContent}>
            {fitness.map(v => (
              <MenuItem
                className={css.menuItem}
                key={v}
                onClick={() =>
                  history.push(
                    `/listings?pub_category=fitness&pub_activity=${v.toLocaleLowerCase()}`
                  )
                }
              >
                {v}
              </MenuItem>
            ))}
          </MenuContent>
        </Menu>
        <Menu>
          <MenuLabel className={css.menuLabel}>
            Sports
            <ArrowDown />
          </MenuLabel>
          <MenuContent className={css.menuContent}>
            {sports.map(v => (
              <MenuItem
                className={css.menuItem}
                key={v}
                onClick={() =>
                  history.push(
                    `/listings?pub_category=sports&pub_activity=${v.toLocaleLowerCase()}`
                  )
                }
              >
                {v}
              </MenuItem>
            ))}
          </MenuContent>
        </Menu>
        <Menu>
          <MenuLabel className={css.menuLabel}>
            Train with a Pro
            <ArrowDown />
          </MenuLabel>
          <MenuContent className={css.menuContent}>
            {trainWithPro.map(v => (
              <MenuItem
                className={css.menuItem}
                key={v}
                onClick={() => history.push(`/listings?is_find_pro=true`)}
              >
                {v}
              </MenuItem>
            ))}
          </MenuContent>
        </Menu>
        <Menu>
          <MenuLabel className={css.menuLabel}>
            Education & Skills
            <ArrowDown />
          </MenuLabel>
          <MenuContent className={css.menuContent}>
            {educations.map(v => (
              <MenuItem
                className={css.menuItem}
                key={v}
                onClick={() =>
                  history.push(
                    `/listings?pub_category=education&pub_activity=${v.toLocaleLowerCase()}`
                  )
                }
              >
                {v}
              </MenuItem>
            ))}
          </MenuContent>
        </Menu>
        <Menu>
          <MenuLabel className={css.menuLabel}>
            Arts, Dance, & Music
            <ArrowDown />
          </MenuLabel>
          <MenuContent className={css.menuContent}>
            {arts.map(v => (
              <MenuItem
                className={css.menuItem}
                key={v}
                onClick={() =>
                  history.push(
                    `/listings?pub_category=creative&pub_activity=${v.toLocaleLowerCase()}`
                  )
                }
              >
                {v}
              </MenuItem>
            ))}
          </MenuContent>
        </Menu>
        <NamedLink
          className={css.menuLabel}
          name="CategoriesPage"
          to={{
            search:
              '?bounds=1.68008452%2C104.08284892%2C1.00507067%2C103.59952873&pub_category=esports',
          }}
        >
          Esports
        </NamedLink>
      </div>
    </div>
  );
};

export default CategoriesBar;
