import { Field, Form, Formik } from 'formik';
import React from 'react';
import SocialMediaSignup from './SocialMediaSignup';
import { FormattedMessage, injectIntl } from 'react-intl';
import CreatSingupStyle from '../styles/CreatSingup.style';
import { InputFieldComp } from './CommonComp/FieldComponent';
import { compose } from 'redux';

const CreateAccountForm = props => {

    const {
        _handleCreateAccountSubmit,
        createInitialValues,
        validationSchema,
        _intelMessage,
        setLoginPage,
        setTosModalOpen,
        signupError,
        signupErrorMessage,
        authWithGoogle,
        authWithFacebook,
        history,
        section
    } = props;
    const termsLink = (
        <a
            className="linksingup"
            onClick={() => setTosModalOpen(true)}
            role="button"
            tabIndex="0"
        // onKeyUp={handleTermsKeyUp}
        >
            <FormattedMessage id="SignupForm.termsAndConditionsLinkText" />
        </a>
    );
    return (
        <CreatSingupStyle>
            <div className="MainCreateAccount_Class">
                <section className="createFirstSection commonWidth">
                    <h1>Create an account</h1>
                    <div className="actionsButton">
                        <button>Retrive Myinfo with singpass</button>
                        <SocialMediaSignup {...{ authWithGoogle, authWithFacebook }} />
                    </div>
                    <Formik
                        enableReinitialize
                        validationSchema={validationSchema}
                        initialValues={createInitialValues}
                        onSubmit={(values, action) => _handleCreateAccountSubmit(values)}
                    >
                        {props => (
                            <Form className="signupForm">
                                <div className="allInput_section fieldCommonCss">
                                    <div className="field firstLast_name">
                                        <InputFieldComp
                                            name="firstName"
                                            label={_intelMessage('SignupForm.firstNameLabel')}
                                            placeholder={_intelMessage('SignupForm.firstNamePlaceholder')}
                                        />
                                        <InputFieldComp
                                            name="lastName"
                                            label={_intelMessage('SignupForm.lastNameLabel')}
                                            placeholder={_intelMessage('SignupForm.lastNamePlaceholder')}
                                        />
                                    </div>
                                    <InputFieldComp
                                        name="email"
                                        label={_intelMessage('SignupForm.emailLabel')}
                                        placeholder={_intelMessage('SignupForm.emailPlaceholder')}
                                    />
                                    <InputFieldComp
                                        type='password'
                                        name="password"
                                        label={_intelMessage('SignupForm.passwordLabel')}
                                        placeholder={_intelMessage('SignupForm.passwordPlaceholder')}
                                    />

                                    <div className="checkboxInput">
                                        {(section !== 'learner') && <Field id="term" type="checkbox" name="term" />}
                                        <label htmlFor="term">
                                            <FormattedMessage
                                                id="SignupForm.termsAndConditionsAcceptText"
                                                values={{ termsLink }}
                                            />
                                        </label>
                                    </div>

                                </div>
                                <div className="formsubmit">
                                    <button type="submit">Create account</button>
                                    <p>
                                        Already have an account? <a onClick={() => {
                                            setLoginPage(true);
                                            history.push('/login')
                                        }}>Log in</a>
                                    </p>
                                </div>
                                {signupError && <div>{signupErrorMessage}</div>}
                            </Form>
                        )}
                    </Formik>
                </section>
                <section className="createSecondSection imageSection">
                    <img src={require('../assest/createSignupImage.png')} alt="image1" />
                    <div className="imageContent">
                        <div>
                            <h3>Teach and sell with ease</h3>
                            <span>For freelancers, teachers, influencers. For everyone.</span>
                        </div>
                    </div>
                </section>
            </div>
        </CreatSingupStyle>
    );
};

export default CreateAccountForm;
