import { NamedLink, SecondaryButton } from '../../../components';
import { GrayButton } from '../../../components/Button/Button';
import css from './Hero.module.css';
import content from '../../../assets/BecomeProContent.png';
import avatar1 from '../../../assets/become-pro/image-members.png';
import avatar2 from '../../../assets/become-pro/image-members-1.png';
import avatar3 from '../../../assets/become-pro/image-members-2.png';
import img from '../../../assets/become-pro/income.svg';
import message from '../../../assets/become-pro/message.svg';

const HeroBecomeProPage = () => {
  return (
    <div className={css.hero}>
      <div>
        <h1 className={css.title}>Sell, share and get paid</h1>
        <p className={css.desc}>
          The marketplace where your personal brand, knowledge, and clients meet. Start and grow
          your business with ProLed!
        </p>
        <div className={css.buttons}>
          <NamedLink name="SignupPage">
            <GrayButton>Get Started</GrayButton>
          </NamedLink>
          <NamedLink
            name="SearchPage"
            to={{
              search: 'bounds=1.68008452%2C104.08284892%2C1.00507067%2C103.59952873',
            }}
          >
            <SecondaryButton className={css.explore}>Explore Marketplace</SecondaryButton>
          </NamedLink>
        </div>
      </div>
      <div className={css.contents}>
        <div className={css.message}>
          <img src={message} alt="message" />
          <span>
            Messages, <br /> payments, calendar <br /> - all in one place!
          </span>
        </div>
        <div className={css.content}>
          <p className={css.text}>Tell us more what you wanna sell?</p>
          <div className={css.categories}>
            <div className={css.item_black}>1:1 session</div>
            <div className={css.item}>Group classes</div>
            <div className={css.item}>Online course</div>
            <div className={css.item}>Shipping product</div>
          </div>
          <img src={content} alt="girl" />
        </div>
        <div className={css.income}>
          <div>
            <div className={css.members}>
              <img src={avatar1} alt="member 1" />
              <img src={avatar2} alt="member 2" />
              <img src={avatar3} alt="member 3" />
            </div>
            <span className={css.text}>50+ members</span>
          </div>
          <div className={css.box}>
            <div className={css.top}>
              <div className={css.label}>
                <span>Income</span>
                <img src={img} alt="" />
              </div>
              <span className={css.money}>SGD 1256.00</span>
            </div>
            <div className={css.bottom}>
              <div className={css.dots}>
                <div className={css.dot}></div>
                <div className={css.dot}></div>
                <div className={css.dot_gray}></div>
                <div className={css.dot_gray}></div>
              </div>
              <div className={css.range}>
                <span>Nov 1</span>
                <span>Nov 30</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeroBecomeProPage;
