import css from './CallToAction.module.css';
import { GrayButton } from '../../../components/Button/Button';
import { NamedLink } from '../../../components';

const CallToAction = () => {
  return (
    <div className={css.cta_container}>
      <div className={css.cta}>
        <h2 className={css.cta_title}>Start building your business 100% owned by you</h2>
        <NamedLink name="SignupPage">
          <GrayButton>Get Started</GrayButton>
        </NamedLink>
      </div>
    </div>
  );
};

export default CallToAction;
