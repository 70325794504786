import styled from 'styled-components';

const ProStepperCss = styled.div`
  input[type='checkbox'] {
    width: 20px;
    height: 20px;
    border-color: #dddddd;
    border-radius: 5px;
  }
  .steps_main_container {
    .firstDiv,
    .secondDiv,
    .proSecond_main {
      display: flex;
      flex-direction: column;
      gap: 10px;
      @media (max-width: 767px) {
        gap: 32px;
      }
    }
    label {
      font-size: 16px;
      padding-top: 0;
      color: #1c1c1c;
      font-size: 16px;
      font-weight: 600;
      line-height: 24px;
      letter-spacing: 0em;
    }

    .first_container {
      display: flex;
      justify-content: space-between;
      margin-bottom: 40px;
      @media (max-width: 767px) {
        flex-direction: column-reverse;
        margin-bottom: 30px;
      }
      .title_section_css {
        width: 100%;
        max-width: 523px;
        margin: 0 auto;
        /* padding: 0 20px; */
        h1 {
          font-size: 30px;
          font-weight: 400;
          line-height: 38px;
          letter-spacing: -0.02em;
          color: #1c1c1c;
          margin-bottom: 10px;
          @media (max-width: 767px) {
            font-size: 24px;
          }
        }
        p {
          font-size: 16px;
          font-weight: 400;
          line-height: 24px;
          letter-spacing: 0em;
          color: #959595;
          @media (max-width: 767px) {
            font-size: 14px;
          }
        }
      }

      .stepper {
        width: 100%;
        max-width: 591px;
      }
    }
    .secondContainer {
      display: flex;
      justify-content: space-between;
      margin: 0 -15px;

      .mainProFirst_container {
        form {
          position: relative;
          height: 100%;
          .actionButtons {
            margin-top: 18px;
            display: flex;
            gap: 20px;
            @media (max-width: 767px) {
              margin-top: 64px;
              flex-wrap: wrap;
              gap: 12px;
            }
            &.apply_position {
              position: absolute;
              left: 0;
              right: 0;
              bottom: 0;
              @media (max-width: 767px) {
                position: unset;
              }
            }
            .backbutton {
              background: #f3f3f3;
              color: #1c1c1c;
            }
          }
          .fieldsSections {
            .firstDiv {
              margin-bottom: 8px;
            }
          }
        }

        .proSecond_main,
        .LearnerFirst_main {
          gap: 14px;
          margin-bottom: 70px;
          display: flex;
          flex-direction: column;
          .uploadSection {
            display: flex;
            align-items: center;
            gap: 30px;

            button {
              width: 100%;
              max-width: 110px;
              height: 32px;
              font-weight: 600;
              padding: 4px 12px 4px 12px;
              border-radius: 6px;
              font-size: 12px;
              line-height: 20px;
              background: #f3f3f3;
              color: #1c1c1c;
              @media (max-width: 767px) {
                margin: 0;
              }
            }
          }
          .imagePreview {
            width: 64px;
            height: 64px;
            border-radius: 136px;
            gap: 10px;

            img {
              width: 100%;
              height: 100%;
              border-radius: 136px;
            }
          }
        }

        .proThird_main,
        .LearnerThird_main {
          margin-bottom: 70px;
          display: flex;
          flex-direction: column;
          gap: 25px;

          .listingTypes_options {
            p {
              font-size: 16px;
              line-height: 24px;
              color: #959595;
              margin-bottom: 16px;
            }
            .toggleContainer {
              display: flex;
              flex-wrap: wrap;
              gap: 10px;

              .togglebutton {
                max-width: max-content;
                width: 100%;
                border: 1px solid #e9e9e9;
                background: #fff;
                color: #1c1c1c;
                border-radius: 40px;
                font-size: 16px;
                font-weight: 400;
                line-height: 24px;
                letter-spacing: 0em;
                padding: 5px 15px;

                &.active {
                  background: var(--blue-primary);
                  color: #fff;
                }
              }
            }
          }
        }

        .proFourth_main {
          display: flex;
          flex-direction: column;
          gap: 10px;
          height: 530px;
          overflow-y: auto;

          .accordion-item {
            padding: 16px;
            border: 1px solid #dddd;
            border-radius: 12px;
            @media (max-width: 767px) {
              padding: 16px 24px;
            }
            .accordion-title {
              display: flex;
              justify-content: space-between;
              /* margin-bottom: 20px; */
              .text_title {
                font-size: 20px;
                font-weight: 400;
                line-height: 30px;
                letter-spacing: 0em;
                color: #1c1c1c;
              }

              .plus_minusIcon {
                svg {
                  color: #1c1c1c;
                  background-color: #dddd;
                  border-radius: 30px;
                }
              }
            }

            .accordion-content {
              display: flex;
              flex-direction: column;
              gap: 14px;
              margin-top: 30px;
              @media (max-width: 1199px) {
                margin-bottom: 30px;
              }
              @media (max-width: 767px) {
                margin-bottom: 0px;
              }
              .fieldWithLabel {
                p {
                  font-size: 14px;
                  color: #1c1c1c;
                }
                &.photos_data {
                  p {
                    line-height: 20px;
                    margin-bottom: 10px;
                  }
                  .file_selection {
                    button {
                      width: 100%;
                      max-width: 110px;
                      /* height: 32px; */
                      padding: 4px 12px 4px 12px;
                      border-radius: 8px;
                      gap: 4px;
                      color: #1c1c1c;
                      background-color: #f3f3f3;
                      font-size: 14px;
                      font-weight: 400;
                      line-height: 20px;
                      letter-spacing: -0.01em;
                      text-align: center;
                    }
                  }
                }
                .price_container {
                  display: flex;
                  align-items: center;
                  gap: 18px;
                  margin-bottom: 12px;

                  input {
                    max-width: 260px;
                    @media (max-width: 767px) {
                      max-width: 120px;
                    }
                  }
                }

                &.checkbox_forth {
                  label {
                    display: flex;
                    gap: 10px;
                    align-items: center;
                    p {
                      color: #1c1c1c;
                      font-size: 16px;
                    }
                  }
                }

                .para {
                  font-size: 14px;
                  font-weight: 400;
                  letter-spacing: -0.01em;
                  text-align: left;
                  @media (max-width: 767px) {
                    margin-bottom: 10px;
                  }
                  p:first-child {
                    line-height: 16px;
                  }
                }

                &.location_section {
                  p {
                    color: #656565;
                    line-height: 20px;
                    cursor: pointer;
                    border-bottom: 1px solid #656565;
                    width: 100%;
                    max-width: 145px;
                    margin-top: 10px;
                  }
                  .autocompleteField {
                    margin-bottom: 10px;
                    display: flex;
                    gap: 10px;

                    > div {
                      width: 100%;
                    }

                    input {
                      width: 100%;
                    }

                    button {
                      float: right;
                      width: 100%;
                      max-width: 40px;
                      font-size: 20px;
                      border-radius: 30px;
                      padding: 4px;
                    }
                    .deleteIcon {
                      width: 100%;
                      max-width: 20px;
                      line-height: 30px;
                      border: unset;
                      color: #f00;
                      font-weight: 600;
                    }
                  }
                }
              }
              .calender_main {
                .MyCalendarPage_root {
                  padding: 0 10px;

                  .MyCalendarPage_addExceptionButton {
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 20px;
                    letter-spacing: -0.01em;
                    text-align: left;
                    background: none;
                    color: #1c1c1c;
                    border-bottom: 1px solid #1c1c1c;
                    width: 100%;
                    max-width: 120px;
                    padding: 0;
                    border-radius: unset;

                    &:hover {
                      text-decoration: unset;
                    }
                  }

                  header {
                    h2 {
                      color: #1c1c1c;
                      font-weight: 600;
                      margin-top: 0;
                    }
                  }
                }
                .MyCalendarPage_week {
                  display: flex;
                  flex-direction: column;
                  gap: 20px;
                  border: unset;

                  .MyCalendarPage_weekDay {
                    width: 100%;
                    max-width: 440px;
                    background: #f2f2f2;
                    padding: 15px;
                    border-radius: 8px;

                    .MyCalendarPage_dayOfWeek {
                      span {
                        color: #1c1c1c;
                        font-size: 16px;
                        font-weight: 600;
                        line-height: 24px;
                        letter-spacing: 0em;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
      .imageSection {
        height: 600px !important;
        img {
          @media (max-width: 1199px) {
            object-fit: cover;
          }
        }
      }
    }
  }
`;

export default ProStepperCss;
