import { useEffect, useState } from 'react';
import { Menu, MenuContent, MenuItem, MenuLabel } from '../../../components';
import { GrayButton } from '../../../components/Button/Button';
import { filters } from '../../../marketplace-custom-config';
import { format } from '../Filter.helpers';
import ArrowIcon from './ArrowIcon';
import css from './Filter.module.css';
import classNames from 'classnames';

const location = [
  { label: "Pro's Location", key: '' },
  { label: 'Travel to me', key: 'events' },
  { label: 'Online', key: '' },
];

const distanceLocation = [
  { label: '1 km', key: 1 },
  { label: '2 km', key: 2 },
  { label: '3 km', key: 3 },
  { label: '5 km', key: 5 },
  { label: '10 km', key: 10 },
  { label: '15 km', key: 15 },
];

const Icon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.70898 8.45307C2.70898 4.37055 5.96316 1.04199 10.0007 1.04199C14.0381 1.04199 17.2923 4.37055 17.2923 8.45307C17.2923 10.424 16.7306 12.5402 15.7377 14.3685C14.746 16.1946 13.297 17.7813 11.4844 18.6286C10.5428 19.0687 9.45851 19.0687 8.51689 18.6286C6.70429 17.7813 5.25533 16.1946 4.26361 14.3685C3.27067 12.5402 2.70898 10.424 2.70898 8.45307ZM10.0007 2.29199C6.67435 2.29199 3.95898 5.03989 3.95898 8.45307C3.95898 10.2006 4.46118 12.1132 5.36207 13.772C6.26418 15.433 7.539 16.7917 9.04619 17.4962C9.65236 17.7795 10.3489 17.7795 10.9551 17.4962C12.4623 16.7917 13.7371 15.433 14.6392 13.772C15.5401 12.1132 16.0423 10.2006 16.0423 8.45307C16.0423 5.03989 13.327 2.29199 10.0007 2.29199ZM10.0007 6.45866C8.96512 6.45866 8.12565 7.29813 8.12565 8.33366C8.12565 9.36919 8.96512 10.2087 10.0007 10.2087C11.0362 10.2087 11.8757 9.36919 11.8757 8.33366C11.8757 7.29813 11.0362 6.45866 10.0007 6.45866ZM6.87565 8.33366C6.87565 6.60777 8.27476 5.20866 10.0007 5.20866C11.7265 5.20866 13.1257 6.60777 13.1257 8.33366C13.1257 10.0595 11.7265 11.4587 10.0007 11.4587C8.27476 11.4587 6.87565 10.0595 6.87565 8.33366Z"
      fill="#313131"
    />
  </svg>
);

const LocationFilter = ({ urlQueryParams, onSubmit }) => {
  const [open, setOpen] = useState(false);
  const [travel, setTravel] = useState(false);
  const [virtual, setVirtual] = useState(false);
  const [location, setLocation] = useState(false);
  const [distance, setDistance] = useState();

  const handleSubmit = () => {
    let result = {};
    if (travel) {
      const filter = filters.find(({ id }) => id === 'travelWilling');

      const value = ['yes'];
      const formatted = format(value, filter.queryParamNames);
      result = { ...result, ...formatted };
    } else if (!travel) {
      const filter = filters.find(({ id }) => id === 'travelWilling');

      const value = undefined;
      const formatted = format(value, filter.queryParamNames);
      result = { ...result, ...formatted };
    }

    if (virtual) {
      const filter = filters.find(({ id }) => id === 'virtualTraining');

      const value = ['yes'];
      const formatted = format(value, filter.queryParamNames);
      result = { ...result, ...formatted };
    } else if (!virtual) {
      const filter = filters.find(({ id }) => id === 'virtualTraining');

      const value = undefined;
      const formatted = format(value, filter.queryParamNames);
      result = { ...result, ...formatted };
    }

    if (location) {
      const value = [true];
      const formatted = format(value, 'pro_location');
      result = { ...result, ...formatted };
    } else if (!location) {
      const value = undefined;
      const formatted = format(value, 'pro_location');
      result = { ...result, ...formatted };
    }

    if (distance) {
      const value = [distance];
      const formatted = format(value, 'distance');
      result = { ...result, ...formatted };
    } else if (!distance) {
      const value = undefined;
      const formatted = format(value, 'distance');
      result = { ...result, ...formatted };
    }

    onSubmit(result);
    setOpen(false);
  };

  const handleClearAll = () => {
    setTravel(false);
    setVirtual(false);
    setLocation(false);
  };

  const handleDistance = value => {
    if (value === distance) {
      setDistance();
    } else {
      setDistance(value);
    }
  };

  useEffect(() => {
    if (urlQueryParams.pub_travelWilling) {
      setTravel(true);
    }

    if (urlQueryParams.pub_virtualTraining) {
      setVirtual(true);
    }

    if (urlQueryParams.pro_location) {
      setLocation(true);
    }

    if (urlQueryParams.distance) {
      setDistance(urlQueryParams.distance);
    }
  }, [urlQueryParams]);

  return (
    <Menu
      className={css.filterContainer}
      useArrow={false}
      onToggleActive={values => setOpen(values)}
      isOpen={open}
    >
      <MenuLabel className={css.filterLabel}>
        <Icon />
        <span>Location</span>
        <ArrowIcon />
      </MenuLabel>
      <MenuContent key="activities" className={css.filterContent}>
        <MenuItem key="location" className={css.filterTitle}>
          Location
        </MenuItem>
        <MenuItem key="items" className={css.filterItemContainer}>
          <span
            className={classNames(css.filterItem, location && css.filterItemActive)}
            onClick={() => setLocation(!location)}
          >
            Pro's Location
          </span>
          <span
            className={classNames(css.filterItem, travel && css.filterItemActive)}
            onClick={() => setTravel(!travel)}
          >
            Travel to me
          </span>
          <span
            className={classNames(css.filterItem, virtual && css.filterItemActive)}
            onClick={() => setVirtual(!virtual)}
          >
            Online
          </span>
        </MenuItem>
        <MenuItem key="distance" className={css.filterTitle}>
          Distance
        </MenuItem>
        <MenuItem key="items" className={css.filterItemContainer}>
          {distanceLocation.map(option => (
            <span
              key={option.key}
              className={classNames(
                css.filterItem,
                distance === option.key && css.filterItemActive
              )}
              onClick={() => handleDistance(option.key)}
            >
              {option.label}
            </span>
          ))}
        </MenuItem>
        <MenuItem key="actions" className={css.filterAction}>
          <button onClick={handleClearAll} className={css.filterBtnApply}>
            Clear All
          </button>
          <GrayButton onClick={handleSubmit} className={css.filterBtnGray}>
            Apply
          </GrayButton>
        </MenuItem>
      </MenuContent>
    </Menu>
  );
};

export default LocationFilter;
