import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './IconUser.module.css';

const IconUser = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);
  return (
    <svg
      className={classes}
      // xmlns="http://www.w3.org/2000/svg"
      // xmlns:xlink="http://www.w3.org/1999/xlink"
      // width="1080"
      zoomAndPan="magnify"
      viewBox="0 0 810 809.999993"
      // height="1080"
      preserveAspectRatio="xMidYMid meet"
      version="1.0"
    >
      {/* <defs>
        <clipPath id="d8aefc3e22">
          <path
            d="M 260.574219 422 L 438 422 L 438 554 L 260.574219 554 Z M 260.574219 422 "
            clip-rule="nonzero"
          />
        </clipPath>
        <clipPath id="4fcf5b97e8">
          <path
            d="M 421 497 L 484 497 L 484 554.949219 L 421 554.949219 Z M 421 497 "
            clip-rule="nonzero"
          />
        </clipPath>
      </defs> */}
      {/* <rect
        x="-81"
        width="972"
        fill="#ffffff"
        y="-80.999999"
        height="971.999992"
        fill-opacity="1"
      />
      <rect
        x="-81"
        width="972"
        fill="#ffffff"
        y="-80.999999"
        height="971.999992"
        fill-opacity="1"
      /> */}
      <path
        fill="#000000"
        d="M 366.578125 401.351562 C 354.585938 401.351562 342.59375 396.554688 333.003906 387.441406 C 315.253906 370.171875 298.46875 339.953125 301.824219 310.695312 C 302.785156 303.019531 304.703125 292.949219 312.378906 282.394531 C 325.328125 265.128906 344.992188 255.054688 366.097656 255.054688 C 387.683594 255.054688 407.347656 265.128906 420.777344 282.394531 C 428.453125 292.949219 430.371094 303.019531 431.332031 310.695312 C 434.207031 340.433594 417.902344 370.652344 400.152344 387.441406 C 390.558594 396.554688 378.570312 401.351562 366.578125 401.351562 Z M 366.578125 401.351562 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
      <g clip-path="url(#d8aefc3e22)">
        <path
          fill="#000000"
          d="M 407.828125 544.769531 L 404.46875 503.515625 C 403.992188 497.761719 405.429688 492.484375 407.828125 487.210938 L 437.566406 433.96875 C 425.574219 426.773438 412.144531 422.933594 398.234375 422.933594 L 335.878906 422.933594 C 321.011719 422.933594 306.140625 427.253906 293.671875 435.40625 C 273.046875 448.835938 260.574219 473.300781 260.574219 500.160156 L 260.574219 509.273438 C 260.574219 533.734375 280.71875 553.882812 305.183594 553.882812 L 411.183594 553.882812 C 409.265625 551.003906 408.308594 548.125 407.828125 544.769531 Z M 407.828125 544.769531 "
          fill-opacity="1"
          fill-rule="nonzero"
        />
      </g>
      <g clip-path="url(#4fcf5b97e8)">
        <path
          fill="#000000"
          d="M 421.738281 497.28125 C 421.257812 498.722656 420.777344 500.160156 421.257812 502.078125 L 424.617188 543.328125 C 425.09375 547.167969 427.015625 551.003906 430.851562 552.921875 C 434.207031 554.839844 438.527344 554.839844 442.363281 552.921875 L 479.296875 533.734375 C 481.214844 532.777344 482.652344 531.335938 483.613281 529.417969 Z M 421.738281 497.28125 "
          fill-opacity="1"
          fill-rule="nonzero"
        />
      </g>
      <path
        fill="#000000"
        d="M 546.925781 393.675781 C 544.527344 384.5625 538.292969 377.367188 530.140625 373.050781 C 521.984375 368.734375 512.390625 367.296875 503.757812 370.171875 C 494.644531 372.570312 487.449219 378.808594 483.132812 386.960938 L 477.855469 396.074219 L 430.851562 480.972656 L 492.726562 513.589844 L 538.773438 430.128906 L 544.050781 421.015625 C 548.367188 411.902344 549.324219 402.789062 546.925781 393.675781 Z M 522.945312 408.546875 L 517.667969 417.660156 L 498.480469 407.105469 L 503.757812 397.992188 C 506.636719 392.71875 513.351562 390.796875 518.628906 393.675781 C 523.902344 396.554688 525.820312 403.269531 522.945312 408.546875 Z M 522.945312 408.546875 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
    </svg>
  );
};

IconUser.defaultProps = {
  rootClassName: null,
  className: null,
};

const { string } = PropTypes;

IconUser.propTypes = {
  rootClassName: string,
  className: string,
};

export default IconUser;
