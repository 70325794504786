import React, { Component, useState } from 'react';
import { array, bool, func, object, string } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm, FormSpy } from 'react-final-form';
import { FormattedMessage, intlShape, injectIntl, FormattedDate } from '../../util/reactIntl';
import classNames from 'classnames';
import { timestampToDate } from '../../util/dates';
import { propTypes } from '../../util/types';
import config from '../../config';
import {
  IconSpinner,
  Form,
  PrimaryButton,
  FieldSelect,
  FieldTextInput,
  Button,
} from '../../components';
import EstimatedBreakdownMaybe from './EstimatedBreakdownMaybe';
import FieldDateAndTimeInput from './FieldDateAndTimeInput';
import infoIcon from './info.svg';
import Decimal from 'decimal.js';
import { types as sdkTypes } from '../../util/sdkLoader';

import css from './BookingTimeForm.module.css';
import { TRANSITION_REQUEST_PAYMENT, TX_TRANSITION_ACTOR_CUSTOMER } from '../../util/transaction';
import LineItemCustomerCommissionMaybe from '../../components/BookingBreakdown/LineItemCustomerCommissionMaybe';
import { convertMoneyToNumber, convertUnitToSubUnit, unitDivisor } from '../../util/currency';
import SectionMapMaybe from '../../containers/ListingPage/SectionMapMaybe';
const { Money, UUID } = sdkTypes;

const BookingTimeFormComponent = props => {
  const {
    rootClassName,
    className,
    price: unitPrice,
    isDateInputShown,
    handleCloseDateInput,
    handleOpenDateInput,
    geolocation,
    address,
    onSubmit,
    ...rest
  } = props;

  const [showBookingError, setShowBookingError] = useState(false);
  const [selectedLocation, setSelectedLocation] = useState('pro');
  const [selectedOnlinePlatform, setSelectedOnlinePlatform] = useState('');
  const [totalPrice, setTotalPrice] = useState(null);
  const [hours, setHours] = useState(null);

  const handleFormSubmit = e => {
    props.onSubmit(e);
  };

  const onBookingSubmit = () => {
    if (props.currentUser?.id) {
      setShowBookingError(true);
    }
  };

  const publicData = props.listing?.attributes?.publicData;

  // When the values of the form are updated we need to fetch
  // lineItems from FTW backend for the EstimatedTransactionMaybe
  // In case you add more fields to the form, make sure you add
  // the values here to the bookingData object.
  const handleOnChange = formValues => {
    const { bookingStartTime, bookingEndTime } = formValues.values;
    const startDate = bookingStartTime ? timestampToDate(bookingStartTime) : null;
    const endDate = bookingEndTime ? timestampToDate(bookingEndTime) : null;

    const listingId = props.listingId;
    const isOwnListing = props.isOwnListing;

    if (bookingStartTime && bookingEndTime && !props.fetchLineItemsInProgress) {
      props.onFetchTransactionLineItems({
        bookingData: { startDate, endDate },
        listingId,
        isOwnListing,
      });
    }
  };

  const [isShowContactError, seIsShowContactError] = useState(false);

  const classes = classNames(rootClassName || css.root, className);

  if (!unitPrice) {
    return (
      <div className={classes}>
        <p className={css.error}>
          <FormattedMessage id="BookingTimeForm.listingPriceMissing" />
        </p>
      </div>
    );
  }
  if (unitPrice.currency !== config.currency) {
    return (
      <div className={classes}>
        <p className={css.error}>
          <FormattedMessage id="BookingTimeForm.listingCurrencyInvalid" />
        </p>
      </div>
    );
  }

  return (
    <FinalForm
      {...rest}
      unitPrice={unitPrice}
      onSubmit={handleFormSubmit}
      render={fieldRenderProps => {
        const {
          endDatePlaceholder,
          startDatePlaceholder,
          form,
          pristine,
          handleSubmit,
          intl,
          isOwnListing,
          listingId,
          submitButtonWrapperClassName,
          unitType,
          values,
          monthlyTimeSlots,
          onFetchTimeSlots,
          timeZone,
          lineItems,
          fetchLineItemsInProgress,
          fetchLineItemsError,
          isEmailVerified,
          currentUser,
          listing,
          showContactUser,
          onContactUser,
        } = fieldRenderProps;

        const startTime = values && values.bookingStartTime ? values.bookingStartTime : null;
        const endTime = values && values.bookingEndTime ? values.bookingEndTime : null;
        const location = values && values.location ? values.location : null;
        const location_address = values && values.location_address ? values.location_address : null;
        const isSubmitDisabled = !startTime || !endTime;

        const bookingStartLabel = intl.formatMessage({
          id: 'BookingTimeForm.bookingStartTitle',
        });
        const bookingEndLabel = intl.formatMessage({
          id: 'BookingTimeForm.bookingEndTitle',
        });
        const dateFormatOptions = {
          month: 'short',
          day: 'numeric',
        };

        const timeZoneMaybe = timeZone ? { timeZone } : null;

        const timeFormatOptions = {
          weekday: 'short',
          hour: 'numeric',
          minute: 'numeric',
        };

        const startDate = startTime ? timestampToDate(startTime) : null;
        const endDate = endTime ? timestampToDate(endTime) : null;

        // This is the place to collect breakdown estimation data. See the
        // EstimatedBreakdownMaybe component to change the calculations
        // for customized payment processes.
        const bookingData =
          startDate && endDate
            ? {
                unitType,
                startDate,
                endDate,
                timeZone,
                location,
                location_address,
              }
            : null;

        const showEstimatedBreakdown =
          bookingData && lineItems && !fetchLineItemsInProgress && !fetchLineItemsError;

        const bookingInfoMaybe = (
          <div className={css.priceBreakdownContainer}>
            <div className={css.listingInfo}>
              <img
                className={css.imageSmall}
                src={listing?.images[0]?.attributes?.variants['square-small']?.url}
              />{' '}
              <div>
                <p className={css.title}>{listing.attributes.title}</p>
                {selectedLocation === 'Requested' && (
                  <p className={css.location}>Requested location: {values.location_address}</p>
                )}
                {selectedLocation === 'Online' && (
                  <p className={css.location}>Online location: {values.location_address}</p>
                )}
              </div>
            </div>
            {/* <EstimatedBreakdownMaybe
                bookingData={bookingData}
                lineItems={lineItems}
                location={this.state.selectedLocation}
                // listing={listing}
              /> */}

            <div className={css.bookingPeriodSection}>
              <div className={css.bookingPeriod}>
                <div className={css.dayInfo}>Date</div>
                <div className={css.day}>
                  <FormattedDate value={startDate} {...dateFormatOptions} {...timeZoneMaybe} />
                  {startDate && endDate && startDate?.getDay() !== endDate?.getDay() && (
                    <>
                      - <FormattedDate value={endDate} {...dateFormatOptions} {...timeZoneMaybe} />
                    </>
                  )}
                  {', '}
                  <FormattedDate
                    value={startDate}
                    {...timeFormatOptions}
                    {...timeZoneMaybe}
                  /> - <FormattedDate value={endDate} {...timeFormatOptions} {...timeZoneMaybe} />
                </div>
              </div>
              <div className={css.locationRow}>
                <div className={css.locationTitle}>Location</div>
                <div>
                  {selectedLocation === 'pro'
                    ? "Pro's location"
                    : values.location === 'request_location'
                    ? 'Requested'
                    : 'Online'}
                </div>
              </div>
            </div>
          </div>
        );

        const loadingSpinnerMaybe = fetchLineItemsInProgress ? (
          <IconSpinner className={css.spinner} />
        ) : null;

        const bookingInfoErrorMaybe = fetchLineItemsError ? (
          <span className={css.sideBarError}>
            <FormattedMessage id="BookingDatesForm.fetchLineItemsError" />
          </span>
        ) : null;

        const submitButtonClasses = classNames(
          submitButtonWrapperClassName || css.submitButtonWrapper
        );

        const startDateInputProps = {
          label: bookingStartLabel,
          placeholderText: startDatePlaceholder,
        };
        const endDateInputProps = {
          label: bookingEndLabel,
          placeholderText: endDatePlaceholder,
        };

        const dateInputProps = {
          startDateInputProps,
          endDateInputProps,
        };

        const estimatedTotalPrice = lineItems => {
          const numericTotalPrice = lineItems.reduce((sum, lineItem) => {
            const numericPrice = convertMoneyToNumber(lineItem.lineTotal);
            return new Decimal(numericPrice).add(sum);
          }, 0);

          // All the lineItems should have same currency so we can use the first one to check that
          // In case there are no lineItems we use currency from config.js as default
          const currency =
            lineItems[0] && lineItems[0].unitPrice
              ? lineItems[0].unitPrice.currency
              : config.currency;

          return new Money(
            convertUnitToSubUnit(numericTotalPrice.toNumber(), unitDivisor(currency)),
            currency
          );
        };

        const estimatedTransaction = (bookingStart, bookingEnd, lineItems, userRole) => {
          const now = new Date();

          const isCustomer = userRole === 'customer';

          const customerLineItems = lineItems.filter(item => item.includeFor.includes('customer'));
          const providerLineItems = lineItems.filter(item => item.includeFor.includes('provider'));

          const payinTotal = estimatedTotalPrice(customerLineItems);
          const payoutTotal = estimatedTotalPrice(providerLineItems);

          return {
            id: new UUID('estimated-transaction'),
            type: 'transaction',
            attributes: {
              createdAt: now,
              lastTransitionedAt: now,
              lastTransition: TRANSITION_REQUEST_PAYMENT,
              payinTotal,
              payoutTotal,
              lineItems: isCustomer ? customerLineItems : providerLineItems,
              transitions: [
                {
                  createdAt: now,
                  by: TX_TRANSITION_ACTOR_CUSTOMER,
                  transition: TRANSITION_REQUEST_PAYMENT,
                },
              ],
            },
            booking: {
              id: new UUID('estimated-booking'),
              type: 'booking',
              attributes: {
                start: bookingStart,
                end: bookingEnd,
              },
            },
          };
        };

        return (
          <div className={css.wrapper}>
            <Form onSubmit={handleSubmit} className={classes}>
              <FormSpy
                subscription={{ values: true }}
                onChange={values => {
                  handleOnChange(values);
                }}
              />
              <div className={css.bookingHandleSection}>
                {monthlyTimeSlots && timeZone ? (
                  <FieldDateAndTimeInput
                    {...dateInputProps}
                    className={css.bookingDates}
                    listingId={listingId}
                    bookingStartLabel={bookingStartLabel}
                    onFetchTimeSlots={onFetchTimeSlots}
                    monthlyTimeSlots={monthlyTimeSlots}
                    values={values}
                    intl={intl}
                    form={form}
                    pristine={pristine}
                    timeZone={timeZone}
                    handleCloseDateInput={handleCloseDateInput}
                    isDateInputShown={isDateInputShown}
                    handleOpenDateInput={handleOpenDateInput}
                  />
                ) : null}
                <div className={css.locationSection}>
                  <div className={css.sectionTitle}>Location</div>
                  <div className={css.locationContainer}>
                    {address && (
                      <div
                        className={classNames(
                          css.locationButton,
                          selectedLocation === 'pro' && css.active
                        )}
                        onClick={() => setSelectedLocation('pro')}
                      >
                        Pro's location
                      </div>
                    )}
                    {publicData?.travelWilling === 'yes' && (
                      <div
                        onClick={() => {
                          values.location_address = '';
                          values.location = 'request_location';
                          setSelectedLocation('Requested');
                        }}
                        className={classNames(
                          css.locationButton,
                          selectedLocation === 'Requested' && css.active
                        )}
                      >
                        Request
                      </div>
                    )}
                    {(publicData?.virtualTraining === 'yes' ||
                      publicData?.exclusiveOnlineTraining) && (
                      <div
                        onClick={() => {
                          values.location_address = '';
                          setSelectedLocation('Online');
                        }}
                        className={classNames(
                          css.locationButton,
                          selectedLocation === 'Online' && css.active
                        )}
                      >
                        Online
                      </div>
                    )}
                  </div>
                </div>
                {selectedLocation === 'Requested' && (
                  <>
                    <div className={css.infoLabelContainer}>
                      <img src={infoIcon} alt="info" />
                      <span className={css.infoLabel}>
                        Add your location and wait for approving from Pro
                      </span>
                    </div>
                    <FieldTextInput
                      className={css.location_address}
                      name="location_address"
                      id={'location_address'}
                      placeholder={'Type here'}
                    />
                  </>
                )}
                {selectedLocation === 'Online' && (
                  <>
                    <div className={css.infoLabelContainer}>
                      <img src={infoIcon} alt="info" />
                      <span className={css.infoLabel}>You can choose your preferred platform</span>
                    </div>
                    <div className={css.onlineLocationContainer}>
                      <div
                        onClick={() => {
                          values.location = 'Online';
                          values.location_address = 'Google meet';
                          setSelectedOnlinePlatform('google meet');
                        }}
                        className={classNames(
                          css.locationButton,
                          selectedOnlinePlatform === 'google meet' && css.active
                        )}
                      >
                        Google meet
                      </div>
                      <div
                        onClick={() => {
                          values.location = 'Online';
                          values.location_address = 'Zoom';
                          setSelectedOnlinePlatform('zoom');
                        }}
                        className={classNames(
                          css.locationButton,
                          selectedOnlinePlatform === 'zoom' && css.active
                        )}
                      >
                        Zoom
                      </div>
                    </div>
                  </>
                )}
                {selectedLocation === 'pro' && (
                  <>
                    {/* <p className={css.address}>{address}</p> */}
                    <SectionMapMaybe
                      geolocation={geolocation}
                      address={address}
                      listingId={listingId}
                      className={css.map}
                    />
                  </>
                )}
              </div>

              <div className={css.bookingInfoSection}>
                <h2 className={css.priceBreakdownTitle}>
                  <FormattedMessage id="BookingTimeForm.priceBreakdownTitle" />
                </h2>
                <div className={css.bookingInfo}>
                  <div className={css.bookingDetails}>
                    {bookingInfoMaybe}
                    {loadingSpinnerMaybe}
                    {bookingInfoErrorMaybe}

                    <p className={css.errorPrint}>
                      {!isEmailVerified && showBookingError && (
                        <FormattedMessage id="BookingTimeForm.verifyEmailError" />
                      )}
                    </p>

                    <div className={submitButtonClasses}>
                      <button
                        className={css.requestSession}
                        type="submit"
                        onClick={onBookingSubmit}
                        disabled={isSubmitDisabled}
                      >
                        <FormattedMessage id="BookingTimeForm.requestSession" />
                      </button>
                      {isOwnListing && (
                        <p className={css.buttonLabel}>You can't book your own listing.</p>
                      )}
                      {showContactUser ? (
                        <>
                          <div>
                            {isShowContactError && (
                              <p className={css.contactError}>
                                <FormattedMessage id="ListingPage.contactUserError" />
                              </p>
                            )}
                            <button
                              className={css.button}
                              onClick={e => {
                                e.preventDefault();
                                onContactUser();
                              }}
                            >
                              <FormattedMessage id="ListingPage.contactUser" />
                            </button>
                          </div>
                          <p className={css.buttonLabel}>
                            Click Contact me below to speak with the Pro and discuss session details
                            before finalising your booking
                          </p>
                        </>
                      ) : null}
                    </div>
                  </div>

                  <div className={css.priceDetailsContainer}>
                    <h3 className={css.priceHeader}>Price details</h3>
                    {/* <LineItemCustomerCommissionMaybe
                      transaction={tx}
                      isCustomer={isCustomer}
                      intl={intl}
                    /> */}
                    <EstimatedBreakdownMaybe
                      bookingData={bookingData}
                      lineItems={lineItems}
                      location={selectedLocation}
                      setTotalPrice={setTotalPrice}
                      setHours={setHours}
                    />
                  </div>
                </div>
              </div>
            </Form>
            {!isOwnListing && (
              <div className={css.openBookingForm}>
                <div className={css.priceContainer}>
                  <div className={css.priceValue} title={'priceTitle'}>
                    {totalPrice}
                  </div>
                  {hours > 1 && (
                    <div className={css.perUnit}>{`Total for ${hours} ${
                      hours > 1 ? 'hours' : 'hour'
                    }`}</div>
                  )}
                </div>
                {
                  <Button rootClassName={css.bookButton} onClick={() => onSubmit(values)}>
                    <FormattedMessage id="BookingPanel.ctaButtonMessage" />
                  </Button>
                }
              </div>
            )}
          </div>
        );
      }}
    />
  );
};

BookingTimeFormComponent.defaultProps = {
  rootClassName: null,
  className: null,
  submitButtonWrapperClassName: null,
  price: null,
  isOwnListing: false,
  listingId: null,
  startDatePlaceholder: null,
  endDatePlaceholder: null,
  monthlyTimeSlots: null,
  lineItems: null,
  fetchLineItemsError: null,
};

BookingTimeFormComponent.propTypes = {
  rootClassName: string,
  className: string,
  submitButtonWrapperClassName: string,

  unitType: propTypes.bookingUnitType.isRequired,
  price: propTypes.money,
  isOwnListing: bool,
  listingId: propTypes.uuid,
  monthlyTimeSlots: object,
  onFetchTimeSlots: func.isRequired,
  isEmailVerified: bool,

  onFetchTransactionLineItems: func.isRequired,
  lineItems: array,
  fetchLineItemsInProgress: bool.isRequired,
  fetchLineItemsError: propTypes.error,

  // from injectIntl
  intl: intlShape.isRequired,

  // for tests
  startDatePlaceholder: string,
  endDatePlaceholder: string,
};

const BookingTimeForm = compose(injectIntl)(BookingTimeFormComponent);
BookingTimeForm.displayName = 'BookingTimeForm';

export default BookingTimeForm;
