import React, { useState } from 'react';
import { array, arrayOf, bool, func, object, shape, string, oneOf } from 'prop-types';
import { FormattedMessage, intlShape, injectIntl } from '../../util/reactIntl.js';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import config from '../../config.js';
import routeConfiguration from '../../routeConfiguration.js';
import { findOptionsForSelectFilter } from '../../util/search.js';
import {
  LISTING_STATE_PENDING_APPROVAL,
  LISTING_STATE_CLOSED,
  propTypes,
} from '../../util/types.js';
import { types as sdkTypes } from '../../util/sdkLoader.js';
import {
  LISTING_PAGE_DRAFT_VARIANT,
  LISTING_PAGE_PENDING_APPROVAL_VARIANT,
  LISTING_PAGE_PARAM_TYPE_DRAFT,
  LISTING_PAGE_PARAM_TYPE_EDIT,
  createSlug,
} from '../../util/urlHelpers.js';
import { formatMoney } from '../../util/currency.js';
import { createResourceLocatorString, findRouteByRouteName } from '../../util/routes.js';
import {
  ensureListing,
  ensureOwnListing,
  ensureUser,
  userDisplayNameAsString,
} from '../../util/data.js';
import { timestampToDate, calculateQuantityFromHours } from '../../util/dates.js';
import { richText } from '../../util/richText.js';
import { getMarketplaceEntities } from '../../ducks/marketplaceData.duck.js';
import { manageDisableScrolling, isScrollingDisabled } from '../../ducks/UI.duck.js';
import { initializeCardPaymentData } from '../../ducks/stripe.duck.js';
import {
  Page,
  Modal,
  NamedLink,
  NamedRedirect,
  LayoutSingleColumn,
  LayoutWrapperTopbar,
  LayoutWrapperMain,
  LayoutWrapperFooter,
  Footer,
  BookingPanel,
  UserCard,
  LayoutSideNavigation,
  LayoutWrapperAccountSideNav,
  Avatar,
  Button,
} from '../../components/index.js';
import { EnquiryForm } from '../../forms/index.js';
import { TopbarContainer, NotFoundPage } from '../index.js';

import {
  sendEnquiry,
  loadData,
  setInitialValues,
  fetchTimeSlots,
  fetchTransactionLineItems,
} from './ListingPage.duck.js';
import SectionGallery from './SectionGallery';

import css from './ListingPage.module.css';

const MIN_LENGTH_FOR_LONG_WORDS_IN_TITLE = 16;

const { UUID } = sdkTypes;

const priceData = (price, intl) => {
  if (price && price.currency === config.currency) {
    const formattedPrice = formatMoney(intl, price);
    return { formattedPrice, priceTitle: formattedPrice };
  } else if (price) {
    return {
      priceTitle: `Unsupported currency (${price.currency})`,
      formattedPrice: `(${price.currency})`,
    };
  }
  return {};
};

const ListingPageComponent = props => {
  const {
    unitType,
    isAuthenticated,
    currentUser,
    getListing,
    getOwnListing,
    intl,
    onManageDisableScrolling,
    onFetchTimeSlots,
    params: rawParams,
    location,
    scrollingDisabled,
    showListingError,
    sendEnquiryInProgress,
    sendEnquiryError,
    monthlyTimeSlots,
    filterConfig,
    onFetchTransactionLineItems,
    lineItems,
    fetchLineItemsInProgress,
    fetchLineItemsError,
    enquiryModalOpenForListingId,
  } = props;

  const [enquiryModalOpen, setEnquiryModalOpen] = useState(
    enquiryModalOpenForListingId === props.params.id
  );
  const [showMessageError, setShowMessageError] = useState(false);
  const [isDateInputShown, setIsDateInputShown] = useState(false);

  const handleSubmit = values => {
    const {
      history,
      getListing,
      params,
      callSetInitialValues,
      onInitializeCardPaymentData,
    } = props;
    const listingId = new UUID(params.id);
    const listing = getListing(listingId);

    const { bookingStartTime, bookingEndTime, ...restOfValues } = values;
    const bookingStart = timestampToDate(bookingStartTime);
    const bookingEnd = timestampToDate(bookingEndTime);

    const bookingData = {
      quantity: calculateQuantityFromHours(bookingStart, bookingEnd),
      ...restOfValues,
    };

    const initialValues = {
      listing,
      bookingData,
      bookingDates: {
        bookingStart,
        bookingEnd,
      },
      confirmPaymentError: null,
      isDateInputShown: false,
    };

    const routes = routeConfiguration();
    // Customize checkout page state with current listing and selected bookingDates
    const { setInitialValues } = findRouteByRouteName('CheckoutPage', routes);
    callSetInitialValues(setInitialValues, initialValues);
    // bablu
    // Clear previous Stripe errors from store if there is any
    onInitializeCardPaymentData();

    // Redirect to CheckoutPage
    history.push(
      createResourceLocatorString(
        'CheckoutPage',
        routes,
        { id: listing.id.uuid, slug: createSlug(listing.attributes.title) },
        {}
      )
    );
  };

  const handleCloseDateInput = () => {
    if (isDateInputShown) {
      setIsDateInputShown(false);
    }
  };

  const handleOpenDateInput = () => {
    if (!isDateInputShown) {
      setIsDateInputShown(true);
    }
  };

  const onContactUser = () => {
    const { currentUser, history, callSetInitialValues, params, location } = props;

    if (!currentUser) {
      const state = { from: `${location.pathname}${location.search}${location.hash}` };

      // We need to log in before showing the modal, but first we need to ensure
      // that modal does open when user is redirected back to this listingpage
      callSetInitialValues(setInitialValues, { enquiryModalOpenForListingId: params.id });

      // signup and return back to listingPage.
      history.push(createResourceLocatorString('SignupPage', routeConfiguration(), {}, {}), state);
    } else {
      setEnquiryModalOpen(true);
    }
  };

  const onSubmitEnquiry = values => {
    const { history, params, onSendEnquiry } = props;
    const routes = routeConfiguration();
    const listingId = new UUID(params.id);
    const { message } = values;

    onSendEnquiry(listingId, message.trim())
      .then(txId => {
        setEnquiryModalOpen(false);

        // Redirect to OrderDetailsPage
        history.push(
          createResourceLocatorString('OrderDetailsPage', routes, { id: txId.uuid }, {})
        );
      })
      .catch(() => {
        // Ignore, error handling in duck file
      });
  };

  const listingId = new UUID(rawParams.id);
  const isPendingApprovalVariant = rawParams.variant === LISTING_PAGE_PENDING_APPROVAL_VARIANT;
  const isDraftVariant = rawParams.variant === LISTING_PAGE_DRAFT_VARIANT;
  const currentListing =
    isPendingApprovalVariant || isDraftVariant
      ? ensureOwnListing(getOwnListing(listingId))
      : ensureListing(getListing(listingId));

  const listingSlug = rawParams.slug || createSlug(currentListing.attributes.title || '');
  const params = { slug: listingSlug, ...rawParams };

  const listingType = isDraftVariant ? LISTING_PAGE_PARAM_TYPE_DRAFT : LISTING_PAGE_PARAM_TYPE_EDIT;
  const listingTab = isDraftVariant ? 'photos' : 'description';

  const isApproved =
    currentListing.id && currentListing.attributes.state !== LISTING_STATE_PENDING_APPROVAL;

  const pendingIsApproved = isPendingApprovalVariant && isApproved;

  // If a /pending-approval URL is shared, the UI requires
  // authentication and attempts to fetch the listing from own
  // listings. This will fail with 403 Forbidden if the author is
  // another user. We use this information to try to fetch the
  // public listing.
  const pendingOtherUsersListing =
    (isPendingApprovalVariant || isDraftVariant) &&
    showListingError &&
    showListingError.status === 403;
  const shouldShowPublicListingPage = pendingIsApproved || pendingOtherUsersListing;

  if (shouldShowPublicListingPage) {
    return <NamedRedirect name="ListingPage" params={params} search={location.search} />;
  }

  const {
    description = '',
    geolocation = null,
    price = null,
    title = '',
    publicData,
  } = currentListing.attributes;

  const richTitle = (
    <span>
      {richText(title, {
        longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS_IN_TITLE,
        longWordClass: css.longWord,
      })}
    </span>
  );

  const bookingTitle = (
    <FormattedMessage id="ListingPage.bookingTitle" values={{ title: richTitle }} />
  );

  const topbar = <TopbarContainer currentPage={'ListingPage'} />;

  if (showListingError && showListingError.status === 404) {
    // 404 listing not found

    return <NotFoundPage />;
  } else if (showListingError) {
    // Other error in fetching listing

    const errorTitle = intl.formatMessage({
      id: 'ListingPage.errorLoadingListingTitle',
    });

    return (
      <Page title={errorTitle} scrollingDisabled={scrollingDisabled}>
        <LayoutSingleColumn className={css.pageRoot}>
          <LayoutWrapperTopbar>{topbar}</LayoutWrapperTopbar>
          <LayoutWrapperMain>
            <p className={css.errorText}>
              <FormattedMessage id="ListingPage.errorLoadingListingMessage" />
            </p>
          </LayoutWrapperMain>
          <LayoutWrapperFooter>
            <Footer />
          </LayoutWrapperFooter>
        </LayoutSingleColumn>
      </Page>
    );
  } else if (!currentListing.id) {
    // Still loading the listing

    const loadingTitle = intl.formatMessage({
      id: 'ListingPage.loadingListingTitle',
    });

    return (
      <Page title={loadingTitle} scrollingDisabled={scrollingDisabled}>
        <LayoutSingleColumn className={css.pageRoot}>
          <LayoutWrapperTopbar>{topbar}</LayoutWrapperTopbar>
          <LayoutWrapperMain>
            <p className={css.loadingText}>
              <FormattedMessage id="ListingPage.loadingListingMessage" />
            </p>
          </LayoutWrapperMain>
          <LayoutWrapperFooter>
            <Footer />
          </LayoutWrapperFooter>
        </LayoutSingleColumn>
      </Page>
    );
  }

  const authorAvailable = currentListing && currentListing.author;
  const userAndListingAuthorAvailable = !!(currentUser && authorAvailable);
  const isOwnListing =
    userAndListingAuthorAvailable && currentListing.author.id.uuid === currentUser.id.uuid;
  const showContactUser = authorAvailable && (!currentUser || (currentUser && !isOwnListing));

  const currentAuthor = authorAvailable ? currentListing.author : null;
  const ensuredAuthor = ensureUser(currentAuthor);

  // When user is banned or deleted the listing is also deleted.
  // Because listing can be never showed with banned or deleted user we don't have to provide
  // banned or deleted display names for the function
  const authorDisplayName = userDisplayNameAsString(ensuredAuthor, '');

  const { formattedPrice, priceTitle } = priceData(price, intl);

  const handleBookingSubmit = values => {
    const isCurrentlyClosed = currentListing.attributes.state === LISTING_STATE_CLOSED;
    const isEmailVerified = currentUser?.attributes?.emailVerified;

    const { history } = props;
    if (!currentUser?.id) {
      history.push(createResourceLocatorString('SignupPage', routeConfiguration(), {}, {}));
    }

    if (isOwnListing || isCurrentlyClosed || !isEmailVerified) {
      window.scrollTo(0, 0);
    } else {
      handleSubmit(values);
    }
  };

  const listingImages = (listing, variantName) =>
    (listing.images || [])
      .map(image => {
        const variants = image.attributes.variants;
        const variant = variants ? variants[variantName] : null;

        // deprecated
        // for backwards combatility only
        const sizes = image.attributes.sizes;
        const size = sizes ? sizes.find(i => i.name === variantName) : null;

        return variant || size;
      })
      .filter(variant => variant != null);

  const facebookImages = listingImages(currentListing, 'facebook');
  const twitterImages = listingImages(currentListing, 'twitter');
  const schemaImages = JSON.stringify(facebookImages.map(img => img.url));
  const siteTitle = config.siteTitle;
  const schemaTitle = intl.formatMessage(
    { id: 'ListingPage.schemaTitle' },
    { title, price: formattedPrice, siteTitle }
  );

  const hostLink = (
    <NamedLink
      className={css.authorNameLink}
      name="ListingPage"
      params={params}
      to={{ hash: '#host' }}
    >
      {authorDisplayName}
    </NamedLink>
  );

  const specializationsOptions = findOptionsForSelectFilter('specializations', filterConfig);
  const certificateOptions = findOptionsForSelectFilter('certificate', filterConfig);

  const about = publicData?.about;

  const address = publicData && publicData.location ? publicData.location.address : '';

  const location2 = publicData && publicData.location2;
  const location3 = publicData && publicData.location3;

  const isEmailVerified = currentUser?.attributes?.emailVerified;
  const hadleContactClick = () => {
    if (!!currentUser?.id && !isEmailVerified) {
      setShowMessageError(true);
    }
  };

  return (
    <Page
      title={schemaTitle}
      scrollingDisabled={scrollingDisabled}
      author={authorDisplayName}
      contentType="website"
      description={description}
      facebookImages={facebookImages}
      twitterImages={twitterImages}
      schema={{
        '@context': 'http://schema.org',
        '@type': 'ItemPage',
        description: description,
        name: schemaTitle,
        image: schemaImages,
      }}
    >
      <LayoutSideNavigation containerClassName={css.sidebarContainer}>
        <LayoutWrapperTopbar>{topbar}</LayoutWrapperTopbar>
        <LayoutWrapperAccountSideNav currentTab={'ListingPage'} />

        <LayoutWrapperMain className={css.wrapperMain}>
          <div className={css.mobileGallery}>
            <SectionGallery listing={currentListing} variantPrefix="listing-card" />
          </div>
          <div className={css.wrapper} onClick={handleCloseDateInput}>
            <div className={css.gallerySection}>
              <div className={css.infoBlock}>
                <h1 className={css.title}>{title}</h1>
                <p className={css.price}>
                  <span className={css.amount}>{formattedPrice}</span> / 1 hour session
                </p>
                <p className={css.aboutInfo}>{description || about}</p>
              </div>{' '}
              <div className={css.galleryBlock}>
                <div className={css.desktopGallery}>
                  <SectionGallery listing={currentListing} variantPrefix="listing-card" />
                </div>
                <div className={css.authorInfoBLock}>
                  <div className={css.authorInfo}>
                    <Avatar user={currentAuthor} />
                    <span className={css.authorName}>
                      {currentAuthor?.attributes?.profile?.publicData?.displayName ||
                        currentAuthor?.attributes?.profile?.displayName}
                    </span>
                  </div>
                  {showContactUser ? (
                    <div>
                      {showMessageError && (
                        <p className={css.contactError}>
                          <FormattedMessage id="ListingPage.contactUserError" />
                        </p>
                      )}

                      <button
                        className={css.messageButton}
                        onClick={
                          !!currentUser?.id && !isEmailVerified ? hadleContactClick : onContactUser
                        }
                      >
                        Message
                      </button>
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
            <div className={css.contentContainer}>
              <div className={css.mainContent}>
                <BookingPanel
                  className={css.bookingPanel}
                  listing={currentListing}
                  isOwnListing={isOwnListing}
                  unitType={unitType}
                  onSubmit={handleBookingSubmit}
                  title={bookingTitle}
                  authorDisplayName={authorDisplayName}
                  onManageDisableScrolling={onManageDisableScrolling}
                  monthlyTimeSlots={monthlyTimeSlots}
                  onFetchTimeSlots={onFetchTimeSlots}
                  onFetchTransactionLineItems={onFetchTransactionLineItems}
                  lineItems={lineItems}
                  fetchLineItemsInProgress={fetchLineItemsInProgress}
                  fetchLineItemsError={fetchLineItemsError}
                  showContactUser={showContactUser}
                  onContactUser={onContactUser}
                  currentUser={currentUser}
                  handleCloseDateInput={handleCloseDateInput}
                  isDateInputShown={isDateInputShown}
                  handleOpenDateInput={handleOpenDateInput}
                  geolocation={geolocation}
                  address={address}
                />
              </div>
            </div>
          </div>
          <Modal
            id="ListingPage.enquiry"
            contentClassName={css.enquiryModalContent}
            isOpen={isAuthenticated && enquiryModalOpen}
            onClose={() => setEnquiryModalOpen(false)}
            onManageDisableScrolling={onManageDisableScrolling}
          >
            <EnquiryForm
              className={css.enquiryForm}
              submitButtonWrapperClassName={css.enquirySubmitButtonWrapper}
              listingTitle={title}
              authorDisplayName={authorDisplayName}
              sendEnquiryError={sendEnquiryError}
              onSubmit={onSubmitEnquiry}
              inProgress={sendEnquiryInProgress}
            />
          </Modal>
        </LayoutWrapperMain>
        <LayoutWrapperFooter>
          <Footer />
        </LayoutWrapperFooter>
      </LayoutSideNavigation>
    </Page>
  );
};

ListingPageComponent.defaultProps = {
  unitType: config.bookingUnitType,
  currentUser: null,
  enquiryModalOpenForListingId: null,
  showListingError: null,
  reviews: [],
  fetchReviewsError: null,
  monthlyTimeSlots: null,
  sendEnquiryError: null,
  filterConfig: config.custom.filters,
  lineItems: null,
  fetchLineItemsError: null,
};

ListingPageComponent.propTypes = {
  // from withRouter
  history: shape({
    push: func.isRequired,
  }).isRequired,
  location: shape({
    search: string,
  }).isRequired,

  unitType: propTypes.bookingUnitType,
  // from injectIntl
  intl: intlShape.isRequired,

  params: shape({
    id: string.isRequired,
    slug: string,
    variant: oneOf([LISTING_PAGE_DRAFT_VARIANT, LISTING_PAGE_PENDING_APPROVAL_VARIANT]),
  }).isRequired,

  isAuthenticated: bool.isRequired,
  currentUser: propTypes.currentUser,
  getListing: func.isRequired,
  getOwnListing: func.isRequired,
  onManageDisableScrolling: func.isRequired,
  scrollingDisabled: bool.isRequired,
  enquiryModalOpenForListingId: string,
  showListingError: propTypes.error,
  callSetInitialValues: func.isRequired,
  reviews: arrayOf(propTypes.review),
  fetchReviewsError: propTypes.error,
  monthlyTimeSlots: object,
  // monthlyTimeSlots could be something like:
  // monthlyTimeSlots: {
  //   '2019-11': {
  //     timeSlots: [],
  //     fetchTimeSlotsInProgress: false,
  //     fetchTimeSlotsError: null,
  //   }
  // }
  sendEnquiryInProgress: bool.isRequired,
  sendEnquiryError: propTypes.error,
  onSendEnquiry: func.isRequired,
  onInitializeCardPaymentData: func.isRequired,
  filterConfig: array,
  onFetchTransactionLineItems: func.isRequired,
  lineItems: array,
  fetchLineItemsInProgress: bool.isRequired,
  fetchLineItemsError: propTypes.error,
};

const mapStateToProps = state => {
  const { isAuthenticated } = state.Auth;
  const {
    showListingError,
    reviews,
    fetchReviewsError,
    monthlyTimeSlots,
    sendEnquiryInProgress,
    sendEnquiryError,
    lineItems,
    fetchLineItemsInProgress,
    fetchLineItemsError,
    enquiryModalOpenForListingId,
  } = state.ListingPage;
  const { currentUser } = state.user;

  const getListing = id => {
    const ref = { id, type: 'listing' };
    const listings = getMarketplaceEntities(state, [ref]);
    return listings.length === 1 ? listings[0] : null;
  };

  const getOwnListing = id => {
    const ref = { id, type: 'ownListing' };
    const listings = getMarketplaceEntities(state, [ref]);
    return listings.length === 1 ? listings[0] : null;
  };

  return {
    isAuthenticated,
    currentUser,
    getListing,
    getOwnListing,
    scrollingDisabled: isScrollingDisabled(state),
    enquiryModalOpenForListingId,
    showListingError,
    reviews,
    fetchReviewsError,
    monthlyTimeSlots,
    lineItems,
    fetchLineItemsInProgress,
    fetchLineItemsError,
    sendEnquiryInProgress,
    sendEnquiryError,
  };
};

const mapDispatchToProps = dispatch => ({
  onManageDisableScrolling: (componentId, disableScrolling) =>
    dispatch(manageDisableScrolling(componentId, disableScrolling)),
  callSetInitialValues: (setInitialValues, values) => dispatch(setInitialValues(values)),
  onFetchTransactionLineItems: (bookingData, listingId, isOwnListing) =>
    dispatch(fetchTransactionLineItems(bookingData, listingId, isOwnListing)),
  onSendEnquiry: (listingId, message) => dispatch(sendEnquiry(listingId, message)),
  onInitializeCardPaymentData: () => dispatch(initializeCardPaymentData()),
  onFetchTimeSlots: (listingId, start, end, timeZone) =>
    dispatch(fetchTimeSlots(listingId, start, end, timeZone)),
});

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const ListingPage = compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl
)(ListingPageComponent);

ListingPage.setInitialValues = initialValues => setInitialValues(initialValues);
ListingPage.loadData = loadData;

export default ListingPage;
