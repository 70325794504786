import React from 'react';
import Accordion from '../../components/CommonComp/AccordianComp';
import { InputFieldComp, SelectFieldComp } from '../../components/CommonComp/FieldComponent';
import { MyCalendarPage } from '../../../containers';
import { ErrorMessage, Field, FieldArray } from 'formik';
import _ from 'lodash';
import Autocomplete from 'react-google-autocomplete';
import { compose, withProps } from 'recompose';
import { withScriptjs, withGoogleMap, GoogleMap, Marker } from 'react-google-maps';
import { LocationAutocompleteInputField, SearchMap } from '../../../components';
import LocationAutocompleteInputImpl from '../../../components/LocationAutocompleteInput/LocationAutocompleteInputImpl';
import { isUploadImageOverLimitError } from '../../../util/errors';
import { FormattedMessage } from 'react-intl';
import SectionMapMaybe from '../../../containers/ListingPage/SectionMapMaybe';

const MyMapComponent = compose(
  withProps({
    /**
     * Note: create and replace your own key in the Google console.
     * https://console.developers.google.com/apis/dashboard
     * The key "AIzaSyBkNaAGLEVq0YLQMi-PYEMabFeREadYe1Q" can be ONLY used in this sandbox (no forked).
     */
    googleMapURL:
      'https://maps.googleapis.com/maps/api/js?key=pk.eyJ1IjoicHJvbGVhZGRldiIsImEiOiJjbGl3aXBzN3AwM24wM3Rwb2xtbjVmbG96In0.-vxQlWgstAtLEm-3VC_LqA&v=3.exp&libraries=geometry,drawing,places',
    loadingElement: <div style={{ height: `100%` }} />,
    containerElement: <div style={{ height: `400px` }} />,
    mapElement: <div style={{ height: `100%` }} />,
  }),
  withScriptjs,
  withGoogleMap
)(props => (
  <GoogleMap defaultZoom={8} defaultCenter={{ lat: -34.397, lng: 150.644 }}>
    {props.isMarkerShown && <Marker position={{ lat: -34.397, lng: 150.644 }} />}
  </GoogleMap>
));

const ProFourthStepFile = props => {
  const {
    intl,
    errors,
    priceValues,
    setPriceValues,
    values,
    setCalenderAvablility,
    setFieldValue,
    setExceptionData,
    listingInputRef,
    _handleListingImageChange,
    handleClickListing,
    handleChange,
    errorsApis,
    imagesIdsTOUpload,
    locationArray,
    setLocationArray,
  } = props;
  const { uploadImageError } = errorsApis;

  const uploadOverLimit = isUploadImageOverLimitError(uploadImageError);

  let uploadImageFailed = null;

  if (uploadOverLimit) {
    uploadImageFailed = (
      <p className="" style={{ color: 'red', fontSize: '16px' }}>
        <FormattedMessage id="EditListingPhotosForm.imageUploadFailed.uploadOverLimit" />
      </p>
    );
  } else if (uploadImageError) {
    uploadImageFailed = (
      <p className="" style={{ color: 'red', fontSize: '16px' }}>
        <FormattedMessage id="EditListingPhotosForm.imageUploadFailed.uploadFailed" />
      </p>
    );
  }

  function _labelGetting(key) {
    return intl.formatMessage({ id: key });
  }

  function _sixPrecentage(price) {
    if (price) {
      const MATH = +price * (6 / 100);
      setFieldValue('pricing_receive', 'SGD ' + (+price - MATH));
    } else {
      setFieldValue('pricing_receive', '');
    }
  }
  const identity = v => v;
  const count = 1;
  return (
    <div className="proFourth_main">
      <Accordion title={'Discription'} active={true}>
        <div className="fieldWithLabel">
          <label htmlFor="">Title</label>
          <InputFieldComp name="discriptionTitle" placeholder="Title" />
        </div>
        <div className="fieldWithLabel">
          <label htmlFor="">Category</label>
          <SelectFieldComp
            name="category"
            options={[
              { label: 'Fitness', value: 'fitness' },
              { label: 'Education', value: 'education' },
              { label: 'Creative', value: 'creative' },
              { label: 'Esports', value: 'esports' },
            ]}
            error={errors?.category}
          />
        </div>
        <div className="fieldWithLabel">
          <label htmlFor="">What age groups are you specialise in</label>
          <SelectFieldComp
            name="ageCategory"
            options={[
              { label: 'Senior (55+ years)', value: 'senior' },
              { label: 'Adult (19 - 54 years)', value: 'adult' },
              { label: 'Teen (13 - 18 years)', value: 'teen' },
              { label: 'Kids (6-12 years)', value: 'kids' },
            ]}
            error={errors?.ageCategory}
          />
        </div>

        <div className="fieldWithLabel">
          <label htmlFor="">Description</label>
          <textarea name="description" onChange={handleChange} />
          {errors?.description && <div className="error">{errors?.description}</div>}
        </div>
        <div className="fieldWithLabel pricingSection">
          <label htmlFor="">Pricing</label>
          <div className="para">
            <p>
              Enter the amount you would like to earn and we we'll add our service costs to
              calculate price for clients
            </p>
          </div>
          <div className="price_container">
            <input
              type="text"
              name="pricing_hourly"
              placeholder="SGD 0.00"
              value={values?.pricing_hourly}
              onFocus={() => {
                if (priceValues?.new) {
                  setFieldValue('pricing_hourly', '');
                  setPriceValues(prv => ({ ...prv, new: null }));
                  _sixPrecentage(null);
                }
              }}
              onChange={e => {
                setFieldValue('pricing_hourly', _.replace(e.target.value, 'SGD', ''));
                setPriceValues({
                  old: _.replace(e.target.value, 'SGD', ''),
                  new: _.replace(e.target.value, 'SGD', ''),
                });
                _sixPrecentage(e.target.value);
              }}
              onBlur={e => {
                if (!priceValues?.new) {
                  setFieldValue('pricing_hourly', 'SGD ' + _.replace(priceValues?.old, 'SGD', ''));
                  setPriceValues(prv => ({ ...prv, new: prv?.old }));
                  _sixPrecentage(priceValues?.old);
                } else {
                  setFieldValue('pricing_hourly', 'SGD ' + _.replace(priceValues?.old, 'SGD', ''));
                }
              }}
            />
            <p>Hourly rate</p>
          </div>
          <div className="price_container">
            <input
              type="text"
              value={values?.pricing_receive}
              readOnly
              name="pricing_receive"
              placeholder="SGD 0.00"
            />
            <p>You'll receive</p>
          </div>
        </div>
        <div className="fieldWithLabel photos_data">
          <label htmlFor="">Photos</label>
          <p>
            Add up to 10 photos for your session, including the process, result, and before-after
            shots
          </p>
          <div className="file_selection">
            {imagesIdsTOUpload?.length > 0 ? <p> {imagesIdsTOUpload?.length} file uploaded</p> : ''}
            <input
              type="file"
              ref={listingInputRef}
              id="upload-button"
              style={{ display: 'none' }}
              onChange={_handleListingImageChange}
            />
            <button type="button" onClick={handleClickListing}>
              Upload photo
            </button>
          </div>
          {uploadImageFailed}
        </div>
      </Accordion>

      <Accordion title={_labelGetting('EditListingWizard.tabLabelLocation')} active={false}>
        {_.map(
          [
            {
              label: 'Allow users request location',
              name: 'requestLocation',
              para: 'You need to approve or dismiss the request after booking',
            },
            {
              label: 'Online',
              name: 'online',
              para: 'The client will receive your session online',
            },
            { label: 'My location', name: 'myLocation' },
          ],
          r => (
            <div className="fieldWithLabel checkbox_forth" key={r?.name}>
              <label>
                <Field type="checkbox" name={r?.name} />
                <p>{r?.label}</p>
              </label>
              <p>{r?.para}</p>
            </div>
          )
        )}
        {values?.myLocation && (
          <div className="fieldWithLabel location_section">
            {/* {console.log('locationArray', locationArray)} */}
            <div style={{ marginBottom: '12px' }}>
              {_.map(locationArray, (v, i) => (
                <div key={v} className="autocompleteField">
                  <div className="" id="location">
                    <LocationAutocompleteInputImpl
                      {...{
                        section: 'signup',
                        placeholder: 'placeholder',
                        useDefaultPredictions: false,
                        input: {
                          onChange: data => setFieldValue(v, data),
                          value: values?.[v],
                          onBlur: data => setFieldValue(v, data),
                          onFocus: e => onFocus(e),
                        },
                      }}
                    />
                    <ErrorMessage name={v} className="error" style={{ fontSize: '16px' }} />
                  </div>
                  {i !== 0 && (
                    <div
                      className="deleteIcon"
                      onClick={() => {
                        const FilterData = _.filter(locationArray, val => val !== v);
                        // console.log('FilterData', FilterData);
                        setLocationArray(FilterData);
                      }}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke-width="1.5"
                        stroke="currentColor"
                        class="w-6 h-6"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0"
                        />
                      </svg>
                    </div>
                  )}
                </div>
              ))}
              {/* {console.log(
                                values?.location &&
                                values?.location.selectedPlace &&
                                values?.location.selectedPlace.origin,
                                'values?.location && values?.location.selectedPlace && values?.location.selectedPlace.origin'
                            )} */}
              {/* <SectionMapMaybe
                                // className={css.mapTitle}
                                geolocation={values?.location && values?.location.selectedPlace && values?.location.selectedPlace.origin}
                                listingId={''}
                            /> */}
              {/* <MyMapComponent /> */}
              {!(locationArray?.length === 3) && (
                <p
                  onClick={() => {
                    setLocationArray([...locationArray, `location${locationArray?.length + 1}`]);
                    document.getElementById('location')?.scrollIntoView();
                  }}
                >
                  Add one more address
                </p>
              )}
            </div>
          </div>
        )}
      </Accordion>

      <Accordion title={_labelGetting('EditListingWizard.tabLabelAvailability')} active={false}>
        <div className="calender_main">
          <lable>Set availability manually</lable>
          <MyCalendarPage {...{ section: 'proSignup', setCalenderAvablility, setExceptionData }} />
        </div>
      </Accordion>
    </div>
  );
};

export default ProFourthStepFile;
