import { point as turfPoint, distance as turfDistance } from '@turf/turf';

export const categories = {
  fitness: {
    title: 'Fitness',
    options: [
      { key: 'personal training', label: 'Personal training' },
      { key: 'strength training', label: 'Strength Training' },
      { key: 'functional training', label: 'Functional Training' },
      { key: 'cardio', label: 'Cardio' },
      { key: 'stretching', label: 'Stretching' },
      { key: 'sports recovery', label: 'Sports Recovery' },
    ],
  },
  sports: {
    title: 'Sports',
    options: [
      { key: 'football', label: 'Football' },
      { key: 'basketball', label: 'Basketball' },
      { key: 'swimming', label: 'Swimming' },
      { key: 'tennis', label: 'Tennis' },
      { key: 'combat sports', label: 'Combat Sports' },
      { key: 'volleyball', label: 'Volleyball' },
      { key: 'badminton', label: 'Badminton' },
      { key: 'table tennis', label: 'Table tennis' },
      { key: 'gymnastics', label: 'Gymnastics' },
      { key: 'track field', label: 'Track & Field' },
      { key: 'other', label: 'Other' },
    ],
  },
  education: {
    title: 'Education & Skills',
    options: [
      { key: 'academics', label: 'Academics' },
      { key: 'leadership', label: 'Leadership' },
      { key: 'tools', label: 'Tools' },
      { key: 'mentorship', label: 'Mentorship' },
      { key: 'languages', label: 'Languages' },
      { key: 'mathematics', label: 'Mathematics' },
      { key: 'sciences', label: 'Sciences' },
      { key: 'humanities', label: 'Humanities' },
      { key: 'leadership coaching', label: 'Leadership Coaching' },
      { key: 'professional skills', label: 'Professional Skills' },
      { key: 'other', label: 'Other' },
    ],
  },
  creative: {
    title: 'Art, Dance, & Music',
    options: [
      { key: 'academics', label: 'Academics' },
      { key: 'leadership', label: 'Leadership' },
      { key: 'tools', label: 'Tools' },
      { key: 'mentorship', label: 'Mentorship' },
      { key: 'languages', label: 'Languages' },
      { key: 'mathematics', label: 'Mathematics' },
      { key: 'sciences', label: 'Sciences' },
      { key: 'humanities', label: 'Humanities' },
      { key: 'leadership coaching', label: 'Leadership Coaching' },
      { key: 'professional skills', label: 'Professional Skills' },
      { key: 'other', label: 'Other' },
    ],
  },
  esports: {
    title: 'Esports',
    options: [
      { key: 'valorant', label: 'Valorant' },
      { key: 'other', label: 'Other' },
    ],
  },
};

export const getLabel = urlQueryParams => {
  if (urlQueryParams.is_find_pro) {
    return 'Train with a pro';
  }

  const category = categories[urlQueryParams.pub_category];
  if (category) {
    return category.title;
  } else {
    return 'Other';
  }
};

export const getOptions = urlQueryParams => {
  const category = categories[urlQueryParams.pub_category];
  if (category) {
    return category.options;
  } else {
    const allOptions = Object.values(categories).reduce((acc, category) => {
      return acc.concat(category.options);
    }, []);
    return allOptions.filter(
      (option, index, self) =>
        index === self.findIndex(o => o.key === option.key && o.label === option.label)
    );
  }
};

export const getQueryParamName = queryParamNames => {
  return Array.isArray(queryParamNames) ? queryParamNames[0] : queryParamNames;
};

// Format URI component's query param: { pub_key: 'has_all:a,b,c' }
export const format = (selectedOptions, queryParamName, searchMode) => {
  const hasOptionsSelected = selectedOptions && selectedOptions.length > 0;
  const mode = searchMode ? `${searchMode}:` : '';
  const value = hasOptionsSelected ? `${mode}${selectedOptions.join(',')}` : null;
  return { [queryParamName]: value };
};

export const reverseFormat = (formattedQueryParam, queryParamName) => {
  const value = formattedQueryParam && formattedQueryParam[queryParamName];
  if (!value) {
    return { selectedOptions: [], searchMode: null };
  }
  const splitValue = value.split(':');
  const searchMode = splitValue.length > 1 ? splitValue[0] : null;
  const selectedOptions = splitValue[splitValue.length - 1].split(',');
  return { selectedOptions, searchMode };
};

export const getPriceQueryParamName = queryParamNames => {
  return Array.isArray(queryParamNames)
    ? queryParamNames[0]
    : typeof queryParamNames === 'string'
    ? queryParamNames
    : 'price';
};

// Format value, which should look like { minPrice, maxPrice }
export const formatPrice = (range, queryParamName) => {
  const { minPrice, maxPrice } = range || {};
  // Note: we compare to null, because 0 as minPrice is falsy in comparisons.
  const value = minPrice != null && maxPrice != null ? `${minPrice},${maxPrice}` : null;
  return { [queryParamName]: value };
};

export const reverseFormatPrice = (formattedQueryParam, queryParamName) => {
  const value = formattedQueryParam[queryParamName];
  if (!value) {
    return { minPrice: null, maxPrice: null };
  }
  const [minPrice, maxPrice] = value.split(',').map(Number);
  return { minPrice, maxPrice };
};

export const getLocation = () => {
  return new Promise((resolve, reject) => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(resolve, reject);
    } else {
      reject('Geolocation is not supported by this browser.');
    }
  });
};

export const filterListing = (currentListings, queryParams) => {
  let listings = currentListings;

  if (queryParams.is_find_pro) {
    const uuids = new Set();
    listings = listings.filter(item => {
      if (!uuids.has(item.author.id.uuid)) {
        uuids.add(item.author.id.uuid);
        return true;
      }
      return false;
    });
  }

  if (queryParams.pro_location) {
    listings = listings.filter(item => item.attributes.geolocation);
  }

  if (queryParams.distance) {
    listings = listings.filter(item => item && item.distance <= queryParams.distance);
  }

  return listings;
};

export const putDistance = (listings, userLocation) => {
  const userPoint = turfPoint(userLocation);
  return listings.map(listing => {
    if (listing.attributes.geolocation) {
      const listingPoint = turfPoint([
        listing.attributes.geolocation.lng,
        listing.attributes.geolocation.lat,
      ]);
      const distance = turfDistance(userPoint, listingPoint, { units: 'kilometers' });
      return { ...listing, distance };
    }
    return listing;
  });
};
