import { injectIntl } from 'react-intl';
import config from '../../config';
import { propTypes } from '../../util/types';
import { array, bool, func, string } from 'prop-types';
import { intlShape } from '../../util/reactIntl';
import css from './ListingCard.module.css';
import NamedLink from '../NamedLink/NamedLink';
import { ensureListing } from '../../util/data';
import { createSlug } from '../../util/urlHelpers';
import ResponsiveImage from '../ResponsiveImage/ResponsiveImage';
import { Component } from 'react';
import { lazyLoadWithDimensions } from '../../util/contextHelpers';
import classNames from 'classnames';
import Avatar from '../Avatar/Avatar';
import { formatMoney } from '../../util/currency';

const priceData = (price, intl) => {
  if (price && price.currency === config.currency) {
    const formattedPrice = formatMoney(intl, price);
    return { formattedPrice, priceTitle: formattedPrice };
  } else if (price) {
    return {
      formattedPrice: intl.formatMessage(
        { id: 'ListingCard.unsupportedPrice' },
        { currency: price.currency }
      ),
      priceTitle: intl.formatMessage(
        { id: 'ListingCard.unsupportedPriceTitle' },
        { currency: price.currency }
      ),
    };
  }
  return {};
};

class ListingImage extends Component {
  render() {
    return <ResponsiveImage {...this.props} />;
  }
}
const LazyImage = lazyLoadWithDimensions(ListingImage, { loadAfterInitialRendering: 3000 });

const ListingCard = props => {
  const { className, rootClassName, intl, listing, renderSizes, isFindPro } = props;
  const classes = classNames(rootClassName || css.listingCard, className);
  const currentListing = ensureListing(listing);
  const { title = '', price, publicData } = currentListing.attributes;
  const id = currentListing.id.uuid;
  const slug = createSlug(title);
  const firstImage =
    currentListing.images && currentListing.images.length > 0 ? currentListing.images[0] : null;
  const category = publicData.category?.length > 0 ? publicData.category[0] : '';
  const activities = publicData.activity || [];
  const { formattedPrice, priceTitle } = priceData(price, intl);
  const {
    id: { uuid },
    attributes: {
      profile: { displayName, publicData: userData },
    },
  } = currentListing.author;
  const userId = uuid;

  return (
    <div className={classes}>
      <NamedLink
        className={css.listingLinkTop}
        name={isFindPro ? 'ProfilePage' : 'ListingPage'}
        params={isFindPro ? { id: userId } : { id, slug }}
      >
        <LazyImage
          className={css.listingImage}
          alt={title}
          image={firstImage}
          variants={['landscape-crop', 'landscape-crop2x']}
          sizes={renderSizes}
        />
        <div className={css.listingContent}>
          <div>
            <h2 className={css.listingTitle}>{title}</h2>
            <span className={css.listingCategory}>{category}</span>
          </div>
          <div className={css.listingActivities}>
            {Array.isArray(activities) && activities.length > 0
              ? activities.map((activity, index) => (
                  <span className={css.listingActivity} key={index}>
                    {activity}
                  </span>
                ))
              : undefined}
          </div>
        </div>
      </NamedLink>
      <NamedLink name="ProfilePage" params={{ id: userId }} className={css.listringProfile}>
        <div className={css.profile}>
          <Avatar user={currentListing.author} />
          <div className={css.profileContent}>
            <h3 className={css.profileTitle}>{displayName}</h3>
            <span className={css.profileRole}>{userData?.activity || category}</span>
          </div>
        </div>
        <div className={css.profileContent}>
          <p className={css.profileTitle}>{formattedPrice}</p>
          <span style={{ textTransform: 'lowercase' }} className={css.profileRole}>
            per hour
          </span>
        </div>
      </NamedLink>
    </div>
  );
};

ListingCard.defaultProps = {
  className: null,
  rootClassName: null,
  renderSizes: null,
  filtersConfig: config.custom.filters,
  setActiveListing: () => null,
  isFindPro: false,
};

ListingCard.propTypes = {
  className: string,
  rootClassName: string,
  filtersConfig: array,
  intl: intlShape.isRequired,
  listing: propTypes.listing.isRequired,

  // Responsive image sizes hint
  renderSizes: string,

  setActiveListing: func,
  isFindPro: bool,
};

export default injectIntl(ListingCard);
