import styled from 'styled-components';

const WelcomeProLedStyle = styled.div`
  display: flex;
  justify-content: space-between;
  @media (max-width: 991px) {
    flex-wrap: wrap;
  }
  .LedFirstSection {
    text-align: left;
    margin: 0;
    @media (max-width: 991px) {
      margin: 0 auto 32px;
      padding: 0;
    }
    h1 {
      font-size: 48px;
      line-height: 60px;
      color: #1c1c1c;
      font-weight: 400;
      letter-spacing: -0.02em;
      @media (max-width: 991px) {
        font-size: 24px;
        line-height: normal;
        margin: 0 auto 5px;
      }
    }

    p {
      font-size: 18px;
      color: #959595;
      line-height: 24px;
      @media (max-width: 991px) {
        font-size: 14px;
      }
    }
  }
  .LedSecondSection {
    display: flex;
    flex-direction: column;
    height: 560px;
    justify-content: space-between;
    width: 100%;
    max-width: 640px;
    @media (max-width: 991px) {
      height: inherit;
      gap: 64px;
    }
    .listing_section {
      display: flex;
      flex-direction: column;
      gap: 30px;

      .listing_container {
        display: flex;
        gap: 40px;
        @media (max-width: 991px) {
          gap: 38px;
        }
        .listing_image {
          width: 100%;
          max-width: 100px;
          height: 100px;

          img {
            width: 100%;
            height: 100%;
          }
        }
        .text_Container {
          h3 {
            font-size: 20px;
            margin: 0;
            line-height: 30px;
            color: #1c1c1c;
            @media (max-width: 991px) {
              font-size: 16px;
            }
          }

          p {
            font-size: 16px;
            line-height: 24px;
            color: #959595;
            @media (max-width: 991px) {
              font-size: 14px;
              line-height: 20px;
              letter-spacing: -0.14px;
              br {
                display: none;
              }
            }
          }
        }
      }
    }
    .buttonSection {
      display: flex;
      gap: 20px;
      align-items: center;
      @media (max-width: 991px) {
        flex-wrap: wrap;
        justify-content: center;
        gap: 10px;
      }
      button {
        width: 100%;
        max-width: 126px;
        font-size: 18px;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0em;
        @media (max-width: 991px) {
          font-size: 16px;
          max-width: 100%;
          margin-bottom: 0;
        }
      }

      p {
        //styleName: 16 Regular;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0em;
        text-align: left;
        @media (max-width: 991px) {
          font-size: 14px;
        }
      }
    }
  }
`;

export default WelcomeProLedStyle;
