import { useEffect, useState } from 'react';
import { Menu, MenuContent, MenuItem, MenuLabel, RangeSlider } from '../../../components';
import { GrayButton } from '../../../components/Button/Button';
import { formatPrice, getPriceQueryParamName, reverseFormatPrice } from '../Filter.helpers';
import ArrowIcon from './ArrowIcon';
import css from './Filter.module.css';
import { filters } from '../../../marketplace-custom-config';

const Icon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M9.99935 2.29199C5.74215 2.29199 2.29102 5.74313 2.29102 10.0003C2.29102 14.2575 5.74215 17.7087 9.99935 17.7087C14.2565 17.7087 17.7077 14.2575 17.7077 10.0003C17.7077 5.74313 14.2565 2.29199 9.99935 2.29199ZM1.04102 10.0003C1.04102 5.05277 5.0518 1.04199 9.99935 1.04199C14.9469 1.04199 18.9577 5.05277 18.9577 10.0003C18.9577 14.9479 14.9469 18.9587 9.99935 18.9587C5.0518 18.9587 1.04102 14.9479 1.04102 10.0003ZM9.99935 4.37533C10.3445 4.37533 10.6243 4.65515 10.6243 5.00033V5.26427C11.983 5.50755 13.1243 6.52833 13.1243 7.91699C13.1243 8.26217 12.8445 8.54199 12.4993 8.54199C12.1542 8.54199 11.8743 8.26217 11.8743 7.91699C11.8743 7.21343 11.1464 6.45866 9.99935 6.45866C8.85228 6.45866 8.12435 7.21343 8.12435 7.91699C8.12435 8.62055 8.85228 9.37533 9.99935 9.37533C11.6137 9.37533 13.1243 10.486 13.1243 12.0837C13.1243 13.4723 11.983 14.4931 10.6243 14.7364V15.0003C10.6243 15.3455 10.3445 15.6253 9.99935 15.6253C9.65417 15.6253 9.37435 15.3455 9.37435 15.0003V14.7364C8.01566 14.4931 6.87435 13.4723 6.87435 12.0837C6.87435 11.7385 7.15417 11.4587 7.49935 11.4587C7.84453 11.4587 8.12435 11.7385 8.12435 12.0837C8.12435 12.7872 8.85228 13.542 9.99935 13.542C11.1464 13.542 11.8743 12.7872 11.8743 12.0837C11.8743 11.3801 11.1464 10.6253 9.99935 10.6253C8.385 10.6253 6.87435 9.51462 6.87435 7.91699C6.87435 6.52833 8.01566 5.50755 9.37435 5.26427V5.00033C9.37435 4.65515 9.65417 4.37533 9.99935 4.37533Z"
      fill="#313131"
    />
  </svg>
);

const PriceFilter = ({ urlQueryParams, onSubmit }) => {
  const [open, setOpen] = useState(false);
  const [handles, setHandles] = useState([0, 2000]);
  const filter = filters.find(({ id }) => id === 'price');
  const queryParamName = getPriceQueryParamName(filter.queryParamNames);

  const handleSubmit = () => {
    const formatted = formatPrice({ minPrice: handles[0], maxPrice: handles[1] }, queryParamName);
    onSubmit(formatted);
    setOpen(false);
  };

  useEffect(() => {
    if (queryParamName.price) {
      const { maxPrice, minPrice } = reverseFormatPrice(urlQueryParams, queryParamName);
      setHandles([minPrice, maxPrice]);
    }
  }, [urlQueryParams, queryParamName]);

  return (
    <Menu
      className={css.filterContainer}
      useArrow={false}
      onToggleActive={values => setOpen(values)}
      isOpen={open}
    >
      <MenuLabel className={css.filterLabel}>
        <Icon />
        <span>Price</span>
        <ArrowIcon />
      </MenuLabel>
      <MenuContent key="activities" className={css.filterContent}>
        <MenuItem key="activities" className={css.filterTitle}>
          Price
        </MenuItem>
        <MenuItem key="slider" className={css.filterItemContainer}>
          <RangeSlider
            min={0}
            max={2000}
            step={1}
            handles={handles}
            onChange={values => {
              setHandles([values[0], values[1]]);
            }}
          />
        </MenuItem>
        <MenuItem key="values" className={css.filterSliderValueContainer}>
          <span>{`${handles[0]} SGD`}</span>
          <span>{`${handles[1]} SGD`}</span>
        </MenuItem>
        <MenuItem key="actions" className={css.filterAction}>
          <button className={css.filterBtnApply}>Clear All</button>
          <GrayButton className={css.filterBtnGray} onClick={handleSubmit}>
            Apply
          </GrayButton>
        </MenuItem>
      </MenuContent>
    </Menu>
  );
};

export default PriceFilter;
