import { range } from 'lodash';
import moment from 'moment';
import React, { useState } from 'react';
import DatePicker from "react-datepicker";

import styled from 'styled-components';
import '../../styles/DatePickerGlobal.css';
// import "react-datepicker/dist/react-datepicker.css";

const DatePickeStyle = styled.div`
&&
    button {
        max-width: 40px !important;
        border-radius: 30px;
        padding: 8px 10px 10px 10px;
        margin-bottom: 0;
        line-height: 20px;
    }

`;


const DatePickerComp = (props) => {
    const [startDate, setStartDate] = useState(new Date());
    const years = range(1990, new Date().getFullYear() + 1, 1);

    const months = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "July",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
    ];

    return (
        <DatePicker
            renderCustomHeader={({
                date,
                changeYear,
                changeMonth,
                decreaseMonth,
                increaseMonth,
                prevMonthButtonDisabled,
                nextMonthButtonDisabled,
            }) => (
                <DatePickeStyle>
                    <div
                        style={{
                            margin: 10,
                            display: "flex",
                            justifyContent: "center",
                            alignItems: 'center',
                            gap: '10px',
                        }}
                    >
                        <button onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>
                            {"<"}
                        </button>
                        <select
                            value={new Date(date).getFullYear()}
                            onChange={({ target: { value } }) => changeYear(value)}
                        >
                            {years.map((option) => (
                                <option key={option} value={option}>
                                    {option}
                                </option>
                            ))}
                        </select>

                        <select
                            value={months[new Date(date).getMonth()]}
                            onChange={({ target: { value } }) =>
                                changeMonth(months.indexOf(value))
                            }
                        >
                            {months.map((option) => (
                                <option key={option} value={option}>
                                    {option}
                                </option>
                            ))}
                        </select>

                        <button onClick={increaseMonth} disabled={nextMonthButtonDisabled}>
                            {">"}
                        </button>
                    </div>
                </DatePickeStyle>

            )}
            selected={startDate}
            onChange={(date) => {
                props?.setFieldValue('DOB', moment(date).format('DD/MMM/YYYY'))
                setStartDate(date);
            }}
        />
    );
}

export default DatePickerComp;