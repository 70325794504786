import { FormattedMessage } from 'react-intl';
import { InputFieldComp } from './components/CommonComp/FieldComponent';
import CreatSingupStyle from './styles/CreatSingup.style';
import { Field, Form, Formik } from 'formik';
import SocialMediaSignup from './components/SocialMediaSignup';
import * as Yup from 'yup';
import { NamedLink } from '../components';
import styled from 'styled-components';
import { useEffect } from 'react';
import MainSignupFlowStyle from './styles/MainSignupFlow.style';
import { useHistory } from 'react-router-dom';

const MainLoginCss = styled(CreatSingupStyle)``;

const MainLoginFlow = props => {
  const { intl, setLoginPage, tab, loginPage, authWithGoogle, authWithFacebook, submitLogin, isAuthenticated } = props;
  const history = useHistory();

  function _intelMessage(message) {
    return intl.formatMessage({ id: message });
  }

  const passwordRecoveryLink = (
    <NamedLink name="PasswordRecoveryPage" className="">
      <FormattedMessage id="LoginForm.forgotPassword" />
    </NamedLink>
  );
  useEffect(() => {
    window.scrollTo({ behavior: 'smooth', top: 0 });
  }, [loginPage]);

  function _intelMessage(message) {
    return intl.formatMessage({ id: message });
  }

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .trim()
      .required(_intelMessage('LoginForm.emailRequired'))
      .email(_intelMessage('LoginForm.emailInvalid')),
    password: Yup.string()
      .trim()
      .required(_intelMessage('LoginForm.passwordRequired')),
  });

  return (
    <MainSignupFlowStyle>
      <MainLoginCss>
        <div className="MainCreateAccount_Class">
          <section className="createFirstSection commonWidth">
            <h1>Log In</h1>
            <div className="actionsButton">
              <button>Retrive Myinfo with singpass</button>
              <SocialMediaSignup {...{
                tab, authWithGoogle, authWithFacebook
              }} />
            </div>
            <Formik
              enableReinitialize
              validationSchema={validationSchema}
              initialValues={{ email: '', password: '' }}
              onSubmit={(values, action) => submitLogin(values, (data) => data ? props?.history.push('/listings') : '')}
            >
              {props => (
                <Form className="loginForm signupForm">
                  <div className="allInput_section fieldCommonCss">
                    <InputFieldComp
                      name="email"
                      label={_intelMessage('LoginForm.emailLabel')}
                      placeholder={_intelMessage('LoginForm.emailPlaceholder')}
                    />
                    <InputFieldComp
                      type="password"
                      name="password"
                      label={_intelMessage('LoginForm.passwordLabel')}
                      placeholder={_intelMessage('LoginForm.passwordPlaceholder')}
                    />
                    <div className="checkboxInput">
                      <label htmlFor="term">
                        <FormattedMessage
                          id="LoginForm.forgotPasswordInfo"
                          values={{ passwordRecoveryLink }}
                        />
                      </label>
                    </div>
                  </div>
                  <div className="formsubmit">
                    <button type="submit">
                      <FormattedMessage id="LoginForm.logIn" />
                    </button>
                    <p>
                      Don’t have an account? <a onClick={() => {
                        setLoginPage(false);
                        history.push('/signup')
                      }}> Sign Up here</a>
                    </p>
                  </div>
                </Form>
              )}
            </Formik>
          </section>
          <section className="createSecondSection imageSection">
            <img src={require('./assest/createSignupImage.png')} alt="image1" />
            <div className="imageContent">
              <div>
                <h3>Teach and sell with ease</h3>
                <span>For freelancers, teachers, influencers. For everyone.</span>
              </div>
            </div>
          </section>
        </div>
      </MainLoginCss>
    </MainSignupFlowStyle>
  );
};

export default MainLoginFlow;
