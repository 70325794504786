import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { REVIEW_TYPE_OF_PROVIDER, REVIEW_TYPE_OF_CUSTOMER, propTypes } from '../../util/types';
import { ensureCurrentUser, ensureUser } from '../../util/data';
import { withViewport } from '../../util/contextHelpers';
import { isScrollingDisabled, manageDisableScrolling } from '../../ducks/UI.duck';
import { getMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import {
  Page,
  LayoutSideNavigation,
  LayoutWrapperMain,
  LayoutWrapperSideNav,
  LayoutWrapperTopbar,
  LayoutWrapperFooter,
  Footer,
  AvatarLarge,
  NamedLink,
  Reviews,
  ButtonTabNavHorizontal,
  ManageListingCard,
  Avatar,
  Modal,
  LayoutWrapperAccountSideNav,
  ExternalLink,
} from '../../components';
import { TopbarContainer, NotFoundPage } from '../../containers';
import config from '../../config';
import facebookIcon from './FacebookLogo.svg';
import instagramIcon from './InstagramLogo.svg';
import tiktokIcon from './TiktokLogo.svg';
import twitterIcon from './TwitterLogo.svg';
import globalIcon from './global.svg';
import youtubeIcon from './YoutubeLogo.svg';
import bookmark from './bookmark.svg';
import { types as sdkTypes } from '../../util/sdkLoader';
import {
  closeListing,
  openListing,
  getOwnListingsById,
  queryOwnListings,
} from '../ManageListingsPage/ManageListingsPage.duck';

import css from './ProfilePage.module.css';
import classNames from 'classnames';
import { EnquiryForm } from '../../forms';
import routeConfiguration from '../../routeConfiguration';
import { sendEnquiry, setInitialValues } from '../ListingPage/ListingPage.duck';
// import { closeListing } from '../ManageListingsPage/ManageListingsPage.duck';
const { UUID } = sdkTypes;
const MAX_MOBILE_SCREEN_WIDTH = 768;

const sections = ['Listings', 'Reviews'];

export class ProfilePageComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      // keep track of which reviews tab to show in desktop viewport
      showReviewsType: REVIEW_TYPE_OF_PROVIDER,
      sectionName: 'Listings',
      //
      listingMenuOpen: null,
      enquiryModalOpen: false,

      isAboutExpand: false,
    };
    this.onToggleMenu = this.onToggleMenu.bind(this);

    this.showOfProviderReviews = this.showOfProviderReviews.bind(this);
    this.showOfCustomerReviews = this.showOfCustomerReviews.bind(this);

    this.onContactUser = this.onContactUser.bind(this);
    this.onSubmitEnquiry = this.onSubmitEnquiry.bind(this);
  }

  onToggleMenu(listing) {
    this.setState({ listingMenuOpen: listing });
  }

  showOfProviderReviews() {
    this.setState({
      showReviewsType: REVIEW_TYPE_OF_PROVIDER,
    });
  }

  showOfCustomerReviews() {
    this.setState({
      showReviewsType: REVIEW_TYPE_OF_CUSTOMER,
    });
  }

  onContactUser() {
    const { currentUser, history, callSetInitialValues, params, location } = this.props;

    if (!currentUser) {
      const state = { from: `${location.pathname}${location.search}${location.hash}` };

      // We need to log in before showing the modal, but first we need to ensure
      // that modal does open when user is redirected back to this listingpage
      callSetInitialValues(setInitialValues, { enquiryModalOpenForListingId: params?.id });

      // signup and return back to listingPage.
      history.push(createResourceLocatorString('SignupPage', routeConfiguration(), {}, {}), state);
    } else {
      this.setState({ enquiryModalOpen: true });
    }
  }

  onSubmitEnquiry(values) {
    const { history, params, onSendEnquiry } = this.props;
    const routes = routeConfiguration();
    const listingId = new UUID(params.id);
    const { message } = values;

    onSendEnquiry(listingId, message.trim())
      .then(txId => {
        this.setState({ enquiryModalOpen: false });

        // Redirect to OrderDetailsPage
        history.push(
          createResourceLocatorString('OrderDetailsPage', routes, { id: txId.uuid }, {})
        );
      })
      .catch(() => {
        // Ignore, error handling in duck file
      });
  }

  render() {
    const {
      scrollingDisabled,
      currentUser,
      user,
      listings,
      userShowError,
      reviews,
      queryReviewsError,
      viewport,
      intl,
      onManageDisableScrolling,
      isAuthenticated,
      closingListing,
      closingListingError,
      onCloseListing,
      onOpenListing,
      openingListing,
      openingListingError,
      pagination,
      queryInProgress,
      queryListingsError,
      sendEnquiryInProgress,
      sendEnquiryError,
      location,
    } = this.props;

    const isPro = location.pathname.includes('/pro/');
    const listingMenuOpen = this.state.listingMenuOpen;
    const closingErrorListingId = !!closingListingError && closingListingError.listingId;
    const openingErrorListingId = !!openingListingError && openingListingError.listingId;

    const ensuredCurrentUser = ensureCurrentUser(currentUser);
    const profileUser = ensureUser(user);
    const isCurrentUser =
      ensuredCurrentUser.id && profileUser.id && ensuredCurrentUser.id.uuid === profileUser.id.uuid;
    const role = currentUser?.attributes?.profile?.publicData?.activity;
    const displayName =
      user?.attributes?.profile?.publicData?.displayName || user?.attributes?.profile?.displayName;
    const bio = profileUser.attributes.profile.bio;

    const protectedData = currentUser?.attributes?.profile?.protectedData;

    const hasBio = !!bio;
    const aboutText = bio;
    const isMobileLayout = viewport.width < MAX_MOBILE_SCREEN_WIDTH;

    const editLinkMobile = isCurrentUser ? (
      <NamedLink className={css.editLinkMobile} name="ProfileSettingsPage">
        <FormattedMessage id="ProfilePage.editProfileLinkMobile" />
      </NamedLink>
    ) : null;
    const editLinkDesktop = isCurrentUser ? (
      <NamedLink className={css.editLinkDesktop} name="ProfileSettingsPage">
        <FormattedMessage id="ProfilePage.editProfileLinkDesktop" />
      </NamedLink>
    ) : null;

    const asideContent = (
      <div className={css.asideContent}>
        <AvatarLarge className={css.avatar} user={user} disableProfileLink />
        <h1 className={css.mobileHeading}>
          {displayName ? (
            <FormattedMessage id="ProfilePage.mobileHeading" values={{ name: displayName }} />
          ) : null}
        </h1>
        {editLinkMobile}
        {editLinkDesktop}
      </div>
    );

    const reviewsError = (
      <p className={css.error}>
        <FormattedMessage id="ProfilePage.loadingReviewsFailed" />
      </p>
    );

    const reviewsOfProvider = reviews.filter(r => r.attributes.type === REVIEW_TYPE_OF_PROVIDER);

    const reviewsOfCustomer = reviews.filter(r => r.attributes.type === REVIEW_TYPE_OF_CUSTOMER);

    const mobileReviews = (
      <div className={css.mobileReviews}>
        <h2 className={css.mobileReviewsTitle}>
          <FormattedMessage
            id="ProfilePage.reviewsOfProviderTitle"
            values={{ count: reviewsOfProvider.length }}
          />
        </h2>
        {queryReviewsError ? reviewsError : null}
        <Reviews reviews={reviewsOfProvider} />
        <h2 className={css.mobileReviewsTitle}>
          <FormattedMessage
            id="ProfilePage.reviewsOfCustomerTitle"
            values={{ count: reviewsOfCustomer.length }}
          />
        </h2>
        {queryReviewsError ? reviewsError : null}
        <Reviews reviews={reviewsOfCustomer} />
      </div>
    );

    const desktopReviewTabs = [
      {
        text: (
          <h3 className={css.desktopReviewsTitle}>
            <FormattedMessage
              id="ProfilePage.reviewsOfProviderTitle"
              values={{ count: reviewsOfProvider.length }}
            />
          </h3>
        ),
        selected: this.state.showReviewsType === REVIEW_TYPE_OF_PROVIDER,
        onClick: this.showOfProviderReviews,
      },
      {
        text: (
          <h3 className={css.desktopReviewsTitle}>
            <FormattedMessage
              id="ProfilePage.reviewsOfCustomerTitle"
              values={{ count: reviewsOfCustomer.length }}
            />
          </h3>
        ),
        selected: this.state.showReviewsType === REVIEW_TYPE_OF_CUSTOMER,
        onClick: this.showOfCustomerReviews,
      },
    ];

    const desktopReviews = (
      <div className={css.desktopReviews}>
        <ButtonTabNavHorizontal className={css.desktopReviewsTabNav} tabs={desktopReviewTabs} />

        {queryReviewsError ? reviewsError : null}

        {this.state.showReviewsType === REVIEW_TYPE_OF_PROVIDER ? (
          <Reviews reviews={reviewsOfProvider} />
        ) : (
          <Reviews reviews={reviewsOfCustomer} />
        )}
      </div>
    );

    const panelWidth = 62.5;
    const renderSizes = [
      `(max-width: 178px) 100vw`,
      `(max-width: 281px) ${panelWidth / 2}vw`,
      `${panelWidth / 3}vw`,
    ].join(', ');

    const getCategories = listings => {
      const result = [];
      listings.map(l => {
        result.push(
          Array.isArray(l?.attributes?.publicData?.category)
            ? l?.attributes?.publicData?.category[0]
            : l?.attributes?.publicData?.category
        );
      });
      return new Set(result);
    };

    const categories = getCategories(listings);

    const isAboutTextShort = aboutText?.length < 117;

    const mainContent = (
      <div className={css.page}>
        <div className={css.rootWrapper}>
          <div className={css.container}>
            <div className={css.topbar}>
              <div className={css.avatarContainer}>
                <AvatarLarge className={css.avatar} user={user} />
              </div>
              <div className={css.headerContainer}>
                <div>
                  <h1 className={css.desktopHeading}>{displayName}</h1>
                  <p className={css.subTitle}>{role}</p>
                </div>
                <div className={css.buttonsContainer}>
                  {!isPro && (
                    <div className={css.bookmarkButtonContainer}>
                      <img className={css.icon} src={bookmark} alt="bookmark" />
                    </div>
                  )}
                  {isPro ? (
                    <NamedLink name="ProfileSettingsPage" className={css.buttonLink}>
                      <div className={css.buttonContainer}>Edit profile</div>
                    </NamedLink>
                  ) : (
                    <div onClick={this.onContactUser} className={css.buttonContainer}>
                      <div>Message</div>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className={css.infoBlock}>
              {aboutText && (
                <div className={css.aboutContainer}>
                  <p className={css.blockTitle}>About</p>
                  <div className={css.mobileAbout}>
                    {isAboutTextShort ? (
                      <div>{aboutText}</div>
                    ) : (
                      <div>
                        {this.state.isAboutExpand ? (
                          <div>
                            {aboutText}
                            <div
                              onClick={() => this.setState({ isAboutExpand: false })}
                              className={css.showMoreButton}
                            >
                              Show less
                            </div>
                          </div>
                        ) : (
                          <div>
                            <span>{`${aboutText?.substring(0, 117)}...`}</span>
                            <div
                              onClick={() => this.setState({ isAboutExpand: true })}
                              className={css.showMoreButton}
                            >
                              Show more
                            </div>
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                  {aboutText && <div className={css.desktopAbout}>{aboutText}</div>}
                </div>
              )}
              {(protectedData?.instagram ||
                protectedData?.twitter ||
                protectedData?.tiktok ||
                protectedData?.facebook ||
                protectedData?.youtube ||
                protectedData?.website) && (
                <div className={css.socialLinksContainer}>
                  <p className={css.blockTitle}>Links</p>
                  <div className={css.socialLinks}>
                    <div className={css.socialLinksColumn}>
                      {protectedData?.instagram && (
                        <ExternalLink className={css.socialLink} href={protectedData?.instagram}>
                          <img className={css.socialIcon} src={instagramIcon} alt="instagram"></img>
                          <span className={css.socialTitle}>Instagram</span>
                        </ExternalLink>
                      )}
                      {protectedData?.twitter && (
                        <ExternalLink className={css.socialLink} href={protectedData?.twitter}>
                          <img className={css.socialIcon} src={twitterIcon} alt="instagram"></img>
                          <span className={css.socialTitle}>Twitter</span>
                        </ExternalLink>
                      )}
                      {protectedData?.tiktok && (
                        <ExternalLink className={css.socialLink} href={protectedData?.tiktok}>
                          <img className={css.socialIcon} src={tiktokIcon} alt="instagram"></img>
                          <span className={css.socialTitle}>TikTok</span>
                        </ExternalLink>
                      )}
                    </div>
                    <div className={css.socialLinksColumn}>
                      {protectedData?.facebook && (
                        <ExternalLink className={css.socialLink} href={protectedData?.facebook}>
                          <img className={css.socialIcon} src={facebookIcon} alt="instagram"></img>
                          <span className={css.socialTitle}>Facebook</span>
                        </ExternalLink>
                      )}
                      {protectedData?.youtube && (
                        <ExternalLink className={css.socialLink} href={protectedData?.youtube}>
                          <img className={css.socialIcon} src={youtubeIcon} alt="instagram"></img>
                          <span className={css.socialTitle}>YouTube</span>
                        </ExternalLink>
                      )}
                      {protectedData?.website && (
                        <ExternalLink className={css.socialLink} href={protectedData?.website}>
                          <img className={css.socialIcon} src={globalIcon} alt="instagram"></img>
                          <span className={css.socialTitle}>site-link.com</span>
                        </ExternalLink>
                      )}
                    </div>
                  </div>
                </div>
              )}
            </div>
            {!!categories.length && (
              <div className={css.categoriesContainer}>
                <p className={css.blockTitle}>Categories</p>
                <div className={css.categoriesContent}>
                  {categories.map(category => {
                    return (
                      category && (
                        <NamedLink
                          className={css.categorie}
                          name="CategoriesPage"
                          to={{
                            search: `bounds=1.68008452%2C104.08284892%2C1.00507067%2C103.59952873&pub_category=${category}`,
                          }}
                        >
                          {category}
                        </NamedLink>
                      )
                    );
                  })}
                </div>
              </div>
            )}
            <div className={css.typesContent}>
              {sections.map(section => (
                <div
                  key={section}
                  className={
                    this.state.sectionName === section ? classNames(css.type, css.active) : css.type
                  }
                  onClick={() => this.setState({ sectionName: section })}
                >
                  {section}
                </div>
              ))}
            </div>

            <div className={css.horizontalLine} />

            {/* {hasBio ? <p className={css.bio}>{bio}</p> : null} */}
            {/* {isMobileLayout ? mobileReviews : desktopReviews} */}
            {this.state.sectionName === sections[0] && (
              <div className={css.listingPanel}>
                <div className={css.listingCards}>
                  {isPro && (
                    <div className={css.addListingCard}>
                      <span className={css.addListingCardDescription}>
                        Add Product or Service. Find clients and get paid.
                      </span>
                      <NamedLink className={css.createNewListingLink} name="NewListingPage">
                        <button className={css.addButton}>Add new listing</button>
                      </NamedLink>
                    </div>
                  )}
                  {listings.map(l => (
                    <ManageListingCard
                      className={css.listingCard}
                      key={l.id.uuid}
                      listing={l}
                      isMenuOpen={!!listingMenuOpen && listingMenuOpen.id.uuid === l.id.uuid}
                      actionsInProgressListingId={openingListing || closingListing}
                      onToggleMenu={this.onToggleMenu}
                      onCloseListing={onCloseListing}
                      onOpenListing={onOpenListing}
                      hasOpeningError={openingErrorListingId.uuid === l.id.uuid}
                      hasClosingError={closingErrorListingId.uuid === l.id.uuid}
                      renderSizes={renderSizes}
                    />
                  ))}
                </div>
                {/* {paginationLinks} */}
              </div>
            )}
            {this.state.sectionName === sections[1] &&
              (!!reviewsOfProvider.length ? <Reviews reviews={reviewsOfProvider} /> : 'No reviews')}
          </div>
        </div>
      </div>
    );

    let content;

    if (userShowError && userShowError.status === 404) {
      return <NotFoundPage />;
    } else if (userShowError) {
      content = (
        <p className={css.error}>
          <FormattedMessage id="ProfilePage.loadingDataFailed" />
        </p>
      );
    } else {
      content = mainContent;
    }

    const schemaTitle = intl.formatMessage(
      {
        id: 'ProfilePage.schemaTitle',
      },
      {
        name: displayName,
        siteTitle: config.siteTitle,
      }
    );

    return (
      <Page
        scrollingDisabled={scrollingDisabled}
        title={schemaTitle}
        schema={{
          '@context': 'http://schema.org',
          '@type': 'ProfilePage',
          name: schemaTitle,
        }}
      >
        <LayoutSideNavigation containerClassName={css.sidebarContainer}>
          <LayoutWrapperTopbar>
            <TopbarContainer currentPage={isPro ? 'ProProfilePage' : 'ProfilePage'} />
          </LayoutWrapperTopbar>
          <LayoutWrapperAccountSideNav
            currentTab={isPro ? 'ProProfilePage' : 'ProfilePage'}
            userProfile={true}
            isAvatar={true}
            currentUser={currentUser}
            isProUser={true}
          />
          <LayoutWrapperMain className={css.wrapperMain}>
            {content}
            <Modal
              id="ListingPage.enquiry"
              contentClassName={css.enquiryModalContent}
              isOpen={isAuthenticated && this.state.enquiryModalOpen}
              onClose={() => this.setState({ enquiryModalOpen: false })}
              onManageDisableScrolling={onManageDisableScrolling}
            >
              <EnquiryForm
                className={css.enquiryForm}
                submitButtonWrapperClassName={css.enquirySubmitButtonWrapper}
                listingTitle={displayName}
                authorDisplayName={displayName}
                sendEnquiryError={sendEnquiryError}
                onSubmit={this.onSubmitEnquiry}
                inProgress={sendEnquiryInProgress}
              />
            </Modal>
          </LayoutWrapperMain>
          <LayoutWrapperFooter>
            <Footer />
          </LayoutWrapperFooter>
        </LayoutSideNavigation>
      </Page>
    );
  }
}

ProfilePageComponent.defaultProps = {
  currentUser: null,
  user: null,
  userShowError: null,
  reviews: [],
  queryReviewsError: null,
};

const { bool, arrayOf, number, shape } = PropTypes;

ProfilePageComponent.propTypes = {
  scrollingDisabled: bool.isRequired,
  currentUser: propTypes.currentUser,
  user: propTypes.user,
  userShowError: propTypes.error,
  reviews: arrayOf(propTypes.review),
  queryReviewsError: propTypes.error,

  // form withViewport
  viewport: shape({
    width: number.isRequired,
    height: number.isRequired,
  }).isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const mapStateToProps = state => {
  const { currentUser } = state.user;
  const { userId, userShowError, reviews, queryReviewsError, userListingRefs } = state.ProfilePage;
  const { isAuthenticated } = state.Auth;
  const {
    currentPageResultIds,
    pagination,
    queryInProgress,
    queryListingsError,
    queryParams,
    openingListing,
    openingListingError,
    closingListing,
    closingListingError,
  } = state.ManageListingsPage;
  const { sendEnquiryInProgress, sendEnquiryError } = state.ListingPage;

  const userMatches = getMarketplaceEntities(state, [{ type: 'user', id: userId }]);
  const user = userMatches.length === 1 ? userMatches[0] : null;
  const listings = getMarketplaceEntities(state, userListingRefs);
  return {
    scrollingDisabled: isScrollingDisabled(state),
    currentUser,
    user,
    userShowError,
    reviews,
    queryReviewsError,
    listings,

    currentPageResultIds,
    pagination,
    queryInProgress,
    queryListingsError,
    queryParams,
    openingListing,
    openingListingError,
    closingListing,
    closingListingError,
    isAuthenticated,
    sendEnquiryInProgress,
    sendEnquiryError,
  };
};

const mapDispatchToProps = dispatch => ({
  onManageDisableScrolling: (componentId, disableScrolling) =>
    dispatch(manageDisableScrolling(componentId, disableScrolling)),
  onCloseListing: listingId => dispatch(closeListing(listingId)),
  onOpenListing: listingId => dispatch(openListing(listingId)),
  onSendEnquiry: (listingId, message) => dispatch(sendEnquiry(listingId, message)),
});

const ProfilePage = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withViewport,
  injectIntl
)(ProfilePageComponent);

export default ProfilePage;
