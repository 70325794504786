import classNames from 'classnames';
import _ from 'lodash';
import React from 'react';

function LearnerSecondStep(props) {
    const { selectedButton, _handleInterested, } = props;
    const LERNER_SERVICES = [
        { id: 'learner1', title: 'Education', value: 'Education' },
        { id: 'learner2', title: 'EGames', value: 'EGames' },
        { id: 'learner3', title: 'Languages', value: 'Languages' },
        { id: 'learner4', title: 'Business Coaching', value: 'Business Coaching' },
        { id: 'learner5', title: 'Sport', value: 'Sport' },
        { id: 'learner6', title: 'Programming', value: 'Programming' },
        { id: 'learner7', title: 'Other', value: 'Other' },
    ];
    return (
        <div className='LearnerThird_main'>
            <div className='listingTypes_options'>
                <div className='toggleContainer'>
                    {_.map(LERNER_SERVICES, (r) => (
                        <button
                            type='button'
                            className={classNames('togglebutton', { 'active': _.includes(selectedButton, r?.value) })}
                            onClick={() => _handleInterested(r)}
                            key={r?.id}
                        >
                            {r?.title}
                        </button>
                    ))}
                </div>
            </div>

        </div>
    )
}

export default LearnerSecondStep;