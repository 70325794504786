import React, { Component } from 'react';
import { array, bool, func, oneOf, object, shape, string } from 'prop-types';
import { injectIntl, intlShape } from '../../util/reactIntl';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import debounce from 'lodash/debounce';
import unionWith from 'lodash/unionWith';
import classNames from 'classnames';
import config from '../../config';
import routeConfiguration from '../../routeConfiguration';
import { createResourceLocatorString, pathByRouteName } from '../../util/routes';
import { parse, stringify } from '../../util/urlHelpers';
import { propTypes } from '../../util/types';
import { getListingsById } from '../../ducks/marketplaceData.duck';
import { manageDisableScrolling, isScrollingDisabled } from '../../ducks/UI.duck';
import {
  SearchMap,
  ModalInMobile,
  LayoutWrapperFooter,
  Footer,
  Page,
  LayoutSideNavigation,
  LayoutWrapperTopbar,
  LayoutWrapperMain,
  LayoutWrapperAccountSideNav,
} from '../../components';
import { TopbarContainer } from '..';

import { searchMapListings, setActiveListing } from './CategoriesPage.duck';
import {
  pickSearchParamsOnly,
  validURLParamsForExtendedData,
  validFilterParams,
  createSearchResultSchema,
} from './CategoriesPage.helpers';
import MainPanel from './MainPanel';
import css from './CategoriesPage.module.css';
import { TopbarSearchForm } from '../../forms';
import { ConditionalWrapper } from '../../components/ConditionalWrapper/ConditionalWrapper';
import { getUpcomingEvents, shareCalendarAccess } from '../../util/api';
import { requestAddAvailabilityException } from '../MyCalendarPage/MyCalendarPage.duck';
import { timestampToDate } from '../../util/dates';
import Toolbar from '../../components/Toolbar/Toolbar';
import CategoriesBar from '../../components/CategoryBar/CategoriesBar';
import { filterListing, getLocation, putDistance } from './Filter.helpers';

// Pagination page size might need to be dynamic on responsive page layouts
// Current design has max 3 columns 12 is divisible by 2 and 3
// So, there's enough cards to fill all columns on full pagination pages
const RESULT_PAGE_SIZE = 24;
const MODAL_BREAKPOINT = 768; // Search is in modal on mobile layout
const SEARCH_WITH_MAP_DEBOUNCE = 300; // Little bit of debounce before search is initiated.

export class CategoriesPageComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isSearchMapOpenOnMobile: props.tab === 'map',
      isMobileModalOpen: false,
    };

    this.searchMapListingsInProgress = false;

    this.onMapMoveEnd = debounce(this.onMapMoveEnd.bind(this), SEARCH_WITH_MAP_DEBOUNCE);
    this.onOpenMobileModal = this.onOpenMobileModal.bind(this);
    this.onCloseMobileModal = this.onCloseMobileModal.bind(this);
  }

  // Callback to determine if new search is needed
  // when map is moved by user or viewport has changed
  onMapMoveEnd(viewportBoundsChanged, data) {
    const { viewportBounds, viewportMapCenter } = data;

    const routes = routeConfiguration();
    const searchPagePath = pathByRouteName('CategoriesPage', routes);
    const currentPath =
      typeof window !== 'undefined' && window.location && window.location.pathname;

    // When using the ReusableMapContainer onMapMoveEnd can fire from other pages than SearchPage too
    const isSearchPage = currentPath === searchPagePath;

    // If mapSearch url param is given
    // or original location search is rendered once,
    // we start to react to "mapmoveend" events by generating new searches
    // (i.e. 'moveend' event in Mapbox and 'bounds_changed' in Google Maps)
    if (viewportBoundsChanged && isSearchPage) {
      const { history, location, filterConfig } = this.props;

      // parse query parameters, including a custom attribute named certificate
      const { address, bounds, mapSearch, ...rest } = parse(location.search, {
        latlng: ['origin'],
        latlngBounds: ['bounds'],
      });

      const originMaybe = config.sortSearchByDistance ? { origin: viewportMapCenter } : {};

      const searchParams = {
        address,
        ...originMaybe,
        bounds: viewportBounds,
        mapSearch: false,
        ...validFilterParams(rest, filterConfig),
      };

      history.push(createResourceLocatorString('CategoriesPage', routes, {}, searchParams));
    }
  }

  // Only render current search if full place object is available in the URL params

  // Invoked when a modal is opened from a child component,
  // for example when a filter modal is opened in mobile view
  onOpenMobileModal() {
    this.setState({ isMobileModalOpen: true });
  }

  // Invoked when a modal is closed from a child component,
  // for example when a filter modal is opened in mobile view
  onCloseMobileModal() {
    this.setState({ isMobileModalOpen: false });
  }

  authWithCalendar = () => {
    const defaultRoutes = getDefaultRoutes();
    const { baseUrl, fromParam, defaultReturnParam, defaultConfirmParam } = defaultRoutes;
    window.location.href = `${baseUrl}/api/auth/google?${fromParam}${defaultReturnParam}${defaultConfirmParam}`;
  };

  // Save exception click handler
  saveException = values => {
    const { availability, exceptionStartTime, exceptionEndTime } = values;

    // TODO: add proper seat handling
    const seats = availability === 'available' ? 1 : 0;

    return this.props
      .onAddAvailabilityException({
        listingId: this.props.currentUserListing?.id?.uuid,
        seats,
        start: timestampToDate(exceptionStartTime),
        end: timestampToDate(exceptionEndTime),
      })
      .then(() => {
        setIsEditExceptionsModalOpen(false);
      })
      .catch(e => {
        // Don't close modal if there was an error
      });
  };

  componentDidMount() {
    setTimeout(() => {
      const userId = this.props.currentUser?.id?.uuid;

      shareCalendarAccess({ userId: userId }).catch(e => console.error(e));
      const events = getUpcomingEvents({ userId: userId });
      events
        .then(res => {
          localStorage.setItem('calendarEventsLoaded', true);
          res?.map(item => {
            const startTime = new Date(item.start.dateTime).getTime();
            const endTime = new Date(item.end.dateTime).getTime();
            this.saveException({
              availability: 'not-available',
              exceptionStartTime: startTime,
              exceptionEndTime: endTime,
            });
          });
        })
        .catch(err => {
          localStorage.setItem('calendarEventsLoaded', false);
        });
    }, 1000);
  }

  render() {
    const {
      intl,
      listings,
      filterConfig,
      sortConfig,
      history,
      location,
      mapListings,
      onManageDisableScrolling,
      pagination,
      scrollingDisabled,
      searchInProgress,
      searchListingsError,
      searchParams,
      activeListingId,
      onActivateListing,
      currentUser,
      isAuthenticated,
    } = this.props;

    const { mapSearch, page, ...searchInURL } = parse(location.search, {
      latlng: ['origin'],
      latlngBounds: ['bounds'],
    });
    // urlQueryParams doesn't contain page specific url params
    // like mapSearch, page or origin (origin depends on config.sortSearchByDistance)
    const urlQueryParams = pickSearchParamsOnly(searchInURL, filterConfig, sortConfig);

    // Page transition might initially use values from previous search
    const urlQueryString = stringify(urlQueryParams);
    const paramsQueryString = stringify(
      pickSearchParamsOnly(searchParams, filterConfig, sortConfig)
    );
    //
    const searchParamsAreInSync = urlQueryString === paramsQueryString;

    const validQueryParams = validURLParamsForExtendedData(searchInURL, filterConfig);

    const isWindowDefined = typeof window !== 'undefined';
    const isMobileLayout = isWindowDefined && window.innerWidth < MODAL_BREAKPOINT;
    const shouldShowSearchMap =
      !isMobileLayout || (isMobileLayout && this.state.isSearchMapOpenOnMobile);

    const onMapIconClick = () => {
      this.useLocationSearchBounds = true;
      this.setState({ isSearchMapOpenOnMobile: true });
    };

    const { address, bounds, origin } = searchInURL || {};
    const { title, description, schema } = createSearchResultSchema(listings, address, intl);

    // Set topbar class based on if a modal is open in
    // a child component
    const topbarClasses = this.state.isMobileModalOpen
      ? classNames(css.topbarBehindModal, css.topbar)
      : css.topbar;

    //Filter client side
    const filteredListings = filterListing(listings, validQueryParams);

    return (
      <Page
        scrollingDisabled={scrollingDisabled}
        description={description}
        title={title}
        schema={schema}
      >
        <LayoutWrapperTopbar>
          <TopbarContainer
            className={topbarClasses}
            currentPage="CategoriesPage"
            currentSearchParams={urlQueryParams}
          />
          <CategoriesBar />
        </LayoutWrapperTopbar>{' '}
        <ConditionalWrapper
          condition={isAuthenticated}
          wrapper={children => (
            <LayoutSideNavigation containerClassName={css.sidebarContainer}>
              {children}
            </LayoutSideNavigation>
          )}
        >
          {isAuthenticated && (
            <LayoutWrapperAccountSideNav
              className={css.sideNav}
              currentTab="CategoriesPage"
              userProfile={true}
              isAvatar={true}
              currentUser={currentUser}
            />
          )}
          <LayoutWrapperMain className={css.wrapperMain}>
            <div className={css.container}>
              <MainPanel
                urlQueryParams={validQueryParams}
                listings={filteredListings}
                searchInProgress={searchInProgress}
                searchListingsError={searchListingsError}
                searchParamsAreInSync={searchParamsAreInSync}
                onActivateListing={onActivateListing}
                onManageDisableScrolling={onManageDisableScrolling}
                onOpenModal={this.onOpenMobileModal}
                onCloseModal={this.onCloseMobileModal}
                onMapIconClick={onMapIconClick}
                pagination={pagination}
                searchParamsForPagination={parse(location.search)}
                showAsModalMaxWidth={MODAL_BREAKPOINT}
                history={history}
                origin={origin}
                currentSearchParams={urlQueryParams}
                isAuthenticated={isAuthenticated}
                location={location}
              />
            </div>
          </LayoutWrapperMain>
        </ConditionalWrapper>
        <LayoutWrapperFooter>
          <Footer />
        </LayoutWrapperFooter>
      </Page>
    );
  }
}

CategoriesPageComponent.defaultProps = {
  listings: [],
  mapListings: [],
  pagination: null,
  searchListingsError: null,
  searchParams: {},
  tab: 'listings',
  filterConfig: config.custom.filters,
  sortConfig: config.custom.sortConfig,
  activeListingId: null,
};

CategoriesPageComponent.propTypes = {
  listings: array,
  mapListings: array,
  onActivateListing: func.isRequired,
  onManageDisableScrolling: func.isRequired,
  onSearchMapListings: func.isRequired,
  pagination: propTypes.pagination,
  scrollingDisabled: bool.isRequired,
  searchInProgress: bool.isRequired,
  searchListingsError: propTypes.error,
  searchParams: object,
  tab: oneOf(['filters', 'listings', 'map']).isRequired,
  filterConfig: propTypes.filterConfig,
  sortConfig: propTypes.sortConfig,

  // from withRouter
  history: shape({
    push: func.isRequired,
  }).isRequired,
  location: shape({
    search: string.isRequired,
  }).isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const mapStateToProps = state => {
  const {
    currentPageResultIds,
    pagination,
    searchInProgress,
    searchListingsError,
    searchParams,
    searchMapListingIds,
    activeListingId,
    userLocation,
  } = state.SearchPage;
  const pageListings = getListingsById(state, currentPageResultIds);
  const mapListings = getListingsById(
    state,
    unionWith(currentPageResultIds, searchMapListingIds, (id1, id2) => id1.uuid === id2.uuid)
  );
  const { currentUser, currentUserListing } = state.user;
  const { isAuthenticated } = state.Auth;

  return {
    listings: putDistance(pageListings, userLocation),
    mapListings,
    pagination,
    currentUser,
    currentUserListing,
    scrollingDisabled: isScrollingDisabled(state),
    searchInProgress,
    searchListingsError,
    searchParams,
    activeListingId,
    isAuthenticated,
  };
};

const mapDispatchToProps = dispatch => ({
  onManageDisableScrolling: (componentId, disableScrolling) =>
    dispatch(manageDisableScrolling(componentId, disableScrolling)),
  onSearchMapListings: searchParams => dispatch(searchMapListings(searchParams)),
  onActivateListing: listingId => dispatch(setActiveListing(listingId)),
  onAddAvailabilityException: params => dispatch(requestAddAvailabilityException(params)),
});

const CategoriesPage = compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl
)(CategoriesPageComponent);

export default CategoriesPage;
