import _ from 'lodash';
import React from 'react';

const SERVICES = [
    { id: 'service1', title: '1:1 session', value: '1:1 session' },
    { id: 'service2', title: 'Events', value: 'Events' },
    { id: 'service3', title: 'Group classes', value: 'Group classes' },
    { id: 'service4', title: 'Trial Class or Session', value: 'Trial Class or Session' }
];
const DigitalProduct = [
    { id: 'digital1', title: 'Ebook', value: 'Ebook' },
    { id: 'digital2', title: 'Online course', value: 'Online course' },
    { id: 'digital3', title: 'Video course', value: 'Video course' },
    { id: 'digital4', title: 'Affiliate Products', value: 'Affiliate Products' },
    { id: 'digital5', title: 'Webinar', value: 'Webinar' }
];
const PRODUCT = [
    { id: 'product1', title: 'Item Pick Up', value: 'Item Pick Up' },
    { id: 'product2', title: 'Shipping Product', value: 'Shipping Product' },
];

const ProThirdStepFile = ({ setSelectedWhatSell, _handleNext }) => {

    function handleSelectedButton(val) {
        setSelectedWhatSell(val);
        _handleNext();
    }

    return (
        <div className='proThird_main'>
            <div className='listingTypes_options'>
                <p>Services</p>
                <div className='toggleContainer'>
                    {_.map(SERVICES, (r) => (
                        <button type='button' className='togglebutton' key={r?.id} onClick={() => handleSelectedButton(r?.value)}>{r?.title}</button>
                    ))}
                </div>
            </div>
            <div className='listingTypes_options'>
                <p>Digital product</p>
                <div className='toggleContainer'>
                    {_.map(DigitalProduct, (r) => (
                        <button type='button' className='togglebutton' key={r?.id} onClick={() => handleSelectedButton(r?.value)}>{r?.title}</button>
                    ))}
                </div>
            </div>
            <div className='listingTypes_options'>
                <p>Product</p>
                <div className='toggleContainer'>
                    {_.map(PRODUCT, (r) => (
                        <button type='button' className='togglebutton' key={r?.id} onClick={() => handleSelectedButton(r?.value)}>{r?.title}</button>
                    ))}
                </div>
            </div>
        </div >
    )
}

export default ProThirdStepFile;