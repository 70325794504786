import css from './Offers.module.css';
import { GrayButton } from '../../../components/Button/Button';
import classNames from 'classnames';
import coachingImage from '../../../assets/become-pro/coaching.svg';
import digitalProductImage from '../../../assets/become-pro/digital-product.svg';
import goodsImage from '../../../assets/become-pro/goods.svg';
import arrowRight from '../../../assets/become-pro/arrow-right.svg';
import arrowRightWhite from '../../../assets/become-pro/arrow-right-white.svg';
import { NamedLink } from '../../../components';

const Offers = () => {
  return (
    <div className={css.offers_container}>
      <div className={css.offers}>
        <div className={css.offers_top}>
          <h2 className={css.offers_title}>Diversify your income streams</h2>
          <a href="https://calendly.com/proledteam/proled-demo?month=2024-01">
            <GrayButton>Book a demo</GrayButton>
          </a>
        </div>
        <div className={css.offers_content}>
          <div className={classNames(css.offers_content_item, css.offers_content_item_green)}>
            <img src={coachingImage} alt="coaching-img" />
            <div className={css.offers_content_item_content}>
              <h3 className={css.offers_content_item_title}>Coaching</h3>
              <p className={css.offers_content_item_desc}>
                Host virtual or physical sessions: 1:1 session, Group classes, Trial sessions or
                Events.
              </p>
            </div>
            <NamedLink name="SignupPage">
              <div className={css.offers_content_item_cta}>
                <span>Create now</span>
                <img src={arrowRight} alt="arrow" />
              </div>
            </NamedLink>
          </div>
          <div className={classNames(css.offers_content_item, css.offers_content_item_blue)}>
            <img src={digitalProductImage} alt="coaching-img" />
            <div className={css.offers_content_item_content}>
              <h3 className={classNames(css.offers_content_item_title, css.offers_white_color)}>
                Digital products
              </h3>
              <p className={classNames(css.offers_content_item_desc, css.offers_white_color)}>
                Sell content in digital formats: Online courses, Webinars, Ebooks or Affiliate
                Products.
              </p>
            </div>
            <NamedLink name="SignupPage">
              <div className={css.offers_content_item_cta}>
                <span className={css.offers_white_color}>Create now</span>
                <img src={arrowRightWhite} alt="arrow" />
              </div>
            </NamedLink>
          </div>
          <div className={classNames(css.offers_content_item, css.offers_content_item_gray)}>
            <img src={goodsImage} alt="coaching-img" />
            <div className={css.offers_content_item_content}>
              <h3 className={classNames(css.offers_content_item_title, css.offers_white_color)}>
                Goods
              </h3>
              <p className={classNames(css.offers_content_item_desc, css.offers_white_color)}>
                Sell physical products: Pick up item or Shipping product.
              </p>
            </div>
            <NamedLink name="SignupPage">
              <div className={css.offers_content_item_cta}>
                <span className={css.offers_white_color}>Create now</span>
                <img src={arrowRightWhite} alt="arrow" />
              </div>
            </NamedLink>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Offers;
