import { useEffect, useState } from 'react';
import * as validators from '../../util/validators';
import * as Yup from 'yup';
import { useHistory } from 'react-router-dom';

const useSignupFlowHook = props => {
  const { submitSignup, intl } = props;
  const history = useHistory();
  const [currentFlow, setCurrentFlow] = useState('');
  const [currentUserData, setCurrentUserData] = useState(null);

  // learner and pro option file function and methods
  const [selectedSignupProcess, setSelectedSignupProcess] = useState(null);

  function _onCreateButtonClick(goTO) {
    if (selectedSignupProcess) {
      setCurrentFlow(goTO);
    }
  }

  function _intelMessage(message) {
    return intl.formatMessage({ id: message });
  }

  // signup form methodm, states, function
  const validationSchema = Yup.object().shape({
    firstName: Yup.string()
      .trim()
      .required(_intelMessage('SignupForm.firstNameRequired')),
    lastName: Yup.string()
      .trim()
      .required(_intelMessage('SignupForm.lastNameRequired')),
    email: Yup.string()
      .trim()
      .required(_intelMessage('SignupForm.emailRequired'))
      .email(_intelMessage('SignupForm.emailInvalid')),
    password: Yup.string()
      .trim()
      .required(_intelMessage('SignupForm.passwordRequired'))
      .min(
        validators.PASSWORD_MIN_LENGTH,
        intl.formatMessage(
          { id: 'SignupForm.passwordTooShort' },
          { minLength: validators.PASSWORD_MIN_LENGTH }
        )
      )
      .max(
        validators.PASSWORD_MAX_LENGTH,
        intl.formatMessage(
          { id: 'SignupForm.passwordTooLong' },
          { maxLength: validators.PASSWORD_MAX_LENGTH }
        )
      ),
  });

  const [createInitialValues, setCreateInitialValues] = useState({
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    term: false,
  });

  function _handleCreateAccountSubmit(formValues, section) {
    setCreateInitialValues(formValues);
    const { lastName, firstName, email, password } = formValues;
    const params = {
      firstName: firstName.trim(),
      lastName: lastName.trim(),
      email: email.trim(),
      password: password?.trim(),
    };
    submitSignup(params, r => {
      setCurrentFlow('flow5');
      setCurrentUserData(r?.data?.data?.id);
    });
  }

  //welcomeProLed
  function _handleGetStarted() {
    setCurrentFlow('flow6');
  }
  // console.log(currentUserData, 'currentUserData');

  return {
    //lerner and pro all states,functions
    selectedSignupProcess,
    setSelectedSignupProcess,
    currentFlow,
    setCurrentFlow,
    _onCreateButtonClick,
    currentUserData,

    // signup funtions and state
    createInitialValues,
    validationSchema,

    _handleCreateAccountSubmit,
    _intelMessage,

    //welcomePro Led
    _handleGetStarted,
    history,
    ...props,
  };
};

export default useSignupFlowHook;
