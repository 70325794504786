import { useMemo, useState } from 'react';
import css from './Features.module.css';
import classNames from 'classnames';
import calendarImage from '../../../assets/become-pro/img-calendar.webp';
import messagesImage from '../../../assets/become-pro/img-messages.webp';
import analyticsImage from '../../../assets/become-pro/img-analytics.webp';
import paymentsImage from '../../../assets/become-pro/img-payments.webp';

const Features = () => {
  const [tab, setTab] = useState('calendar');

  const tabContent = useMemo(() => {
    switch (tab) {
      case 'calendar':
        return (
          <div className={css.features_content}>
            <img src={calendarImage} alt="calendar" />
            <div className={css.features_content_text}>
              <h3 className={css.features_content_title}>Google Calendar</h3>
              <p className={css.features_content_desc}>
                Once a booking is confirmed, both parties will receive a calendar update. You may
                opt for a live calendar function that will sync your availabilities.
              </p>
            </div>
          </div>
        );
      case 'messaging':
        return (
          <div className={css.features_content}>
            <img src={messagesImage} alt="messanging" />
            <div className={css.features_content_text}>
              <h3 className={css.features_content_title}>In-web Messaging</h3>
              <p className={css.features_content_desc}>
                Keep things professional by communicating through our in-web messaging function.
              </p>
            </div>
          </div>
        );
      case 'payments':
        return (
          <div className={css.features_content}>
            <img src={paymentsImage} alt="payments" />
            <div className={css.features_content_text}>
              <h3 className={css.features_content_title}>Smooth Payments</h3>
              <p className={css.features_content_desc}>
                Say goodbye to chasing for payments! Collect payments through ProLed’s secure
                payment system facilitated through Stripe.
              </p>
            </div>
          </div>
        );
      case 'analytics':
        return (
          <div className={css.features_content}>
            <img src={analyticsImage} alt="analytic" />
            <div className={css.features_content_text}>
              <h3 className={css.features_content_title}>
                Analytics <span>Coming Soon</span>
              </h3>
              <p className={css.features_content_desc}>
                Easily keep track of your earnings from various revenue streams and your performance
                - all in one place.
              </p>
            </div>
          </div>
        );
      default:
        return (
          <div className={css.features_content}>
            <img src={calendarImage} />
            <div className={css.features_content_text}>
              <h3 className={css.features_content_title}>Google Calendar</h3>
              <p className={css.features_content_desc}>
                Once a booking is confirmed, both parties will receive a calendar update. You may
                opt for a live calendar function that will sync your availabilities.
              </p>
            </div>
          </div>
        );
    }
  }, [tab]);

  return (
    <div className={css.features_container}>
      <div className={css.features}>
        <h2 className={css.features_title}>Features that work for you</h2>
        <div className={css.features_tabs}>
          <span
            className={classNames(css.features_tab, tab === 'calendar' && css.features_tab_active)}
            onClick={() => setTab('calendar')}
          >
            Calendar
          </span>
          <span
            className={classNames(css.features_tab, tab === 'payments' && css.features_tab_active)}
            onClick={() => setTab('payments')}
          >
            Payments
          </span>
          <span
            className={classNames(css.features_tab, tab === 'messaging' && css.features_tab_active)}
            onClick={() => setTab('messaging')}
          >
            Messaging
          </span>
          <span
            className={classNames(css.features_tab, tab === 'analytics' && css.features_tab_active)}
            onClick={() => setTab('analytics')}
          >
            Analytics
          </span>
        </div>
        {tabContent}
      </div>
    </div>
  );
};

export default Features;
