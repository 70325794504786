import React from 'react';

// This is Facebook's logo, you are not allowed to change its color
export const FacebookLogo = (
  <svg width="24" height="24" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M7.89.214C4.055 1.047 1.005 4.13.205 7.947c-.734 3.45.533 7.283 3.166 9.6.967.85 3.2 2.033 4.15 2.183l.617.1v-6.883H5.806v-3h2.283l.083-1.633c.134-2.417.717-3.534 2.3-4.25.617-.284 1.034-.35 2.3-.334.85.017 1.617.084 1.7.134.1.05.167.7.167 1.433v1.317h-.983c-1.484 0-1.75.283-1.817 1.983l-.067 1.35h1.45c1.284 0 1.434.033 1.35.283-.05.167-.133.667-.2 1.134-.216 1.55-.25 1.583-1.483 1.583h-1.083V19.914l.866-.234c1.684-.433 2.984-1.216 4.4-2.633 2.067-2.067 2.9-4.1 2.9-7.017 0-3.166-1.2-5.75-3.616-7.766C14.106.38 10.772-.42 7.889.214z"
      fill="#1877F2"
      fillRule="nonzero"
    />
  </svg>
);

// This is Google's logo, you are not allowed to change its color
export const GoogleLogo = (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="Clip path group">
      <mask id="mask0_1140_2314" maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24">
        <g id="clip0_760_7151">
          <path id="Vector" d="M24 0H0V24H24V0Z" fill="white" />
        </g>
      </mask>
      <g mask="url(#mask0_1140_2314)">
        <g id="Group">
          <path
            id="Vector_2"
            d="M23.5205 12.273C23.5205 11.422 23.4442 10.6039 23.3023 9.81836H12.0005V14.4602H18.4586C18.1805 15.9602 17.3351 17.2311 16.0642 18.082V21.093H19.9423C22.2114 19.0039 23.5205 15.9274 23.5205 12.273Z"
            fill="#4285F4"
          />
          <path
            id="Vector_3"
            d="M12 24.0003C15.24 24.0003 17.9564 22.9257 19.9418 21.093L16.0636 18.0821C14.9891 18.8021 13.6145 19.2275 12 19.2275C8.87453 19.2275 6.22913 17.1166 5.28545 14.2803H1.27637V17.3894C3.25097 21.3112 7.30913 24.0003 12 24.0003Z"
            fill="#34A853"
          />
          <path
            id="Vector_4"
            d="M5.2854 14.2794C5.0454 13.5594 4.90908 12.7904 4.90908 11.9994C4.90908 11.2085 5.0454 10.4394 5.2854 9.71943V6.61035H1.27632C0.463679 8.23035 0 10.0631 0 11.9994C0 13.9358 0.463679 15.7685 1.27632 17.3885L5.2854 14.2794Z"
            fill="#FBBC04"
          />
          <path
            id="Vector_5"
            d="M12 4.77276C13.7618 4.77276 15.3436 5.37816 16.5873 6.56724L20.0291 3.1254C17.951 1.18908 15.2345 0 12 0C7.30913 0 3.25097 2.68908 1.27637 6.61092L5.28545 9.72C6.22913 6.88368 8.87453 4.77276 12 4.77276Z"
            fill="#E94235"
          />
        </g>
      </g>
    </g>
  </svg>
);

export const EmailIcon = (
  <svg
    fill="#000000"
    height="24"
    width="24"
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 64 64"
    enableBackground="new 0 0 64 64"
  >
    <path
      id="Mail"
      d="M58.0034485,8H5.9965506c-3.3136795,0-5.9999995,2.6862001-5.9999995,6v36c0,3.3137016,2.6863203,6,5.9999995,6
	h52.006897c3.3137016,0,6-2.6862984,6-6V14C64.0034485,10.6862001,61.3171501,8,58.0034485,8z M62.0034485,49.1108017
	L43.084549,30.1919994l18.9188995-12.0555992V49.1108017z M5.9965506,10h52.006897c2.2056007,0,4,1.7943001,4,4v1.7664003
	L34.4677505,33.3134003c-1.4902,0.9492989-3.3935013,0.9199982-4.8495998-0.0703011L1.9965508,14.4694996V14
	C1.9965508,11.7943001,3.7910507,10,5.9965506,10z M1.9965508,16.8852005L21.182251,29.9251003L1.9965508,49.1108017V16.8852005z
	 M58.0034485,54H5.9965506c-1.6473999,0-3.0638998-1.0021019-3.6760998-2.4278984l20.5199013-20.5200024l5.6547985,3.843401
	c1.0859013,0.7383003,2.3418007,1.1083984,3.5995998,1.1083984c1.1953011,0,2.3925018-0.3339996,3.4463005-1.0048981
	l5.8423996-3.7230015l20.2961006,20.2961025C61.0673485,52.9978981,59.6508713,54,58.0034485,54z"
    />
  </svg>
);
