import React from 'react';
import { LearnerAndProOptionStyle } from '../styles/LearnerAndProOption.style';
import _ from 'lodash';

const LearnerAndProOption = props => {
  const {
    selectedSignupProcess,
    setSelectedSignupProcess,
    _onCreateButtonClick,
    setLoginPage,
  } = props;

  const LERNER_PRO = [
    {
      signupSelectCode: 1,
      icon: require('../assest/lernerIcon.png'),
      text: 'Learner',
      para: 'Find a Service',
    },
    {
      signupSelectCode: 2,
      icon: require('../assest/proIcon.png'),
      text: 'Pro',
      para: 'Sell my services',
    },
  ];

  return (
    <LearnerAndProOptionStyle>
      <div className="mainLernerPro_class">
        <div className="chooseSection_class commonWidth">
          <div className="contentButton_section">
            <div className="content">
              <h2>Hey! Join as a client or a freelancer</h2>
              <p>
                You can swap between accounts later, but now we need more information to tailor your
                experience
              </p>
            </div>
            <div className="learnerPro_Buttons">
              {_.map(LERNER_PRO, r => (
                <div className="borderClass" key={r?.text}>
                  <div className="icon">
                    <div>
                      <img src={r?.icon} alt={_.lowerCase(r?.text) + 'Icon'} />
                    </div>
                    <div>
                      <input
                        type="checkbox"
                        checked={selectedSignupProcess === r?.signupSelectCode}
                        onChange={e => {
                          if (e.target.checked) {
                            setSelectedSignupProcess(r?.signupSelectCode);
                          } else {
                            setSelectedSignupProcess(null);
                          }
                        }}
                      />
                    </div>
                  </div>
                  <div className="text">
                    <p>{r?.text}</p>
                    <span>{r?.para}</span>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className="actionSection">
            <button
              type="button"
              disabled={!selectedSignupProcess}
              onClick={() => _onCreateButtonClick('flow2')}
            >
              Create account
            </button>
            <p>
              Already have an account?{' '}
              <a
                onClick={() => {
                  setLoginPage(true);
                  props?.history.push('/login');
                }}
              >
                Log in
              </a>{' '}
            </p>
          </div>
        </div>
        <div className="imageSection">
          <img src={require('../assest/LearnerProImage.png')} alt="image1" />
          <div className="imageContent">
            <div>
              <h3>Monetize and enhance your passion with ProLed</h3>
              <span>Easy booking and selling for everyone</span>
            </div>
          </div>
        </div>
      </div>
    </LearnerAndProOptionStyle>
  );
};

export default LearnerAndProOption;
