import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import { FormattedMessage, intlShape, injectIntl } from '../../util/reactIntl';
import classNames from 'classnames';
import {
  LINE_ITEM_NIGHT,
  LINE_ITEM_DAY,
  LISTING_STATE_PENDING_APPROVAL,
  LISTING_STATE_CLOSED,
  LISTING_STATE_DRAFT,
  propTypes,
} from '../../util/types';
import { formatMoney } from '../../util/currency';
import { ensureOwnListing } from '../../util/data';
import {
  LISTING_PAGE_PARAM_TYPE_DRAFT,
  LISTING_PAGE_PARAM_TYPE_EDIT,
  createSlug,
} from '../../util/urlHelpers';
import config from '../../config';
import trashIcon from './trash.svg';
import pencilIcon from './pencil.svg';

import { NamedLink } from '..';

import css from './ManageListingCard.module.css';

const priceData = (price, intl) => {
  if (price && price.currency === config.currency) {
    const formattedPrice = formatMoney(intl, price);
    return { formattedPrice, priceTitle: formattedPrice };
  } else if (price) {
    return {
      formattedPrice: intl.formatMessage(
        { id: 'ManageListingCard.unsupportedPrice' },
        { currency: price.currency }
      ),
      priceTitle: intl.formatMessage(
        { id: 'ManageListingCard.unsupportedPriceTitle' },
        { currency: price.currency }
      ),
    };
  }
  return {};
};

// Cards are not fixed sizes - So, long words in title make flexboxed items to grow too big.
// 1. We split title to an array of words and spaces.
//    "foo bar".split(/([^\s]+)/gi) => ["", "foo", " ", "bar", ""]
// 2. Then we break long words by adding a '<span>' with word-break: 'break-all';
const formatTitle = (title, maxLength) => {
  const nonWhiteSpaceSequence = /([^\s]+)/gi;
  return title.split(nonWhiteSpaceSequence).map((word, index) => {
    return word.length > maxLength ? (
      <span key={index} style={{ wordBreak: 'break-all' }}>
        {word}
      </span>
    ) : (
      word
    );
  });
};

export const ManageListingCardComponent = props => {
  const {
    className,
    rootClassName,
    hasClosingError,
    hasOpeningError,
    history,
    intl,
    actionsInProgressListingId,
    listing,
    onCloseListing,
    onOpenListing,
    onToggleMenu,
    showNotification,
    setNotification,
  } = props;
  const classes = classNames(rootClassName || css.root, className);
  const currentListing = ensureOwnListing(listing);
  const id = currentListing.id.uuid;
  const { title = '', price, state } = currentListing.attributes;
  const slug = createSlug(title);
  const isPendingApproval = state === LISTING_STATE_PENDING_APPROVAL;
  const isClosed = state === LISTING_STATE_CLOSED;
  const isDraft = state === LISTING_STATE_DRAFT;
  const firstImage =
    currentListing.images && currentListing.images.length > 0
      ? currentListing.images[0]?.attributes?.variants?.['landscape-crop']?.url
      : null;

  const menuItemClasses = classNames(css.menuItem, {
    [css.menuItemDisabled]: !!actionsInProgressListingId,
  });

  const { formattedPrice, priceTitle } = priceData(price, intl);

  const hasError = hasOpeningError || hasClosingError;
  const thisListingInProgress =
    actionsInProgressListingId && actionsInProgressListingId.uuid === id;

  const titleClasses = classNames(css.title, {
    [css.titlePending]: isPendingApproval,
    [css.titleDraft]: isDraft,
  });

  const category = currentListing?.attributes?.publicData?.category;

  const editListingLinkType = isDraft
    ? LISTING_PAGE_PARAM_TYPE_DRAFT
    : LISTING_PAGE_PARAM_TYPE_EDIT;

  const unitType = config.bookingUnitType;
  const isNightly = unitType === LINE_ITEM_NIGHT;
  const isDaily = unitType === LINE_ITEM_DAY;

  const unitTranslationKey = isNightly
    ? 'ManageListingCard.perNight'
    : isDaily
    ? 'ManageListingCard.perDay'
    : 'ManageListingCard.perUnit';

  const activateTooltipText = 'Activate and display the listing in your profile';
  const closeTooltipText = 'Close and hide the listing from your profile';

  const closeNotificationText = `${title} is Closed and not being displayed`;
  const activeNotificationText = `${title} is Active and displayed on your page`;
  const saveNotificationText = `${title} title saved as a Draft`;

  return (
    <div className={classes}>
      <div className={css.wrapper}>
        <div className={css.infoContainer}>
          <div className={css.imageContainer}>
            {firstImage ? (
              <img className={css.image} src={firstImage} alt="listing photo" />
            ) : (
              <p className={css.image}>🚫</p>
            )}
            {/* <ResponsiveImage
              rootClassName={css.rootForImage}
              alt={title}
              image={firstImage}
              variants={['landscape-crop']}
              sizes={renderSizes}
            /> */}
          </div>
          <div className={css.listingInfo}>
            <p className={css.listingTitle}>{title}</p>
            <p className={css.listingCategory}>{category}</p>
          </div>
        </div>
        <div className={css.buttonsContainer}>
          <div className={classNames(css.buttonContainer, css.statusContainer)}>
            <div
              className={classNames(
                css.status,
                !isDraft && !isClosed && !thisListingInProgress && css.activeStatus
              )}
            >
              {isDraft && 'Draft'}
              {isClosed && !thisListingInProgress && 'Closed'}
              {thisListingInProgress && 'In progress'}
              {/* {isPendingApproval && 'Pending approval'} */}
              {!isDraft && !isClosed && !thisListingInProgress && 'Active'}
            </div>
          </div>
          {/* {isClosed && (
            <button
              className={css.openListingButton}
              disabled={!!actionsInProgressListingId}
              onClick={event => {
                event.preventDefault();
                event.stopPropagation();
                if (!actionsInProgressListingId) {
                  onOpenListing(currentListing.id);
                }
              }}
            >
              <FormattedMessage id="ManageListingCard.openListing" />
            </button>
          )} */}
          <div className={css.buttonContainer}>
            <NamedLink
              className={css.button}
              name="EditListingPage"
              params={{ id, slug, type: LISTING_PAGE_PARAM_TYPE_EDIT, tab: 'photos' }}
            >
              Edit
            </NamedLink>
          </div>
          <div className={css.buttonContainer}>
            {isDraft ? (
              <span className={classNames(css.button, css.disabledButton)}>Close</span>
            ) : (
              <span
                className={classNames(css.button, css.tooltipButton)}
                onClick={
                  isClosed
                    ? event => {
                        event.preventDefault();
                        event.stopPropagation();
                        if (!actionsInProgressListingId) {
                          onOpenListing(currentListing.id).then(data => {
                            setNotification(activeNotificationText);
                            showNotification();
                          });
                        }
                      }
                    : event => {
                        event.preventDefault();
                        event.stopPropagation();
                        if (!actionsInProgressListingId) {
                          onToggleMenu(null);
                          onCloseListing(currentListing.id).then(data => {
                            setNotification(closeNotificationText);
                            showNotification();
                          });
                        }
                      }
                }
              >
                <span className={css.spand}>
                  {isClosed ? 'Activate' : 'Close'}
                  <span className={css.tooltiptext}>
                    {isClosed ? activateTooltipText : closeTooltipText}
                  </span>
                </span>
              </span>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

ManageListingCardComponent.defaultProps = {
  className: null,
  rootClassName: null,
  actionsInProgressListingId: null,
  renderSizes: null,
  availabilityEnabled: config.enableAvailability,
};

const { bool, func, shape, string } = PropTypes;

ManageListingCardComponent.propTypes = {
  className: string,
  rootClassName: string,
  hasClosingError: bool.isRequired,
  hasOpeningError: bool.isRequired,
  intl: intlShape.isRequired,
  listing: propTypes.ownListing.isRequired,
  isMenuOpen: bool.isRequired,
  actionsInProgressListingId: shape({ uuid: string.isRequired }),
  onCloseListing: func.isRequired,
  onOpenListing: func.isRequired,
  onToggleMenu: func.isRequired,
  availabilityEnabled: bool,

  // Responsive image sizes hint
  renderSizes: string,

  // from withRouter
  history: shape({
    push: func.isRequired,
  }).isRequired,
};

export default compose(withRouter, injectIntl)(ManageListingCardComponent);
