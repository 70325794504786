import React from 'react';
import { FormattedMessage } from 'react-intl';
import { SocialLoginButton } from '../../components';
import { FacebookLogo, GoogleLogo } from '../../containers/AuthenticationPage/socialLoginLogos';

const SocialMediaSignup = ({ authWithGoogle, authWithFacebook, tab }) => {

    // Social login buttons
    const isLogin = tab === 'login';
    const showFacebookLogin = !!process.env.REACT_APP_FACEBOOK_APP_ID;
    const showGoogleLogin = !!process.env.REACT_APP_GOOGLE_CLIENT_ID;
    const showSocialLogins = showFacebookLogin || showGoogleLogin;

    const googleButtonText = isLogin ? (
        <FormattedMessage id="AuthenticationPage.loginWithGoogle" />
    ) : (
        <FormattedMessage id="AuthenticationPage.signupWithGoogle" />
    );
    const facebookButtonText = isLogin ? (
        <FormattedMessage id="AuthenticationPage.loginWithFacebook" />
    ) : (
        <FormattedMessage id="AuthenticationPage.signupWithFacebook" />
    );

    const socialLoginButtonsMaybe = showSocialLogins ? (
        <div className="facbookSection_class">
            {showFacebookLogin ? (
                <div className="">
                    <SocialLoginButton onClick={() => authWithFacebook()}>
                        <span className="">{FacebookLogo}</span>
                        {facebookButtonText}
                    </SocialLoginButton>
                </div>
            ) : null}

            {showGoogleLogin ? (
                <div className="googleSection_class">
                    <SocialLoginButton onClick={() => authWithGoogle()}>
                        <span className="">{GoogleLogo}</span>
                        {googleButtonText}
                    </SocialLoginButton>
                </div>
            ) : null}
            <div className='main_orBorder'>
                <div className="orDisplaySection">
                    <span className="orborder_class">
                        <FormattedMessage id="AuthenticationPage.or" />
                    </span>
                </div>
            </div>
        </div>
    ) : null;

    return socialLoginButtonsMaybe;
}

export default SocialMediaSignup